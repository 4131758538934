import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../../images/logo.png";
import { IoMdArrowBack } from "react-icons/io";
import InputField from "../../components/InputField";
import SelectField from "../../components/SelectField";
import Combobox from "../../components/Combobox";
import TextareaField from "../../components/TextareaField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTransactionCreationData } from "../../hooks/useTransactionCreationData";
import { useDataFormTemplatesQuery } from "../../hooks/useFormTemplatesQuery";
import { setTransaction,setName,setDescription,setReportType,setType,setTransactionData,setTasksData, clearTransaction } from "../../store/transactionSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
const navigation = [
  //   { name: "Product", href: "#" },
  //   { name: "Features", href: "#" },
  //   { name: "Marketplace", href: "#" },
  //   { name: "Company", href: "#" },
];
function NewTransaction() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [reportForms, setReportForms] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    pushToForm,
    resetForms,
    name,
    type,
    reportType,
    description,
    resetData,
  } = useTransactionCreationData();
  const dataForms = useDataFormTemplatesQuery();


  useEffect( ()=>{
    dispatch(clearTransaction());
    },[]);







//  const fetchReports= async ()=>{
//     await axios.get(`https://collins-chilisa.testlive.space/api/v1/report-templates`).then((response)=>{
//       const data =  response.data.data;
//       setReportForms(data)
//      });}

useEffect(() => {
  const fetchReportForms = async () => {
    try {
      const response = await axios.get('https://collins-chilisa.testlive.space/api/v1/report-templates');
      const data = response.data.data;
      setReportForms(data);
    } catch (err) {
      console.error('Error fetching report templates:', err);
    }
  };
  fetchReportForms();
}, []);


  const schema = yup
    .object({
      name: yup.string().required(),
      type: yup.object({}).required(),
      reportType: yup.object({}).required(),
      description: yup.string().required(),
    })
    .required();
  
  
  
    const {
    register,
    reset,
    getValues,
    handleSubmit,
    watch,
    setValue,
    clearErrors,

    formState: { errors },
  } = useForm({
    defaultValues: {
      name: name,
      type: type,
      reportType: reportType,
      description: description,
    },
    resolver: yupResolver(schema),
  });


  const onSubmit = (data) => {
    resetForms();
    let type = data.type;

    let form = dataForms?.data?.find((item) => item._id === type?.id);

    let tasksForm = JSON.parse(JSON.stringify(form));
    let transactionForm = JSON.parse(JSON.stringify(form));

    tasksForm.formSchema = form.formSchema[0].tasksData;
    tasksForm.formType = "tasks";
    transactionForm.formSchema = form.formSchema[0].transactionData;
    dispatch(setTasksData(tasksForm))
    dispatch(setTransactionData(transactionForm))


    pushToForm(transactionForm);
    pushToForm(tasksForm);

    if(form?.isMultiparty){
      history.push("new/parties");
    }else{
      history.push("new/single");
    }
   
  };




  useEffect(() => {
    dispatch(setName(watch("name")))
    setName(watch("name"));
  }, [watch("name")]);

  useEffect(() => {
   dispatch(setDescription(watch("description")));
    setDescription(watch("description"));
  }, [watch("description")]);

  useEffect(() => {
    dispatch(setType(watch("type")));
    setType(watch("type"));
  }, [watch("type")]);

  useEffect(() => {
    dispatch(setReportType(watch("reportType")));
    setReportType(watch("reportType"));
  }, [watch("reportType")]);

  const [transactionType, setTransactionTypes] = useState([]);
  const [reportsType, setReportsTypes] = useState([]);

  useEffect(() => {
    setTransactionTypes(dataForms.data);
  // }, [dataForms.data]);
  });

  // useEffect(() => {
  //   setReportsTypes(reportForms);
  // }, [reportForms]);
  // const= labelwatch("type").label);
  return (
    <div>
      <div className="flex flex-col justify-center items-center">
        <header className="container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0 z-50">
          <nav
            className="flex items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-12 w-auto" src={logo} alt="" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </nav>
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt=""
                  />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="py-6">
                    <a
                      href="#"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </div>
      <div className="text-white flex px-20 flex-row mb-4 container mx-auto text-xl items-center">
        <span
          aria-hidden="true "
          className="cursor-pointer absolute"
          onClick={() => {
            resetData();
            history.goBack();
          }}
        >
          {<IoMdArrowBack />}
        </span>
        <label className="w-full text-xl flex justify-center font-medium">
          <span className="font-light text-white mr-2">Select </span>
          Transaction Type
        </label>
      </div>

      {transactionType?.length > 0 && (
        <form>
          <div className="border-b mx-auto  w-[680px] flex flex-col justify-center items-center border-gray-900/10 mb-10 -screen  mt-6 ">
            <div className=" w-full bg-[#102232] px-10  rounded-3xl  py-5 shadow-lg   border-[#585d61]">
              <div className="font-medium text-center my-5 text-white">
                <span className="font-light   mr-1">Transaction </span>
                Type
              </div>

              <div className="mt-5 space-y-5">
                <InputField
                  label={"Transaction Name"}
                  name={"name"}
                  register={register}
                  isErrored={errors.name}
                  placeholder={"Enter transaction name"}
                />

                <Combobox
                  name={"type"}
                  isErrored={errors.type}
                  setValue={setValue}
                  register={register}
                  clearErrors={clearErrors}
                  value={watch("type")?.label}
                  onSelect={setType}
                  label={"Type of transaction"}
                  placeholder={
                    watch("type")?.label ?? "Click on arrow to select"
                  }
                  toolTip={
                    transactionType?.length > 0
                      ? dataForms.data.map((item) => {
                          return {
                            id: item._id,
                            label: item.formName,
                            name: item.formName,
                          };
                        })
                      : []
                  }
                />
                 <Combobox
                  name={"reportType"}
                  isErrored={errors.type}
                  setValue={setValue}
                  register={register}
                  clearErrors={clearErrors}
                  value={watch("reportType")?.label}
                  onSelect={setType}
                  label={"Instruction Owner"}
                  placeholder={watch("reportType")?.label ?? "Click on arrow to select"}
                  toolTip={
                    // transactionType?.length > 0
                    //   ? 
                      reportForms?.map((item) => {
                          return {
                            id: item?._id,
                            label: item?.bank,
                            name: item?.reportName,
                            instructionOwner:item?.reportType,
                            formSchema:item?.templateSchema
                          };
                        })
                      // : []
                  }
                />
                <TextareaField
                  rows={"6"}
                  register={register}
                  name={"description"}
                  isErrored={errors.description}
                  placeholder={"Briefly describe what the transaction is for…."}
                  label={"Transaction description"}
                />
              </div>
            </div>
            <div className="flex  gap-4 w-full relative mx-auto mt-5 ">
              <div className="flex w-full gap-3 justify-end  ">
                <button
                  className="border-[#2b2d77] border align-center flex justify-center text-sm items-center rounded-[9px] px-[40px]  text-white p-3 hover:scale-105 duration-300 h-[35px]"
                  onClick={(e) => {
                    e.preventDefault();
                    history.goBack();
                  }}
                >
                  Discard
                </button>
                <button
                  className="bg-[#2b2d77] align-center flex justify-center text-sm items-center rounded-[9px] px-[40px] text-white p-3 hover:scale-105 duration-300 h-[35px]"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit(onSubmit)();
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default NewTransaction;
