import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import pdfDocument from "../Documents/BondAbsa.pdf";
import fontt from './verdana.ttf';
import fontkit from '@pdf-lib/fontkit'

const MortgageBondApplicationAbsa = async (details) => {
  const fetchPdf = async () => {

    const existingPdfBytes = await fetch(pdfDocument).then((res) =>
      res.arrayBuffer()
    );
    return existingPdfBytes;
  };

  const pdfBytes = await fetchPdf();


  const pdfDoc = await PDFDocument.load(pdfBytes);
  pdfDoc.registerFontkit(fontkit);
  const response = await fetch(fontt);
  const fontBytes = await response.arrayBuffer();
  const font = await pdfDoc.embedFont(fontBytes);
  const helveticaFont = await pdfDoc.embedFont(fontBytes); // Embed the custom font

  const verdana = await pdfDoc.embedFont(fontBytes); // Embed the custom font
  const form = pdfDoc.getForm();


  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" }); // "October"
    const year = date.getUTCFullYear();

    return { day, month, year };
  }

  const firstPage = pdfDoc.getPage(0);
  const secondPage = pdfDoc.getPage(1);
  const thirdPage = pdfDoc.getPage(2);
  const eleventhPage = pdfDoc.getPage(10);
  const fourteenthPage = pdfDoc.getPage(13);
  const fifteenthPage = pdfDoc.getPage(14);
  const sixteenthPage = pdfDoc.getPage(15);

  const { width, height } = firstPage.getSize();
  let xPos = 66; // Starting x-position

  const drawWrappedText = (projects, page, yPos, xPos, font, maxWidth) => {
    const words = projects.split(" ");
    const lineHeight = 12; // Adjust this value to control the space between lines
    const fontSize = 10;
    const additionalSpace = 2; // Extra space to add between words
  
    let currentLine = [];
    let currentLineWidth = 0;
  
    // Helper function to draw a justified line
    const drawJustifiedLine = (line, lineWidth, xPos, yPos, font, page, isLastLine = false) => {
      if (line.length === 1 || isLastLine) {
        // If it's the last line or a single word, draw normally without justification
        const text = line.join(" ");
        page.drawText(text, {
          x: xPos,
          y: yPos,
          size: fontSize,
          font,
        });
      } else {
        // Calculate extra space to justify the line
        const extraSpace = (maxWidth - lineWidth) / (line.length - 1) + additionalSpace;
  
        let currentX = xPos;
        for (let i = 0; i < line.length; i++) {
          page.drawText(line[i], {
            x: currentX,
            y: yPos,
            size: fontSize,
            font,
          });
          if (i < line.length - 1) {
            // Add extra space between words
            currentX += font.widthOfTextAtSize(line[i], fontSize) + extraSpace;
          }
        }
      }
    };
  
    // Iterate through each word in the text
    for (let i = 0; i < words.length; i++) {
      const wordWidth = font.widthOfTextAtSize(words[i], fontSize);
  
      // Check if adding the word exceeds the max width of the line
      if (currentLineWidth + wordWidth + font.widthOfTextAtSize(" ", fontSize) > maxWidth) {
        // If so, draw the justified line
        drawJustifiedLine(currentLine, currentLineWidth, xPos, yPos, font, page);
        currentLine = [words[i]]; // Start a new line with the current word
        currentLineWidth = wordWidth;
        yPos -= lineHeight; // Move down for the next line
      } else {
        // Otherwise, add the word to the current line
        currentLine.push(words[i]);
        currentLineWidth += wordWidth + font.widthOfTextAtSize(" ", fontSize);
      }
    }
  
    // Draw the last line without justification
    drawJustifiedLine(currentLine, currentLineWidth, xPos, yPos, font, page, true);
  };

  // Function to draw a single line of words
  const drawLine = (line, xPos, yPos, font, page) => {
    // Draw each word on the current line with the specified word spacing
    for (let i = 0; i < line.length; i++) {
      page.drawText(line[i], {
        x: xPos,
        y: yPos,
        size: 10,
        font: font,
        color: rgb(0, 0, 0),
      });
      xPos += font.widthOfTextAtSize(line[i], 10) + 2; // Move to the next word with spacing
    }
  };


  const drawCenterTextOnPage = (pageName, text, xPos, yPos, size) => {

    const textWidth = verdana.widthOfTextAtSize(text, size);
    const center = (width - textWidth) / 2;
    pageName.drawText(text, {
      x: center,
      y: yPos,
      size: size,
      font: verdana,
      color: rgb(0, 0, 0),
    });
  }


  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" }); // "October"
    const year = date.getUTCFullYear();

    return { day, month, year };
  }

  //page 1



  drawCenterTextOnPage(firstPage, ((details?.conveyancer || "Converyancer")), 200, 789.0 - 594.5927705377654, 11)
  let date = formatTimestamp(
    details?.date_of_authorisation_by_power_of_attorney || "2024-10-16T09:43:15.453+00:00"
  );
  let paragraph = `appeared before me, the REGISTRAR OF DEEDS FOR BOTSWANA at GABORONE, ${details.conveyancer_gender === "Male" ? "he" : "she"} the said Appearer, being duly authorised thereto by a Power of Attorney dated the ${date.day} day of ${date.month} signed at Gaborone and granted to ${details.conveyancer_gender === "Male" ? "him" : "her"} by;`
  drawWrappedText(paragraph, firstPage, 789.0 - 613, xPos, helveticaFont, width - 118);


  drawCenterTextOnPage(firstPage, ((details?.full_names || "Mortgagor")), 200, 789.0 - 657, 11)
  date = formatTimestamp(details?.date_of_birth || "2024-10-16T09:43:15.453+00:00");
  drawCenterTextOnPage(firstPage, ((`(Born on the ${date?.day} day of ${date?.month} ${date?.year})`)), 200, 789.0 - 675, 10)
  drawCenterTextOnPage(secondPage, ((details?.full_names || "Mortgagor")), 200, 789.0 - 104, 11)
  drawCenterTextOnPage(secondPage, ((`(Born on the ${date?.day} day of ${date?.month} ${date?.year})`)), 200, 789.0 - 116, 10)
  paragraph = `(hereinafter called "the MORTGAGOR") to be truly and lawfully indebted and held and firmly bound unto and on behalf of ABSA BANK BOTSWANA LIMITED (hereinafter with its successors-in-title or assigns referred to as "the MORTGAGEE") in the sum of ${details?.mortgage_capital || 'P2,295,000.00'} ${details?.mortgage_capital_in_words || 'Amount'} (hereinafter called "the CAPITAL") arising from advances, credits and other banking facilities granted and/or to be granted to the Mortgagor, whether as principal debtor, guarantor or surety or otherwise howsoever and including:`
  drawWrappedText(paragraph, secondPage, 649, xPos, helveticaFont, width - 118);
  paragraph = `AND FOR SECURING the due payment of the capital or any portion thereof together with all interest which may become due thereon reckoned as herein set forth, and also the repayment of any sum or sums of money which shall or may be disbursed by the said Mortgagee in respect of premiums of insurances, Licenses or Rentals, Government and Municipal and other rates and taxes, and any interest thereon respectively, as also of any costs and charges incurred by the Mortgagee in having this Bond prepared and registered and in suing for the recovery of the capital or any portion thereof and including legal costs incurred by the Mortgagee on an Attorney/client scale, and collection commission paid or payable to the Mortgagee's Attorneys; such costs and charges and disbursements insofar as they are not otherwise preferent being hereby secured as preferent to the additional extent of ${details?.the_ad || "P459,000.00 (Four Hundred and Fifty Nine Thousand Pula)"} (hereinafter called "the AD)`
  drawWrappedText(paragraph, secondPage, 297, xPos, helveticaFont, width - 118);
  paragraph = `piece of land being Lot ${details?.property_lot_number || "754939"}, (Portion of Lot ${details?.property_lot_number || "754939"} ;`
  drawWrappedText(paragraph, thirdPage, 710, 210, helveticaFont, 313);
  paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
  drawWrappedText(paragraph, thirdPage, 685, 210, helveticaFont, 313);
  paragraph = `${details?.property_measurements || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
  drawWrappedText(paragraph, thirdPage, 660.5, 210, helveticaFont, 313);
  date = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");
  paragraph = `Deed of Transfer No. ${details?.transfer_number || "24646/2024"} dated ${date?.day} day of ${date?.month} ${date?.year} made in favour of ${details?.full_names || "Mike Modise Smith"};`
  drawWrappedText(paragraph, thirdPage, 637.5, 210, helveticaFont, 313);



  paragraph = `THAT advances, debts or demands made by or accruing to the Mortgagee before and/or after the date of this Bond shall be deemed to be covered or secured thereto to the extent of and not beyond the said Capital sum of ${details?.mortgage_capital || 'P2,295,000.00'} ${details?.mortgage_capital_in_words || 'Amount'}  together with interest thereon and the said additional amount of ${details?.the_ad || 'P459,000.00 (Four Hundred and Fifty Nine Thousand Pula)'}.`
  drawWrappedText(paragraph, thirdPage, 418, 103, helveticaFont, 442);

  paragraph = `THAT for further securing the payment aforesaid the Appearer, q.q., on behalf of the Mortgagor, hereby cedes, assigns and transfers to the Mortgagee all the Mortgagor's right, title and interest in and to the rents which do now or may hereafter arise in respect of the mortgaged property, also authorising the Mortgagee irrevocably and in rem suam to let the mortgaged property and in default of the `
  // drawWrappedText(paragraph,thirdPage,334 , 103, helveticaFont, 440);


  paragraph = `THAT interest on all amounts secured by this bond calculated at the mortgagee’s prime rate ${details?.mortgagee_prime_rate || '20'}% (${details?.mortgagee_prime_rate || '20'} Per centum) above ${details?.mortgagee_prime_rate || '20'}% (${details?.mortgagee_prime_rate || '20'} Per Centum) effectively ${details?.effective_rate || '20'}% (${details?.effective_rate || '20'} Per Centum) per annum (calculated on a daily basis and compounded monthly) or such other rate as the Bank may from time to time determine`
  drawWrappedText(paragraph, thirdPage, 235, 103, helveticaFont, 442);


  date = formatTimestamp(details?.date_of_birth || "2024-10-16T09:43:15.453+00:00");
  drawCenterTextOnPage(fourteenthPage, ((details?.full_names || "Mortgagor")), 200, 570, 11)
  drawCenterTextOnPage(fourteenthPage, ((`(Born on the ${date?.day} day of ${date?.month} ${date?.year})`)), 200, 789.0 - 236, 10)
  drawCenterTextOnPage(fourteenthPage, ((details?.appointed_attorney || "Appointed Attorney")), 200, 789 - 300, 11)
  paragraph = `with power of substitution to be my true and lawful Attorney and Agent for and in my name, place and stead and to appear before the REGISTRAR OF DEEDS FOR BOTSWANA at GABORONE and then and there as my act and deed to sign the FIRST CONTINUING COVERING MORTGAGE BOND in favour of ABSA BANK BOTSWANA LIMITED for a sum of ${details?.mortgage_capital || 'P2,295,000.00'} ${details?.mortgage_capital_in_words || 'Amount'} over the following property:`
  drawWrappedText(paragraph, fourteenthPage, 789 - 321, xPos, helveticaFont, width - 120);
  paragraph = `piece of land being Lot ${details?.property_lot_number || "754939"},(Portion of Lot ${details?.property_lot_number || "754939"};`
  drawWrappedText(paragraph, fourteenthPage, 789.0 - 407, 210, helveticaFont, 313);
  paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
  drawWrappedText(paragraph, fourteenthPage, 789.0 - 431.5, 210, helveticaFont, 313);
  paragraph = `${details?.property_measurements || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
  drawWrappedText(paragraph, fourteenthPage, 789.0 - 457, 210, helveticaFont, 313);
  date = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");
  paragraph = `Deed of Transfer No. ${details?.transfer_number || "24646/2024"} dated ${date?.day} day of ${date?.month} ${date?.year} made in favour of ${details?.full_names || "Mike Modise Smith"};`
  drawWrappedText(paragraph, fourteenthPage, 789.0 - 480, 210, helveticaFont, 313);






  drawCenterTextOnPage(sixteenthPage, ((details?.full_names || "Mortgagor")), 200, 839.0 - 260, 11)
  date = formatTimestamp(details?.date_of_birth || "2024-10-16T09:43:15.453+00:00");
  paragraph = `I am an adult ${details?.gender || 'male'} citizen of Botswana`
  drawWrappedText(paragraph, sixteenthPage, 839.0 - 335.5, 103, helveticaFont, 313);
  date = formatTimestamp(details?.date_of_birth || "2024-10-16T09:43:15.453+00:00");
  paragraph = `I was born on the ${date.day} day of ${date.month} ${date.year}, which date I have always regarded as my true date of birth.`
  drawWrappedText(paragraph, sixteenthPage, 479, 103, helveticaFont, 385);
  let married = `I am married in community of property to ${details?.spouse_full_names || "Mortgagor Spouse"}`
  let not_married = `I am not married`
  drawWrappedText(details?.marital_status === "Married" ? married : not_married, sixteenthPage, 442.5, 103, helveticaFont, 313);









  const modifiedPdfBytes = await pdfDoc.save();
  return modifiedPdfBytes;
};

export default MortgageBondApplicationAbsa;
