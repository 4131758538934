import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import pdfDocument from "../Documents/BondFNBBank.pdf";
import fontt from './verdana.ttf';
import fontkit from '@pdf-lib/fontkit'

const MortgageBondApplicationFnb = async (details) => {




  function getDayWithSuffix(day) {
    const suffixes = ["th", "st", "nd", "rd"];
    const remainder = day % 10;
    const suffix =
      (day % 100 >= 11 && day % 100 <= 13) || remainder > 3
        ? suffixes[0]
        : suffixes[remainder];
    return `${day}${suffix}`;
  }



  const fetchPdf = async () => {
    const existingPdfBytes = await fetch(pdfDocument).then((res) =>
      res.arrayBuffer()
    );
    return existingPdfBytes;
  };

  const pdfBytes = await fetchPdf();





  const pdfDoc = await PDFDocument.load(pdfBytes);
  pdfDoc.registerFontkit(fontkit);
  const response = await fetch(fontt);
  const fontBytes = await response.arrayBuffer();
  const font = await pdfDoc.embedFont(fontBytes); // Embed the custom font
  const verdana = await pdfDoc.embedFont(fontBytes); // Embed the custom font
  const helveticaFont = await pdfDoc.embedFont(fontBytes); // Embed the custom font

  const form = pdfDoc.getForm();

  const firstPage = pdfDoc.getPage(0);
  const secondPage = pdfDoc.getPage(1);
  const thirdPage = pdfDoc.getPage(2);
  const fourthPage = pdfDoc.getPage(3);
  const sixthPage = pdfDoc.getPage(5);
  const seventhPage = pdfDoc.getPage(6);
  const eighthPage = pdfDoc.getPage(7);
  const ninthPage = pdfDoc.getPage(8);
  const tenthPage = pdfDoc.getPage(9);

  const { width, height } = firstPage.getSize();
  let xPos = 70.9; // Starting x-position

  const drawWrappedText = (projects, page, yPos, xPos, font, maxWidth) => {
    const words = projects.split(" ");
    const lineHeight = 12; // Adjust this value to control the space between lines
    const fontSize = 10;
    const additionalSpace = 2; // Extra space to add between words
  
    let currentLine = [];
    let currentLineWidth = 0;
  
    // Helper function to draw a justified line
    const drawJustifiedLine = (line, lineWidth, xPos, yPos, font, page, isLastLine = false) => {
      if (line.length === 1 || isLastLine) {
        // If it's the last line or a single word, draw normally without justification
        const text = line.join(" ");
        page.drawText(text, {
          x: xPos,
          y: yPos,
          size: fontSize,
          font,
        });
      } else {
        // Calculate extra space to justify the line
        const extraSpace = (maxWidth - lineWidth) / (line.length - 1) + additionalSpace;
  
        let currentX = xPos;
        for (let i = 0; i < line.length; i++) {
          page.drawText(line[i], {
            x: currentX,
            y: yPos,
            size: fontSize,
            font,
          });
          if (i < line.length - 1) {
            // Add extra space between words
            currentX += font.widthOfTextAtSize(line[i], fontSize) + extraSpace;
          }
        }
      }
    };
  
    // Iterate through each word in the text
    for (let i = 0; i < words.length; i++) {
      const wordWidth = font.widthOfTextAtSize(words[i], fontSize);
  
      // Check if adding the word exceeds the max width of the line
      if (currentLineWidth + wordWidth + font.widthOfTextAtSize(" ", fontSize) > maxWidth) {
        // If so, draw the justified line
        drawJustifiedLine(currentLine, currentLineWidth, xPos, yPos, font, page);
        currentLine = [words[i]]; // Start a new line with the current word
        currentLineWidth = wordWidth;
        yPos -= lineHeight; // Move down for the next line
      } else {
        // Otherwise, add the word to the current line
        currentLine.push(words[i]);
        currentLineWidth += wordWidth + font.widthOfTextAtSize(" ", fontSize);
      }
    }
  
    // Draw the last line without justification
    drawJustifiedLine(currentLine, currentLineWidth, xPos, yPos, font, page, true);
  };
  
  const drawCenterTextOnPage = (pageName, text, xPos, yPos, size) => {

    const textWidth = verdana.widthOfTextAtSize(text, size);
    const center = (width - textWidth) / 2;
    pageName.drawText(text, {
      x: center,
      y: yPos,
      size: size,
      font: verdana,
      color: rgb(0, 0, 0),
    });
  }


  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" }); // "October"
    const year = date.getUTCFullYear();

    return { day, month, year };
  }

  //page 1
  let paragraph = `${details?.conveyancer || "Conveyancer"}, a Conveyancer, appeared before me, the REGISTRAR OF DEEDS for BOTSWANA at GABORONE ${details.conveyancer_gender === "Male" ? "he" : "she"}, the Appearer being duly authorised thereto by a Power of Attorney granted to ${details.conveyancer_gender === "Male" ? "him" : "her"} by`
  drawWrappedText(paragraph, firstPage, 839.0 - 593, xPos, helveticaFont, width - 120);

  drawCenterTextOnPage(firstPage, ((details?.partyName || details?.full_names || "Mortgagor Full Names")), 200, 839.0 - 642, 11)
  let date = formatTimestamp(details?.date_of_birth || details?.date_of_birth || "2024-10-16T09:43:15.453+00:00");
  drawCenterTextOnPage(firstPage, ((`(Born on the ${getDayWithSuffix(date.day)} day of ${date?.month} ${date?.year})`)), 200, 839.0 - 654.5, 10)
  drawCenterTextOnPage(firstPage, ((details?.spouse_full_names || "Mortgagor Spouse")), 200, 839.0 - 679, 11)
  date = formatTimestamp(details?.spouse_date_of_birth || "2024-10-16T09:43:15.453+00:00");
  drawCenterTextOnPage(firstPage, ((`(Born on the ${getDayWithSuffix(date.day)} day of ${date?.month} ${date?.year})`)), 200, 839.0 - 691, 10)
  date = formatTimestamp(
    details?.marriage_laws_botswana_dated || "2024-10-16T09:43:15.453+00:00"
  );
  paragraph = `dated the ${getDayWithSuffix(date.day)} day of ${date?.month} ${date?.year}, and signed at Gaborone duly witnessed according to law, which Power of Attorney was exhibited to me on this day.`
  drawWrappedText(paragraph, firstPage, 839.0 - 727, xPos, helveticaFont, width - 120 );




  //page 2


  drawCenterTextOnPage(secondPage, ((details?.full_names || "Mortgagor Full Names")), 200, 839.0 - 95, 11);
  date = formatTimestamp(details?.date_of_birth || "2024-10-16T09:43:15.453+00:00");
  drawCenterTextOnPage(secondPage, ((`(Born on the ${getDayWithSuffix(date.day)} day of ${date?.month} ${date?.year})`)), 200, 839.0 - 107, 10);
  drawCenterTextOnPage(secondPage, ((details?.spouse_full_names || "Mortgagor Spouse")), 200, 839.0 - 131.5, 11);
  date = formatTimestamp(details?.spouse_date_of_birth || "2024-10-16T09:43:15.453+00:00");
  drawCenterTextOnPage(secondPage, ((`(Born on the ${getDayWithSuffix(date.day)} day of ${date?.month} ${date?.year})`)), 200, 839.0 - 144, 10)
  paragraph = `its successors in title or assigns (hereinafter referred to as "the said Bank") in the sum of ${details?.indebtedness_amount || 'P780,  000.00  (Seven  Hundred and Eighty Thousand Pula)'} howsoever arising from whatsoever causes and without in any way limiting the generality of the aforesaid from the causes aftermentioned, to be paid to the said Bank, its representatives, successors in title or assigns, in the manner hereinafter mentioned, renouncing therefore all benefit from the legal exceptions non numeratae pecuniae, non causa debiti, errore calculi, revision of accounts, no value received, ordinis seu excussionis et divisionis, de duobus vel pluribus reis debendi, the meaning and effect of which the Mortgagor acknowledges and understands and all other exceptions which might or could be taken at law or in equity to the payment of the aforesaid sum or any part thereof with the force and effect of which exceptions the Appearer declared the Mortgagor to be fully acquainted.`
  drawWrappedText(paragraph, secondPage, 585, xPos, helveticaFont, width - 120);
  paragraph = `AND for securing the due payment of the said sum or any portion thereof together with all interest which may become due thereon reckoned as hereinafter set forth, and also the repayment of any sum or sums of money which shall or may be disbursed by the said Bank in respect of premiums of Insurance, Stand Licences, Government and Municipal and other rates and taxes, Stamp Duty and any interest thereon respectively, as also of any costs and charges incurred by the said Bank in having this Bond prepared and registered and in suing for the recovery of the said sum or any portion thereof which costs for recovery shall be on an attorney and client basis and shall include such collection commission as the Bank may be entitled to from time to time - such costs, charges and disbursements, in so far as they are not preferent, being hereby secured as preferent to the additional extent of ${details?.secured_additional_amount || 'P156 000.00 (One Hundred and Fifty Six Thousand Pula)'} just as if they were portion of the Capital of this Bond - the Appearer q.q. hereby declares to bind specially the hereinafter described immovable property (hereinafter referred to as "the mortgaged property") as a FIRST COVERING MORTGAGE over :-`
  drawWrappedText(paragraph, secondPage, 440, xPos, helveticaFont, width- 120);
  paragraph = `piece of land being Lot ${details?.property_lot_number || "754939"}, (Portion of Lot ${details?.property_lot_number || "754939"} ;`
  drawWrappedText(paragraph, secondPage, 839.0 - 581, 213.5, helveticaFont, 313);
  paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
  drawWrappedText(paragraph, secondPage, 839.0 - 606, 213.5, helveticaFont, 313);
  paragraph = `${details?.property_measurements || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
  drawWrappedText(paragraph, secondPage, 839.0 - 630, 213.5, helveticaFont, 313);
  date = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");
  paragraph = `under Deed of Transfer No. ${details?.transfer_number || "24646/2024"} dated ${getDayWithSuffix(date.day)} day of ${date?.month} ${date?.year} made in favour of ${details?.full_names || "Mike Modise Smith"};`
  drawWrappedText(paragraph, secondPage, 185, 213.5, helveticaFont, 313);




  paragraph = `AND WHEREAS the said Bank has acceded to the above mentioned application and is prepared to enter into contracts but upon the express condition that it shall always be in the entire discretion of the said Bank to determine the extent, nature and duration of the advances, contracts and other banking facilities to be made and of the facilities to be so allowed and upon the further special condition of receiving the security of this Bond which shall be deemed to cover the principal sum of the Mortgagor's indebtedness to the said Bank at any time up to, but not exceeding the sum of ${details?.bond_principal_amount || 'P780, 000.00 (Seven Hundred and Eighty Thousand Pula)'} and all interest calculated as hereinafter provided,`
  drawWrappedText(paragraph, thirdPage, 707, xPos, helveticaFont, width - 120);





  paragraph = `THAT all the said obligations and indebtedness demanded by or accruing to the said Bank after the date of this Bond shall be deemed to be covered or secured thereby to the extent of and not beyond the said principal sum of ${details?.bond_principal_amount || 'P780, 000.00 (Seven Hundred and Eighty Thousand Pula)'} together with interest thereon as aforesaid and the said additional sum of ${details?.additional_amount || 'P156 000.00 (One Hundred and Fifty Six Thousand Pula)'}.`
  drawWrappedText(paragraph, fourthPage, 780, 107.8, helveticaFont, width - 162 );
  paragraph = `THAT all interest on any indebtedness covered by this Bond shall be reckoned at the Bank’s prime lending rate, currently ${details?.bank_prime_lending_rate || "20"}% (${details?.bank_prime_lending_rate || "20"} per centum) plus ${details?.additional_interest_rate || "20"}% (${details?.additional_interest_rate || "20"} per centum) per annum calculated daily and compounded monthly and shall be chargeable to any of the Mortgagor's accounts with the said Bank.`
  drawWrappedText(paragraph, fourthPage, 584.7, 107.8, helveticaFont, width - 162 );


  paragraph = `Licence  to  do  business  as  a  Credit  Institution  under  the  Banking Act  Cap,  46:04  for  First National  Bank  of  Botswana  Limited  is  filed  with  Covering  Mortgage  Bond  No.  2482/2003 dated 23rd October 2003. `
  drawWrappedText(paragraph, sixthPage,720, xPos, helveticaFont, width - 118 );
  /////////////////////POWER OF ATTORNEY TO REGISTER MORTGAGE BOND///////////

  drawCenterTextOnPage(seventhPage, ((details?.full_names || "Mortgagor")), 200, 839.0 - 204, 11)
  date = formatTimestamp(details?.date_of_birth || "2024-10-16T09:43:15.453+00:00");
  drawCenterTextOnPage(seventhPage, ((`(Born on the ${getDayWithSuffix(date.day)} day of ${date?.month} ${date?.year})`)), 200, 839.0 - 216, 10)
  drawCenterTextOnPage(seventhPage, ((details?.spouse_full_names || "Mortgagor Spouse")), 200, 839.0 - 241, 11)
  date = formatTimestamp(details?.spouse_date_of_birth || "2024-10-16T09:43:15.453+00:00");
  drawCenterTextOnPage(seventhPage, ((`(Born on the ${getDayWithSuffix(date.day)} day of ${date?.month} ${date?.year})`)), 200, 839.0 - 252, 10)
  paragraph = `the Mortgagor mentioned in the annexed draft Mortgage Bond, acknowledge being fully acquainted with the contents thereof and hereby nominate, constitute and appoint ${details?.appointed_attorney || "Attorney"} with power of substitution to be my/our true and lawful Attorney and Agent in my/our name, place and stead,`
  drawWrappedText(paragraph, seventhPage, 839.0 - 290, xPos, helveticaFont, width- 120);
  paragraph = `piece of land being Lot ${details?.property_lot_number || "754939"}, (Portion of Lot ${details?.property_lot_number || "754939"} ;`
  drawWrappedText(paragraph, seventhPage, 839.0 - 428, 213.5, helveticaFont, 313);
  paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
  drawWrappedText(paragraph, seventhPage, 839.0 - 454, 213.5, helveticaFont, 313);
  paragraph = `${details?.property_measurements || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
  drawWrappedText(paragraph, seventhPage, 839.0 - 477, 213.5, helveticaFont, 313);
  date = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");
  paragraph = `under Deed of Transfer No. ${details?.transfer_number || "24646/2024"} dated ${getDayWithSuffix(date.day)} day of ${date?.month} ${date?.year} made in favour of ${details?.full_names || "Mike Modise Smith"};`
  drawWrappedText(paragraph, seventhPage, 338, 213.5, helveticaFont, 313);




  //////////// AVIDAVIT OF BIRTH ////////////////
  drawCenterTextOnPage(ninthPage, ((details?.full_names || "Masego A Modise")), 0, 839.0 - 270, 11)
  paragraph = `I am an adult ${details?.gender || 'male'} citizen of ${details?.country || "Botswana"}`
  drawWrappedText(paragraph, ninthPage, 839.0 - 342, 106.2, helveticaFont, 313);
  date = formatTimestamp(details?.date_of_birth || "2024-10-16T09:43:15.453+00:00");
  paragraph = `I was born on the ${getDayWithSuffix(date.day)} day of ${date.month} ${date.year}, which date I have always regarded as my true date of birth.`
  drawWrappedText(paragraph, ninthPage, 472.5, 106.2, helveticaFont, 385);
  paragraph = `I am married in community of property to ${details?.spouse_full_names || "Smith Modise"}`
  let not_married_paragraph = `I am not married`
  drawWrappedText(details?.marital_status === "Married" ? paragraph : not_married_paragraph, ninthPage, 436, 106.2, helveticaFont, 313);



  //////////////////////   CONSENT  ///////////////////////
  drawCenterTextOnPage(tenthPage, ((details?.spouse_full_names || "Masego A Modise")), 0, 839.0 - 243.5, 11)
  paragraph = `married in community of property to ${details?.full_names || "Mortgagor Full Names"}) do hereby grant consent to the said ${details?.conveyancer || "Conveyancer"} to pass a First Covering Mortgage Bond in favour of FIRST NATIONAL BANK OF BOTSWANA LIMITED in respect the undermentioned property:`
  drawWrappedText(paragraph, tenthPage, 839.0 - 268, xPos, helveticaFont, 460);
  paragraph = `piece of land being Lot ${details?.property_lot_number || "754939"}, (Portion of Lot ${details?.property_lot_number || "754939"};`
  drawWrappedText(paragraph, tenthPage, 839.0 - 328, 213.5, helveticaFont, 313);
  paragraph = `${details?.property_measurements || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
  drawWrappedText(paragraph, tenthPage, 839.0 - 377, 213.5, helveticaFont, 313);
  date = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");
  paragraph = `under Deed of Transfer No. ${details?.transfer_number || "24646/2024"} dated ${getDayWithSuffix(date.day)} day of ${date?.month} ${date?.year} made in favour of ${details?.full_names || "Mike Modise Smith"};`
  drawWrappedText(paragraph, tenthPage, 438, 213.5, helveticaFont, 313);
  paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
  drawWrappedText(paragraph, tenthPage, 839.0 - 353, 213.5, helveticaFont, 313);


  const modifiedPdfBytes = await pdfDoc.save();
  return modifiedPdfBytes;
};

export default MortgageBondApplicationFnb;
