import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import pdfDocument from "../Documents/Individual-IndividualTransfer.pdf";
import fontkit from '@pdf-lib/fontkit'
import fontt from './verdana.ttf';

const TransferIndividual = async (details) => {


function getDayWithSuffix(day) {
    const suffixes = ["th", "st", "nd", "rd"];
    const remainder = day % 10;
    const suffix =
      (day % 100 >= 11 && day % 100 <= 13) || remainder > 3
        ? suffixes[0]
        : suffixes[remainder];
    return `${day}${suffix}`;
  }



  const fetchPdf = async () => {
    const existingPdfBytes = await fetch(pdfDocument).then((res) =>
      res.arrayBuffer()
    );
    return existingPdfBytes;
  };


  const pdfBytes = await fetchPdf();
  

 


  const pdfDoc = await PDFDocument.load(pdfBytes);
  pdfDoc.registerFontkit(fontkit);
  const response = await fetch(fontt);
  const fontBytes = await response.arrayBuffer();
  const verdana = await pdfDoc.embedFont(fontBytes); // Embed the custom font
  const helveticaFont = await pdfDoc.embedFont(fontBytes); // Embed the custom font

  const font = await pdfDoc.embedFont(StandardFonts.TimesRomanBoldItalic);
  const form = pdfDoc.getForm();


  const firstPage = pdfDoc.getPage(0);
  const secondPage = pdfDoc.getPage(1);
  const thirdPage = pdfDoc.getPage(2);
  const forthPage = pdfDoc.getPage(3);
  const fifthPage = pdfDoc.getPage(4);
  const sixPage = pdfDoc.getPage(5);
  const sixthPage = pdfDoc.getPage(5);
  const seventhPage = pdfDoc.getPage(6);
  const eighthPage = pdfDoc.getPage(7);
  const ninthPage = pdfDoc.getPage(8);
  const tenthPage = pdfDoc.getPage(9);
  const eleventhPage = pdfDoc.getPage(10);
  const twelfthPage = pdfDoc.getPage(11);

  const { width, height } = firstPage.getSize();
  const drawWrappedText = (projects, page, yPos, xPos, font, maxWidth) => {
    const words = projects.split(" ");
    const wordSpacing = 2; // Adjust this value to set the spacing between words
    const lineHeight = 12; // Adjust this value to control the space between lines

    let currentLine = [];
    let currentLineWidth = 0;

    // Iterate through each word in the text
    for (let i = 0; i < words.length; i++) {
      const wordWidth = font.widthOfTextAtSize(words[i], 10);

      // Check if adding the word exceeds the max width of the line
      if (currentLineWidth + wordWidth + (currentLine.length - 1) * wordSpacing > maxWidth) {
        // If so, move to the next line
        drawLine(currentLine, xPos, yPos, font, page);
        currentLine = [words[i]]; // Start a new line with the current word
        currentLineWidth = wordWidth;
        yPos -= lineHeight; // Move down for the next line
      } else {
        // Otherwise, add the word to the current line
        currentLine.push(words[i]);
        currentLineWidth += wordWidth;
      }
    }

    // Draw the last line
    drawLine(currentLine, xPos, yPos, font, page);
  };
  // Function to draw a single line of words
  const drawLine = (line, xPos, yPos, font, page) => {
    // Draw each word on the current line with the specified word spacing
    for (let i = 0; i < line.length; i++) {
      page.drawText(line[i], {
        x: xPos,
        y: yPos,
        size: 10,
        font: font,
        color: rgb(0, 0, 0),
      });
      xPos += font.widthOfTextAtSize(line[i], 10) + 2; // Move to the next word with spacing
    }
  };
  const drawCenterTextOnPage = (pageName, text, xPos, yPos, size) => {

    const textWidth = verdana.widthOfTextAtSize(text, size);
    const center = (width - textWidth) / 2;
    pageName.drawText(text, {
      x: center,
      y: yPos,
      size: size,
      font: verdana,
      color: rgb(0, 0, 0),
    });
  }
  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" }); // "October"
    const year = date.getUTCFullYear();

    return { day, month, year };
  }
  //////////// POWER OF ATTORNEY DOCUMENT START/////////////////
  let xPos = 70.9; // Starting x-position
  drawCenterTextOnPage(firstPage, ((details?.seller_full_names || "Seller Names")), 200, 628.5, 11)
  let date = formatTimestamp(details?.seller_date_of_birth || "2024-10-16T09:43:15.453+00:00");
  drawCenterTextOnPage(firstPage, ((`(Born on the ${getDayWithSuffix(date.day)} day of ${date?.month} ${date?.year})`)), 200, 616.5, 10)
  drawCenterTextOnPage(firstPage, ((` ${details?.appointed_attorney || 'Collins Chillisa Consultancy'}`)), 200, 556, 10)
  date = formatTimestamp(details?.act_and_deed_declaration_date || "2024-10-16T09:43:15.453+00:00");
  let paragraph = `with power of substitution to be my true and lawful Attorney and Agent and in my name, place and stead to appear before the REGISTRAR OF DEEDS FOR BOTSWANA at GABORONE and then and there as my act and deed to declare that I did on the ${getDayWithSuffix(date.day) || '19'} day of ${date?.month} ${date?.year} sell to:`;
  drawWrappedText(paragraph, firstPage, 531, xPos, helveticaFont, 460);
  drawCenterTextOnPage(firstPage, ((` ${details?.appointed_attorney || 'Collins Chillisa Consultancy'}`)), 200, 556, 10)
  drawCenterTextOnPage(firstPage, ((` ${details?.purchaser_full_names || 'Malebog Smith Modise'}`)), 200, 470.6, 10)
  date = formatTimestamp(details?.purchaser_date_of_birth || "2024-10-16T09:43:15.453+00:00");
  drawCenterTextOnPage(firstPage, ((`(Born on the ${getDayWithSuffix(date.day)} day of ${date?.month} ${date?.year})`)), 200, 458.7, 10)
  drawCenterTextOnPage(firstPage, (` ${details?.purchaser_spouse_full_names || 'Malebog Smith Modise'}`), 200, 433.9, 10)
  paragraph = `for the sum of ${details?.purchaser_marriage_settlement_amount || "P1, 560, 000.00 (One Million Five Hundred and Sixty Thousand)"} and the value of the property for transfer duty purposes is the sum of ${details?.transfer_duty_amount || "P1 600, 000.00 (One Million Six Hundred Thousand Pula)"} namely:-`
  drawWrappedText(paragraph, firstPage, 385.1, xPos, helveticaFont, 460);
  paragraph = `piece of land being Lot ${details?.property_lot_number || "754939"}, ${details?.situate || "Gaborone"} (Portion of Lot ${details?.property_lot_number || "754939"} ${details?.situate || "Gaborone"};`
  drawWrappedText(paragraph, firstPage, 325, 213.5, helveticaFont, 313);
  paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
  drawWrappedText(paragraph, firstPage, 289, 213.5, helveticaFont, 313);
  paragraph = `${details?.property_measuring || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
  drawWrappedText(paragraph, firstPage, 264, 213.5, helveticaFont, 313);
  date = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");
  paragraph = `under Deed of Transfer No. ${details?.transfer_number || "24646/2024"} dated ${getDayWithSuffix(date.day)} day of ${date?.month} ${date?.year} made in favour of ${details?.purchaser_full_names || "Mike Modise Smith"};`
  drawWrappedText(paragraph, firstPage, 239.5, 213.5, helveticaFont, 313);
  date = formatTimestamp(details?.date_power_of_attorney_signed || "2024-10-16T09:43:15.453+00:00");
  paragraph = `SIGNED AT GABORONE ON THIS ${getDayWithSuffix(date.day)} DAY OF ${date?.month.toUpperCase()} ${date?.year}`
  drawWrappedText(paragraph, secondPage, 725.2, xPos, helveticaFont, 460);
  paragraph = `${details.power_of_attorney_1_witness_ || "Daniel Modise"}`
  drawWrappedText(paragraph, secondPage, 666, 93, helveticaFont, 313);
  paragraph = `${details.power_of_attorney_2_witness_ || "Samuel Smith"}`
  drawWrappedText(paragraph, secondPage, 630, 93, helveticaFont, 313);

  //////////// POWER OF ATTORNEY DOCUMENT END /////////////////

  let _day = ''
  let _year = '';
  let _month = '';


  //////////// DECLARATION OF SELLER START /////////////////

  drawCenterTextOnPage(thirdPage, ((details?.seller_full_names || "Seller Names")), 0, 726.3, 10)
  date = formatTimestamp(details?.seller_date_of_birth || "2024-10-16T09:43:15.453+00:00");
  drawCenterTextOnPage(thirdPage, ((`(Born on the ${getDayWithSuffix(date.day)} day of ${date?.month} ${date?.year} )`)), 200, 714, 10)
  paragraph = `do solemnly and sincerely declare that the sum of ${details?.purchase_amount || 'P1, 560, 000.00'} ${details?.purchase_amount_in_words || '(One Million Five Hundred and Sixty Thousand Pula)'} and the value of the property for transfer duty purposes is the sum of ${details?.transfer_duty_amount || 'P1 600, 000.00 (One Million Six Hundred Thousand Pula)'} is the full and entire purchase money for which I have sold to:-`
  drawWrappedText(paragraph, thirdPage, 677, xPos, helveticaFont, 460);
  drawCenterTextOnPage(thirdPage, ((details?.purchaser_full_names || "Purchaser Names")), 0, 616.5, 10)
  date = formatTimestamp(details?.purchaser_date_of_birth || "2024-10-16T09:43:15.453+00:00");
  drawCenterTextOnPage(thirdPage, ((`(Born on the ${getDayWithSuffix(date.day)} day of ${date?.month} ${date?.year})`)), 200, 604.5, 10)
  drawCenterTextOnPage(thirdPage, ((details?.purchaser_spouse_full_names || "Samule John  Modise")), 0, 580.2, 10)
  paragraph = `piece of land being Lot ${details?.property_lot_number || "7438439"}, ${details?.situate || "Gaborone"} (Portion of Lot ${details?.property_lot_number || "764383"} ${details?.situate || "Gaborone"};`
  drawWrappedText(paragraph, thirdPage, 520, 213.5, helveticaFont, 313);
  paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
  drawWrappedText(paragraph, thirdPage, 483, 213.5, helveticaFont, 313);
  paragraph = `${details?.property_measuring || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
  drawWrappedText(paragraph, thirdPage, 458.6, 213.5, helveticaFont, 313);
  date = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");
  paragraph = `under Deed of Transfer No. ${details?.transfer_number || "24646/2024"} dated ${getDayWithSuffix(date.day)} day of ${date?.month} ${date?.year} made in favour of ${details?.purchaser_full_names || "Mike Modise Smith"};`
  drawWrappedText(paragraph, thirdPage, 434.25, 213.5, helveticaFont, 313);
  date = formatTimestamp(details?.date_of_purchase || "2024-10-16T09:43:15.453+00:00");
  paragraph = `and I declare that I sold the same to the said PURCHASER on the ${getDayWithSuffix(date.day)} day of ${date.month} ${date.year} and not before, and that there is not any agreement, condition or understanding between me or to any other person whosoever, for, or in respect of, or in connection with the purchase by him of the said property, any sum of money over and above the said sum of ${details?.purchase_amount || 'P1, 560, 000.00'} ${details?.purchase_amount_in_words || '(One Million Five Hundred and Sixty Thousand Pula)'} and the value of the property for transfer duty purposes is the sum of ${details?.transfer_duty_amount || 'P1, 560, 000.00'} ${details?.transfer_duty_amount_in_words || '(One Million Five Hundred and Sixty Thousand Pula)'} save and except certain charges or payments which fall under or come within one or more of the heads of items of charges or payment following:`
  drawWrappedText(paragraph, thirdPage, 397.7, xPos, helveticaFont, 458);
  paragraph = `AND I further declare that I have not received, and that I am not to receive, nor has any other person received, nor is any other person to received, for my use or benefit, or at my instance or request, any valuable consideration besides the said sum of ${details?.purchase_amount || 'P1, 560, 000.00'} ${details?.purchase_amount_in_words || '(One Million Five Hundred and Sixty Thousand Pula)'} and the value of the property for transfer duty purposes is the sum of ${details?.transfer_duty_amount || 'P1, 560, 000.00'} ${details?.transfer_duty_amount_in_words || '(One Million Five Hundred and Sixty Thousand Pula)'} save and exceptins of aras any of the charges specified,and to be paid by the said PURCHASER might be held or taken to be payable for me or on my behalf.`
  drawWrappedText(paragraph, forthPage, 592, xPos, helveticaFont, 458);
  paragraph = `${details?.seller_full_names || "Daniel Modise"}`
  drawWrappedText(paragraph, forthPage, 375, 390, helveticaFont, 313);
  date = formatTimestamp(details?.date_of_purchase || "2024-10-16T09:43:15.453+00:00");
  paragraph = `DECLARED BEFORE ME AT GABORONE ON THIS  DAY OF  `
  drawWrappedText(paragraph, forthPage, 300, xPos, helveticaFont, 458);





  //////////AFFIDAVIT OF BIRTH ////////////////

  drawCenterTextOnPage(fifthPage, ((details?.seller_full_names || "Masego A Modise")), 0, 604.2, 10)
  paragraph = `I am an adult ${details?.seller_gender || 'male'} citizen of Botswana`
  drawWrappedText(paragraph, fifthPage, 532, 106.2, helveticaFont, 313);
  date = formatTimestamp(details?.seller_date_of_birth || "2024-10-16T09:43:15.453+00:00");
  paragraph = `I was born on the ${getDayWithSuffix(date.day)} day of ${date.month} ${date.year}, which date I have always regarded as my true date of birth.`
  drawWrappedText(paragraph, fifthPage, 508.5, 106.2, helveticaFont, 385);
  paragraph = `I am married in community of property to ${details?.seller_spouse_full_names || "Smith Modise"}`
  let not_married_paragraph = `I am not married`  
  drawWrappedText(details?.seller_marital_status === "Married" ? paragraph : not_married_paragraph, fifthPage, 470.6, 106.2, helveticaFont, 313);

  date = formatTimestamp(details?.seller_date_of_birth || "2024-10-16T09:43:15.453+00:00");
  paragraph = `THUS SIGNED AND SWORN TO BEFORE ME AT GABORONE ON THIS ${'  '} DAY OF ${'              '} , THE DEPONENT HAVING ACKNOWLEDGED THAT SHE KNOWS AND UNDERSTANDS THE CONTENTS OF THIS AFFIDAVIT.`
  drawWrappedText(paragraph, fifthPage, 324, xPos, helveticaFont, 460);




  ///////////// CONSENT FORM ////////////////
  drawCenterTextOnPage(sixthPage, ((details?.seller_spouse_full_names || "Masego A Modise")), 0, 663, 10)
  date = formatTimestamp(details?.seller_spouse_date_of_birth || "2024-10-16T09:43:15.453+00:00");
  paragraph = `(Born on ${getDayWithSuffix(date.day)} day of ${date.month} ${date.year})`
  drawCenterTextOnPage(sixthPage, (paragraph), 0, 650, 10)
  date = formatTimestamp(details?.seller_date_of_birth || "2024-10-16T09:43:15.453+00:00");
  paragraph = `married in community of property to ${details.seller_full_names} (Born on ${getDayWithSuffix(date.day)} day of  ${date.month} ${date.year}), do hereby grant consent to the said ${details?.purchaser_full_names || "Purchaser Name"} to accept a transfer from ${details.seller_full_names} (Born on ${getDayWithSuffix(date.day)} day of ${date.month} ${date.year}) in respect of the following property:`
  drawWrappedText(paragraph, sixPage, 625.5, xPos, helveticaFont, 460);
  paragraph = `piece of land being Lot ${details?.property_lot_number || "76483239"}, ${details?.situate || "Gaborone"} (Portion of Lot ${details?.property_lot_number || "7648345"} ${details?.situate || "Gaborone"};`
  drawWrappedText(paragraph, sixthPage, 553, 213.5, helveticaFont, 313);
  paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
  drawWrappedText(paragraph, sixthPage, 517, 213.5, helveticaFont, 313);
  paragraph = `${details?.property_measuring || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
  drawWrappedText(paragraph, sixthPage, 492, 213.5, helveticaFont, 313);
  date = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");
  paragraph = `under Deed of Transfer No. ${details?.transfer_number || "24646/2024"} dated ${getDayWithSuffix(date.day)} day of ${date?.month} ${date?.year} made in favour of ${details?.purchaser_full_names || "Mike Modise Smith"};`
  drawWrappedText(paragraph, sixthPage, 468, 213.5, helveticaFont, 313);




  //page 6



  //page 7


    drawCenterTextOnPage(seventhPage, ((details?.purchaser_full_names || "Masego A Modise")), 0, 690, 10)

    date = formatTimestamp(details?.purchaser_date_of_birth || "2024-10-16T09:43:15.453+00:00");
    paragraph = `Born on ${getDayWithSuffix(date.day)} day of ${date.month} ${date.year})`
    drawCenterTextOnPage(seventhPage, (paragraph), 0, 677.2, 10)


    drawCenterTextOnPage(seventhPage, ((details?.purchaser_spouse_full_names || "Masego A Modise")), 0, 839.0 - 186, 10)
    paragraph = `do solemnly and sincerely declare that the sum of P${details?.purchase_amount || 'P1, 560, 000.00'} ${details?.purchase_amount_in_words || '(One Million Five Hundred and Sixty Thousand Pula)'} and the value of the property for transfer duty purposes is the sum of ${details?.transfer_duty_amount || 'P1, 560, 000.00'} ${details?.transfer_duty_amount_in_words || '(One Million Five Hundred and Sixty Thousand Pula)'} is the full and entire purchase money given, or to be given by me to`
    drawWrappedText(paragraph, seventhPage, 617, xPos, helveticaFont, 460);



    drawCenterTextOnPage(seventhPage, ((details?.seller_full_names || "Masego A Modise")), 0, 556, 10)
    date = formatTimestamp(details?.seller_date_of_birth || "2024-10-16T09:43:15.453+00:00");
    paragraph = `Born on ${getDayWithSuffix(date.day)} day of ${date.month} ${date.year})`
    drawCenterTextOnPage(seventhPage, (paragraph), 0, 544, 10)

    paragraph = `piece of land being Lot ${details?.property_lot_number || "76486493"},${details?.situate || "Gaborone"} (Portion of Lot ${details?.property_lot_number || "1234/2024"} ${details?.situate || "Gaborone"};`
    drawWrappedText(paragraph, seventhPage,839.0 - 356, 213.5, helveticaFont, 313);

    paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
    drawWrappedText(paragraph, seventhPage, 839.0 - 393 , 213.5, helveticaFont, 313);

    paragraph = `${details?.property_measuring || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
    drawWrappedText(paragraph, seventhPage, 839.0 - 417, 213.5, helveticaFont, 313);

    date = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");
    paragraph = `under Deed of Transfer No. ${details?.transfer_number || "24646/2024"} dated ${getDayWithSuffix(date.day)} day of ${date?.month} ${date?.year} made in favour of ${details?.purchaser_full_names || "Mike Modise Smith"};`
    drawWrappedText(paragraph, seventhPage,  839.0 - 442, 213.5, helveticaFont, 313);

    date = formatTimestamp(details?.date_of_purchase || "2024-10-16T09:43:15.453+00:00");
    paragraph = `and I declare that I bought the same from the said SELLER on the ${getDayWithSuffix(date.day)} day of ${date.month} ${date.year} and not before, and that I have not, nor has any person to my knowledge, on my account, given, nor is there by me or on my behalf, to be given, any other valuable consideration for, or in respect, or in connection with the alienation to me of the said property, save and except certain charges or payment which fall under, or come within, some one or more of the heads or items of charges or payment following:`
    drawWrappedText(paragraph, seventhPage, 839.0 - 478, xPos, helveticaFont, 460);
  
  

  drawWrappedText(details?.purchaser_full_names || "Mike Modise Smith", eighthPage,  839.0 - 355,380, helveticaFont, 460);

////////////   AFFIDAVIT OF BIRTH ///////////////////
  //page 9
  if (1 == 1) {

    drawCenterTextOnPage(ninthPage, ((details?.seller_full_names || "Masego A Modise")), 0,839.0 - 270, 10)
    paragraph = `I am an adult ${details?.seller_gender || 'male'} citizen of ${details?.seller_country || "Botswana"}`
    drawWrappedText(paragraph, ninthPage, 482, 106.2, helveticaFont, 313);
    date = formatTimestamp(details?.seller_date_of_birth || "2024-10-16T09:43:15.453+00:00");
    paragraph = `I was born on the ${getDayWithSuffix(date.day)} day of ${date.month} ${date.year}, which date I have always regarded as my true date of birth.`
    drawWrappedText(paragraph, ninthPage,458, 106.2, helveticaFont, 385);
    paragraph = `I am married in community of property to ${details?.seller_spouse_full_names} which marriage is governed by the Laws of Botswana. `
    drawWrappedText(paragraph, ninthPage,422, 106.2, helveticaFont, 385);

   
  }
  //page 10

  drawCenterTextOnPage(tenthPage, ((details?.appointed_attorney || "Collins Chilisa Conveyancer")), 0,264, 10)
  date = formatTimestamp(details?.date_power_of_attorney_granted || "2024-10-16T09:43:15.453+00:00");
  paragraph = `Appeared before me, THE REGISTRAR OF DEEDS FOR BOTSWANA at GABORONE ${details.appointed_conveyancer_gender === "Male" ? "he" : "she"}, the said Appearer, being duly authorised thereto by a Power of Attorney granted to ${details.appointed_conveyancer_gender === "Male" ? "him" : "her"} at Gaborone on the ${getDayWithSuffix(date.day)} day of ${date.month} ${date.year} by;`
  drawWrappedText(paragraph, tenthPage,240,xPos, helveticaFont, 460);


  drawCenterTextOnPage(tenthPage, ((details?.seller_full_names || "Masego A Modise")), 0,839.0 - 648, 10)

  date = formatTimestamp(details?.seller_date_of_birth || "2024-10-16T09:43:15.453+00:00");
  paragraph = `(Born on ${getDayWithSuffix(date.day)} day of ${date.month} ${date.year})`
  drawCenterTextOnPage(tenthPage, ((paragraph|| "Masego A Modise")), 0,179, 10)



  date = formatTimestamp(details?.property_sale_date || "2024-10-16T09:43:15.453+00:00");
  paragraph = `And the Appearer declared that his principal did on the ${getDayWithSuffix(date.day)} ${date.month} ${date.year} truly and legally sell the hereinafter property to the hereinafter transferee, and that he, in his capacity aforesaid, did by these presents cede and transfer in full and free property to and on behalf of`
  drawWrappedText(paragraph, tenthPage,157,xPos, helveticaFont, 460);

  drawCenterTextOnPage(eleventhPage, ((details?.seller_full_names || "Masego A Modise")), 0,839.0 - 88, 10)
  
  date = formatTimestamp(details?.seller_date_of_birth || "2024-10-16T09:43:15.453+00:00");
  paragraph = `(Born on ${getDayWithSuffix(date.day)} day of ${date.month} ${date.year})`
  drawCenterTextOnPage(eleventhPage, ((paragraph)), 0,839.0 - 101, 10)


  drawCenterTextOnPage(eleventhPage, ((details?.seller_spouse_full_names || "Masego A Modise")),0, 839.0 - 125.5, 10)
  paragraph = `piece of land being Lot ${details?.property_lot_number || "764854"}, (Portion of Lot ${details?.property_lot_number || "764824"} ;`
  drawWrappedText(paragraph, eleventhPage,839.0 - 187, 213.5, helveticaFont, 313);

  paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
  drawWrappedText(paragraph, eleventhPage,  617 , 213.5, helveticaFont, 313);

  paragraph = `${details?.property_measuring || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
  drawWrappedText(paragraph, eleventhPage, 593, 213.5, helveticaFont, 313);
  
  date = formatTimestamp(details?.surveyed_on || "2024-10-16T09:43:15.453+00:00");
  let date_ = formatTimestamp(details?.approval_date_by_director || "2024-10-16T09:43:15.453+00:00");
  paragraph = `from General Plan D.S.M. No.${details?.dsm_number || '87809'} surveyed by Land Surveyor ${details?.land_surveyor || 'Land Surveyor'} in ${date.month} ${date.year} and approved by the Director of Surveys and Mapping on the ${date_.day} day of ${date_.month} ${date_.year}`
  drawWrappedText(paragraph, eleventhPage, 556, 213.5, helveticaFont, 313);
  
  date = formatTimestamp(details?.certificate_of_consolidated_title_no_dated || "2024-10-16T09:43:15.453+00:00");
  date_ = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");

  paragraph = `was held under Certificate of Consolidated Title No. ${details?.certificate_of_consolidated_title_no || '59874334/2024'} dated ${date.day} ${date.month} ${date.year} and subsequent Deeds the last of which is Deed of Transfer No. ${details?.transfer_number} dated ${date_.day} day of ${date_.month} ${date_.year} made in favour of ${details?.seller_full_names || "Sellers_name"}; `
  drawWrappedText(paragraph, eleventhPage, 495, 213.5, helveticaFont, 313);

  date = formatTimestamp(details?.certificate_of_consolidated_title_no_dated || "2024-10-16T09:43:15.453+00:00");
  paragraph = 'to Notarial Deed of Abondonment of Mineral Rights No. 6/73 dated 29th day of June 1973;'
  drawWrappedText(paragraph, eleventhPage, 434, 213.5, helveticaFont, 313);

let _date='';
  //page 12
  drawCenterTextOnPage(twelfthPage, ((details?.seller_full_names || "Masego A Modise")), 0, 839.0 - 174, 10)
  date = formatTimestamp(details?.seller_date_of_birth || "2024-10-16T09:43:15.453+00:00");
  paragraph = `(Born on ${getDayWithSuffix(date.day)} day of ${date.month} ${date.year})`
  drawCenterTextOnPage(twelfthPage, ((paragraph)), 0,839.0 - 186, 10)
  drawCenterTextOnPage(twelfthPage, ((details?.seller_spouse_full_names || "Karabo A Modise")), 0,839.0 - 210, 10)


  paragraph = `his heirs, executors, administrators or assigns now is and henceforth shall be entitled thereto, conformably to local custom. The State, however, reserving its rights; and finally acknowledging the purchase price amounting to ${details?.purchase_amount || 'P1, 560, 000.00'} ${details?.purchase_amount_in_words || '(One Million Five Hundred and Sixty Thousand Pula)'} and the value of the property for transfer duty purposes is the sum of ${details?.transfer_duty_amount ||'P1 600, 000.00 (One Million Six Hundred Thousand Pula)'} to have been satisfactorily paid or secured.`
  drawWrappedText(paragraph, twelfthPage,839.0 - 247,xPos, helveticaFont, 460);

  const modifiedPdfBytes = await pdfDoc.save();
  return modifiedPdfBytes;
};

export default TransferIndividual;
