import BondAbsa from "../../PDFComponents/BondAbsa";
import IndividualToIndividualTransfer from "../../PDFComponents/TransferIndividual";
import CompanyToIndividualTransfer from "../../PDFComponents/TransferCompany-Individual";
import EstateTransfer from "../../PDFComponents/TransferEstate";
import MortgageBondApplicationAccess from "../../PDFComponents/MortgageBondApplicationAccess";
import MortgageBondApplicationFnb from "../../PDFComponents/MortgageBondApplicationFnb";
import MortgageBondApplicationBbs from "../../PDFComponents/MortgageBondApplicationBbs";
import MortgageBondApplicationAbsa from "../../PDFComponents/MortgageBondApplicationAbsa";
import SuretyBondApplicationAbsa from "../../PDFComponents/SuretyBondApplicationAbsa";
import SuretyBondApplicationBbs from "../../PDFComponents/SuretyBondApplicationBbs";


import axios from "axios";

const api = "https://collins-chilisa.testlive.space/api/";

export const processTransaction = async (caseDetails) => {


  const savefile = async (data) => {
    console.log({ data });
    try {
      // Convert Uint8Array to a Blob
      const uint8Array = new Uint8Array(data);
      const blob = new Blob([uint8Array], {
        type: "application/octet-stream", // Set the appropriate MIME type
      });
      const file = new File(
        [blob],
        `${caseDetails?.transactionData.formName?.toString()}_Case Document.pdf`
      ); // Set a filename for the upload

      const formData = new FormData();
      formData.append("file", file); // Use 'file' as the key based on your server-side setup

      // Post the file using Axios
      const response = await axios.post(`${api}v1/files`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      // Return the uploaded file URL
      return response.data.data.url;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error; // Optionally re-throw the error for further handling
    }
  };

  let transactionType =
    caseDetails?.transactionData.formName?.toString().trim() || "Unknown";

  let method;


  let details = await caseDetails?.transactionData?.formSchema.map(
    ({ name, value }) => {
      return { name, value };
    }
  );

  const flattenedObject = await details?.reduce((acc, curr) => {
    acc[curr.name] = curr.value;
    return acc;
  }, {});

  details = {
    ...flattenedObject,
  };


  switch (transactionType.toLowerCase()) {
    case "cancellation of mortgage bond":
      method = await savefile(await BondAbsa(caseDetails));
      break;
    case "individual to individual transfer":
      method = await savefile(
        await IndividualToIndividualTransfer(caseDetails)
      );
      break;
    case "company to individual transfer":
      method = await savefile(await CompanyToIndividualTransfer(caseDetails));
      break;
    case "estate transfer":
      method = await savefile(await EstateTransfer(caseDetails));
      break;

    case "application of mortgage bond- fnb":
      method = await savefile(await MortgageBondApplicationFnb(caseDetails));
      break;

    case "application of mortgage bond- bbs":
      method = await savefile(await MortgageBondApplicationBbs(caseDetails));
      break;

    case "application of mortgage bond- absa":
      method = await savefile(await MortgageBondApplicationAbsa(caseDetails));
      break;

    case "application of mortgage bond- access bank":
      method = await savefile(await MortgageBondApplicationAccess(caseDetails));
      break;

    case "application of surety bond- absa":
      method =await savefile(await SuretyBondApplicationAbsa(caseDetails));
      break;


    case "application of surety bond- bbs":
      method = await savefile(await SuretyBondApplicationBbs(caseDetails));
      break;
    default:
      method = "Invalid transaction type";
  }

  console.log(method);

  return method;
};

export const processPreviewTransaction = async (caseDetails) => {


  let transactionType =
    caseDetails?.transactionData.formName?.toString().trim() || "Unknown";

  let method;


  let details = await caseDetails?.transactionData?.formSchema.map(
    ({ name, value }) => {
      return { name, value };
    }
  );

  const flattenedObject = await details?.reduce((acc, curr) => {
    acc[curr.name] = curr.value;
    return acc;
  }, {});

  details = {
    ...flattenedObject,
  };



  if (transactionType.toLowerCase().includes("mortgage bond")) {

     let kycDetails = await caseDetails?.kyc[0]?.formSchema.map(item => {
      return {
          ...item,
          name: item.name.replace(/(_\d+)+$/, ''), // Remove any sequence of _digit(s) at the end of the string
        };
  });
    
    
    const kycFlattenedObject = await kycDetails?.reduce((acc, curr) => {
      acc[curr.name] = curr.value;
      return acc;
    }, {});

    details = {
      ...flattenedObject, ...kycFlattenedObject
    };
    
    console.log("this is the mortageg case")
    console.log({details})
    console.log("this is the mortageg case")
  }


 if (transactionType.toLowerCase().includes("transfer")) {

    let kycDetails_purchaser = await caseDetails?.kyc[0]?.formSchema.reduce((acc, item) => {
      acc["purchaser_" + item.name.replace(/(_\d+)+$/, '')] = item.value;
      return acc;
    }, {});
  
    let kycDetails_seller = await caseDetails?.kyc[1]?.formSchema.reduce((acc, item) => {
      acc["seller_" + item.name.replace(/(_\d+)+$/, '')] = item.value;
      return acc;
    }, {});
  
    // Assuming flattenedObject is already an object, merge everything
    details = {
      ...kycDetails_seller, 
      ...kycDetails_purchaser, 
      ...flattenedObject
    };
  }
  
  if (transactionType.toLowerCase().includes("surety")) {



    let kycDetails = await caseDetails?.kyc[0]?.formSchema.map(item => {
      return {
        ...item,
        name: item.name.replace(/(_\d+)+$/, ''), // Remove any sequence of _digit(s) at the end of the string
      };
    });

    const kycFlattenedObject = await kycDetails?.reduce((acc, curr) => {
      acc[curr.name] = curr.value;
      return acc;
    }, {});

    details = {
      ...flattenedObject, ...kycFlattenedObject
    };
  }

  switch (transactionType.toLowerCase()) {
    case "cancellation of mortgage bond":
      method = await BondAbsa(details);
      break;
    case "individual to individual transfer":
      method = await IndividualToIndividualTransfer(details);
      break;
    case "company to individual transfer":
      method = await CompanyToIndividualTransfer(details);
      break;
    case "estate transfer":
      method = await EstateTransfer(details);
      break;

    case "application of mortgage bond- fnb":
      method = await MortgageBondApplicationFnb(details);
      break;

    case "application of mortgage bond- bbs":
      method = await MortgageBondApplicationBbs(details);
      break;

    case "application of mortgage bond- absa":
      method = await MortgageBondApplicationAbsa(details);
      break;


    case "application of mortgage bond- access bank":
      method = await MortgageBondApplicationAccess(details);
      break;

    case "application of surety bond- absa":
        method = await SuretyBondApplicationAbsa(details);
        break;


    case "application of surety bond- bbs":
        method = await SuretyBondApplicationBbs(details);
        break;

    default:
      method = "Invalid transaction type";
  }

  console.log(method);

  return method;
};




// import BondAbsa from "../../PDFComponents/BondAbsa";
// import IndividualToIndividualTransfer from "../../PDFComponents/TransferIndividual";
// import CompanyToIndividualTransfer from "../../PDFComponents/TransferCompany-Individual";
// import EstateTransfer from "../../PDFComponents/TransferEstate";
// import MortgageBondApplicationAccess from "../../PDFComponents/MortgageBondApplicationAccess";
// import MortgageBondApplicationFnb from "../../PDFComponents/MortgageBondApplicationFnb";
// import MortgageBondApplicationBbs from "../../PDFComponents/MortgageBondApplicationBbs";
// import MortgageBondApplicationAbsa from "../../PDFComponents/MortgageBondApplicationAbsa";


// import axios from "axios";

// const api = "https://collins-chilisa.testlive.space/api/";

// export const processTransaction = async (caseDetails) => {


//   const savefile = async (data) => {
//     console.log({ data });
//     try {
//       // Convert Uint8Array to a Blob
//       const uint8Array = new Uint8Array(data);
//       const blob = new Blob([uint8Array], {
//         type: "application/octet-stream", // Set the appropriate MIME type
//       });
//       const file = new File(
//         [blob],
//         `${caseDetails?.transactionData.formName?.toString()}_Case Document.pdf`
//       ); // Set a filename for the upload

//       const formData = new FormData();
//       formData.append("file", file); // Use 'file' as the key based on your server-side setup

//       // Post the file using Axios
//       const response = await axios.post(`${api}v1/files`, formData, {
//         headers: { "Content-Type": "multipart/form-data" },
//       });

//       // Return the uploaded file URL
//       return response.data.data.url;
//     } catch (error) {
//       console.error("Error uploading file:", error);
//       throw error; // Optionally re-throw the error for further handling
//     }
//   };

//   let transactionType =
//     caseDetails?.transactionData.formName?.toString().trim() || "Unknown";

//   let method;


//   let details = await caseDetails?.transactionData?.formSchema.map(
//     ({ name, value }) => {
//       return { name, value };
//     }
//   );

//   const flattenedObject = await details?.reduce((acc, curr) => {
//     acc[curr.name] = curr.value;
//     return acc;
//   }, {});

//   details = {
//     ...flattenedObject,
//   };



//   switch (transactionType.toLowerCase()) {
//     case "cancellation of mortgage bond":
//       method = await savefile(await BondAbsa(caseDetails));
//       break;
//     case "individual to individual transfer":
//       method = await savefile(
//         await IndividualToIndividualTransfer(caseDetails)
//       );
//       break;
//     case "company to individual transfer":
//       method = await savefile(await CompanyToIndividualTransfer(caseDetails));
//       break;
//     case "estate transfer":
//       method = await savefile(await EstateTransfer(caseDetails));
//       break;

//     case "application of mortgage bond- fnb":
//       method = await savefile(await MortgageBondApplicationFnb(caseDetails));
//       break;

//     case "application of mortgage bond- bbs":
//       method = await savefile(await MortgageBondApplicationBbs(caseDetails));
//       break;

//     case "application of mortgage bond- absa":
//       method = await savefile(await MortgageBondApplicationAbsa(caseDetails));
//       break;

//     case "application of mortgage bond- access bank":
//       method = await savefile(await MortgageBondApplicationAccess(caseDetails));
//       break;
//     default:
//       method = "Invalid transaction type";
//   }

//   console.log(method);

//   return method;
// };

// export const processPreviewTransaction = async (caseDetails) => {
 

//   let transactionType =
//     caseDetails?.transactionData.formName?.toString().trim() || "Unknown";

//   let method;

  
//   let details = await caseDetails?.transactionData?.formSchema.map(
//     ({ name, value }) => {
//       return { name, value };
//     }
//   );

//   const flattenedObject = await details?.reduce((acc, curr) => {
//     acc[curr.name] = curr.value;
//     return acc;
//   }, {});

//   details = {
//     ...flattenedObject,
//   };


//   switch (transactionType.toLowerCase()) {
//     case "cancellation of mortgage bond":
//       method = await BondAbsa(details);
//       break;
//     case "individual to individual transfer":
//       method = await IndividualToIndividualTransfer(details);
//       break;
//     case "company to individual transfer":
//       method = await CompanyToIndividualTransfer(details);
//       break;
//     case "estate transfer":
//       method = await EstateTransfer(details);
//       break;

//     case "application of mortgage bond- fnb":
//       method = await MortgageBondApplicationFnb(details);
//       break;

//     case "application of mortgage bond- bbs":
//       method = await MortgageBondApplicationBbs(details);
//       break;

//     case "application of mortgage bond- absa":
//       method = await MortgageBondApplicationAbsa(details);
//       break;


//     case "application of mortgage bond- access bank":
//       method = await MortgageBondApplicationAccess(details);
//       break;

//     default:
//       method = "Invalid transaction type";
//   }

//   console.log(method);

//   return method;
// };

