import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import pdfDocument from "../Documents/SURETY BOND ABSA BANK.pdf";
import fontkit from "@pdf-lib/fontkit";
import fontt from './verdana.ttf';

const SuretyBondApplicationAbsa = async (details) => {
  const fetchPdf = async () => {
    const existingPdfBytes = await fetch(pdfDocument).then((res) =>
      res.arrayBuffer()
    );
    return existingPdfBytes;
  };

  const pdfBytes = await fetchPdf();
  

  const pdfDoc = await PDFDocument.load(pdfBytes);
  pdfDoc.registerFontkit(fontkit);
  const response = await fetch(fontt);
  const fontBytes = await response.arrayBuffer();
  const verdana = await pdfDoc.embedFont(fontBytes); // Embed the custom font
  const helveticaFont = await pdfDoc.embedFont(fontBytes); // Embed the custom font

  const font = await pdfDoc.embedFont(StandardFonts.TimesRomanBoldItalic);
  const form = pdfDoc.getForm();

  const firstPage = pdfDoc.getPage(0);
  const secondPage = pdfDoc.getPage(1);
  const thirdPage = pdfDoc.getPage(2);
  const forthPage = pdfDoc.getPage(3);
  const fifthPage = pdfDoc.getPage(4);
  const sixthPage = pdfDoc.getPage(5);
  const seventhPage = pdfDoc.getPage(6);
  const eighthPage = pdfDoc.getPage(7);
  const ninthPage = pdfDoc.getPage(8);
  const tenthPage = pdfDoc.getPage(9);
  const eleventhPage = pdfDoc.getPage(10);
  const twelfthPage = pdfDoc.getPage(11);
  const thirteenthPage = pdfDoc.getPage(12);

  const { width, height } = firstPage.getSize();
  let textWidth = "";
  let centerX = "";

  const drawWrappedText = (
    text,
    page,
    yPos,
    xPos,
    font,
    maxWidth,
    boldFont
  ) => {
    const paragraphs = text.split("\n"); // Split into paragraphs using \n
    const lineHeight = 12; // Adjust this value to control the space between lines

    paragraphs.forEach((paragraph) => {
      const words = paragraph.split(/(\s+)/); // Split by words and preserve spaces
      let currentLine = [];
      let currentLineWidth = 0;

      words.forEach((word) => {
        const isBold = word.startsWith("**") && word.endsWith("**");
        const cleanWord = isBold ? word.replace(/\*\*/g, "") : word;
        const wordFont = isBold ? boldFont : font;
        const wordWidth = wordFont.widthOfTextAtSize(cleanWord, 10);

        if (currentLineWidth + wordWidth > maxWidth && currentLine.length > 0) {
          // Draw the current line and start a new one
          drawFormattedLine(currentLine, xPos, yPos, font, boldFont, page);
          currentLine = [{ word: cleanWord, isBold }];
          currentLineWidth = wordWidth;
          yPos -= lineHeight; // Move down for the next line
        } else {
          currentLine.push({ word: cleanWord, isBold });
          currentLineWidth += wordWidth;
        }
      });

      // Draw the last line of the paragraph
      drawFormattedLine(currentLine, xPos, yPos, font, boldFont, page);
      yPos -= lineHeight; // Add extra space between paragraphs
    });
  };

  // Helper function to draw a single line with formatting
  const drawFormattedLine = (line, xPos, yPos, font, boldFont, page) => {
    line.forEach(({ word, isBold }) => {
      const wordFont = isBold ? boldFont : font;
      page.drawText(word, {
        x: xPos,
        y: yPos,
        size: 10,
        font: wordFont,
        color: rgb(0, 0, 0),
      });
      xPos += wordFont.widthOfTextAtSize(word, 10); // Adjust xPos for word width
    });
  };

  // Helper function to draw a single line
  const drawLine = (line, xPos, yPos, font, page) => {
    for (let i = 0; i < line.length; i++) {
      page.drawText(line[i], {
        x: xPos,
        y: yPos,
        size: 10,
        font: font,
        color: rgb(0, 0, 0),
      });
      xPos += font.widthOfTextAtSize(line[i], 10) + 2; // Add word spacing
    }
  };

  const drawCenterTextOnPage = (pageName, text, xPos, yPos, size) => {
    const textWidth = verdana.widthOfTextAtSize(text, size);
    const center = (width - textWidth) / 2;
    pageName.drawText(text, {
      x: center,
      y: yPos,
      size: size,
      font: verdana,
      color: rgb(0, 0, 0),
    });
  };

  // Rendering function that processes an array of content objects
  const renderContent = (contentArray) => {
    contentArray.forEach((item) => {
      switch (item.type) {
        case "centerText":
          drawCenterTextOnPage(
            item.page,
            item.text,
            item.xPos || 0,
            item.yPos,
            item.size,
            item.font
          );
          break;
        case "wrappedText":
          drawWrappedText(
            item.text,
            item.page,
            item.yPos,
            item.xPos,
            item.font,
            item.maxWidth
          );
          break;
        case "line":
          drawLine(item.line, item.xPos, item.yPos, item.font, item.page);
          break;
        // Add more cases if you have other content types
        default:
          console.warn(`Unknown content type: ${item.type}`);
      }
    });
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getUTCFullYear();

    return { day, month, year };
  }

  function getDayWithSuffix(day) {
    const suffixes = ["th", "st", "nd", "rd"];
    const remainder = day % 10;
    const suffix =
      (day % 100 >= 11 && day % 100 <= 13) || remainder > 3
        ? suffixes[0]
        : suffixes[remainder];
    return `${day}${suffix}`;
  }

  // Format the dates
  const powerOfAttorneyDate = formatTimestamp(
    details.powerOfAttorneyDate || "2024-01-01T00:00:00Z"
  );

  const dateOfAuthorisation = formatTimestamp(
    details.date_of_authorisation || "2024-01-01T00:00:00Z"
  );
  //format suffix
  const dateOfAuthorisationDay = getDayWithSuffix(dateOfAuthorisation.day);

  const dateOfResolution = formatTimestamp(
    details.date_of_resolution || "2024-01-01T00:00:00Z"
  );
  //format suffix
  const dateOfResolutionDay = getDayWithSuffix(dateOfResolution.day);

  const principalDebtorDateOfBirth = formatTimestamp(
    details.principal_debtor_date_of_birth || "2024-01-01T00:00:00Z"
  );
  //format suffix
  const principalDebtorDateOfBirthDay = getDayWithSuffix(
    principalDebtorDateOfBirth.day
  );

  const dateOfTransfer = formatTimestamp(
    details.date_of_transfer || "2024-01-01T00:00:00Z"
  );
  //format suffix
  const dateOfTransferDay = getDayWithSuffix(dateOfTransfer.day);

  // Determine pronouns based on gender
  const genderInput = (details.director_gender || "male").toLowerCase();

  let directorPronoun = "she";
  let directorPossessivePronoun = "her";

  if (genderInput === "male") {
    directorPronoun = "he";
    directorPossessivePronoun = "his";
  }

  // Content data for the given blocks
  const contentData = [
    /*PAGE 1*/
    // Block 12
    {
      type: "centerText",
      page: firstPage,
      text: details?.conveyancer_name || "conveyancer", // Replace with actual registrar's name
      yPos: 244.51, // y-coordinate from Coordinates
      size: 10, // Adjust font size as needed
      font: helveticaFont,
    },
    // Block 13
    {
      type: "wrappedText",
      page: firstPage,
      text: `appeared before me, the REGISTRAR OF DEEDS for BOTSWANA at ${
        details.signedAt || "GABORONE"
      }, ${directorPronoun}, the said Appearer, being duly authorised thereto by virtue of a Power of Attorney dated the ${dateOfAuthorisationDay} day of ${
        dateOfAuthorisation.month
      }, ${dateOfAuthorisation.year} and signed at ${
        details.signedAt || "Gaborone"
      } granted to ${directorPossessivePronoun} by:`,
      xPos: 66.02, // x-coordinate from Coordinates
      yPos: 220.27362060546875, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 483.66, // Width from the provided data
    },
    // Block 14
    {
      type: "centerText",
      page: firstPage,
      text: details.director_name || "Director Name", // Replace with actual text
      yPos: 171.55, // y-coordinate from Coordinates
      size: 10, // Adjust font size as needed
      font: helveticaFont,
    },
    // Block 15
    {
      type: "wrappedText",
      page: firstPage,
      text: `in ${details.directorPronoun || "her"} capacity as ${
        details.capacity || "DIRECTOR"
      } of`,
      xPos: 226.42, // x-coordinate from Coordinates
      yPos: 159.29, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 162.94, // Width from the provided data
    },
    // Block 16
    {
      type: "centerText",
      page: firstPage,
      text: details.company_name || "Company Name", // Replace with actual text
      yPos: 147.29, // y-coordinate from Coordinates
      size: 10,
      font: helveticaFont,
    },
    // Block 19
    {
      type: "centerText",
      page: firstPage,
      text: `passed at ${
        details.signedAt || "Gaborone"
      } on the ${dateOfResolutionDay} day of ${dateOfResolution.month} ${
        dateOfResolution.year
      }`,
      yPos: 110.81, // y-coordinate from Coordinates
      size: 10,
      font: helveticaFont,
    },
    /*PAGE 2*/
    // Block 5
    {
      type: "centerText",
      page: secondPage,
      text: `(Born on the ${principalDebtorDateOfBirthDay} day of ${principalDebtorDateOfBirth.month} ${principalDebtorDateOfBirth.year})`,
      yPos: 657.9136199951172, // y-coordinate from Coordinates
      size: 10, // Adjust font size as needed
      font: helveticaFont,
    },
    // Block 4
    {
      type: "centerText",
      page: secondPage,
      text: details.principal_debtor_name || "Principal Debtor Name", // Placeholder text
      yPos: 669.9435882568359, // y-coordinate
      size: 10, // Font size
      font: helveticaFont,
    },
    // Block 7
    {
      type: "centerText",
      page: secondPage,
      text: details.spouse_full_names || "Spouse Name", // Placeholder text
      yPos: 633.4335784912109, // y-coordinate
      size: 10, // Font size
      font: helveticaFont,
    },
    // Block 15
    {
      type: "centerText",
      page: secondPage,
      text: details.full_names || "Mortgagor ", // Placeholder text
      yPos: 426.9836120605469, // y-coordinate
      size: 10, // Font size
      font: helveticaFont,
    },
    // Block 16
    {
      type: "wrappedText",
      page: secondPage,
      text: `(hereinafter referred to as “the Mortgagor”) as surety and co-principal debtor to the Mortgagee and also hypothecating the hereinafter described immovable property as security therefore; \n\nAND WHEREAS it is a further special condition of receiving the security of this Bond to be furnished in pursuance thereof which shall be deemed to cover the principal Debtor’s indebtedness to “the Bank” at any time up to but not exceeding the sum of ${
        details.principal_sum_amount || "P1,000,000.00"
      } (${
        details.principal_sum_amount_in_words || "One Million Pula"
      });\n\n AND all interest calculated as hereinafter provided (hereinafter referred to as “the Capital”) being the amount which “the Bank” has agreed to lend to the Principal Debtors and which amount is to be advanced to the Principal Debtors or their nominees subject to the terms, conditions and provisions of this Bond and under security thereof; and\n\n In addition to the Capital amount this Bond shall be preferent in so far as any costs and charges incurred by “the Bank” in having the Bond prepared and registered and in suing for the recovery of the said sum or any portion thereof such costs, charges and disbursements in so far as they are not preference hereby being secured as preferent to the additional extent of ${
        details.additional_sum_amount || "P100,000"
      } (${
        details.sum_amount_in_words || "Hundred Thousand Pula"
      });\n\n of the above amount just as if they were portion of the Capital of the Bond. Such additional extent is hereafter referred to as “the Additional Sum”`,
      xPos: 66.02400207519531, // x-coordinate from Coordinates
      yPos: 402.4836120605469, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 483.71006774902344, // Width from the provided data
    },
    /*PAGE 3*/
    // Block 2
    {
      type: "wrappedText",
      page: thirdPage,
      text: `said Mortgagor to be held and firmly bound as surety and co-principal debtor to the said Mortgagee its successors in title or assigns in the aforesaid sum of ${
        details.principal_sum_amount || "P1,000,000.00"
      } (${
        details.principal_sum_amount_in_words || "One Million Pula"
      }) (hereinafter referred to as “the Principal Sum”) together with additional sum of ${
        details.additional_sum_amount || "P100,000"
      } (${
        details.sum_amount_in_words || "Hundred Thousand Pula"
      }) for the due payment by the said Principal Debtor.`,
      xPos: 66.024, // x-coordinate from Coordinates
      yPos: 718.664, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 483.626, // Width from the provided data
    },
    // Block 3
    {
      type: "wrappedText",
      page: thirdPage,
      text: `and, in addition, as security for the due payment over prime rates current from time to time and calculated in accordance with banking practice, the Appearer q.q. hereby declares to bind specially the hereinafter described immovable properties (hereinafter referred to as “the Mortgaged Property”) as First Continuing Covering Surety Mortgage:\n\nCERTAIN \t\t\t\t\t:\t\t piece of land being Lot ${
        details.lot || "12345"
      }, ${details.situated || "Gaborone"};`,
      xPos: 66.024, // x-coordinate from Coordinates
      yPos: 633.434, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 483.611, // Width from the provided data
    },
    // Block 5
    {
      type: "wrappedText",
      page: thirdPage,
      text: `SITUATE \t\t\t\t\t :\t\t in ${
        details.situated || "Gaborone plot 12345"
      };`,
      xPos: 66.024, // x-coordinate from Coordinates
      yPos: 548.454, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 310.62, // Width from the provided data
    },
    // Block 7
    {
      type: "wrappedText",
      page: thirdPage,
      text: `MEASURING \t\t\t :\t\t ${
        details.square_metres || "123"
      } m\u00B2 (${
        details.square_metres_in_words ||
        "One Hundred And Twenty Three Square Metres"
      });`,
      xPos: 66.02400207519531, // x-coordinate from Coordinates
      yPos: 524.214, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 483.9, // Width from the provided data
    },
    // Block 9
    {
      type: "wrappedText",
      page: thirdPage,
      text: `HELD UNDER \t\t\t:\t\t `,
      xPos: 66.024, // x-coordinate from Coordinates
      yPos: 499.704, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 483.675, // Width from the provided data
    },
    // Block 9.5
    {
      type: "wrappedText",
      page: thirdPage,
      text: `Deed of Transfer No. ${
        details.deed_of_transfer_no || "12345"
      } dated ${dateOfTransferDay} day of ${dateOfTransfer.month}, ${
        dateOfTransfer.year
      } made in favour of ${details.full_names || "Mortgagor"};`,
      xPos: 195.024, // x-coordinate from Coordinates
      yPos: 499.704, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 346, // Width from the provided data
    },
    // Block 10
    {
      type: "wrappedText",
      page: thirdPage,
      text: `SUBJECT TO \t\t\t :\t\t the conditions and reservations mentioned in the aforesaid Deed.`,
      xPos: 66.024, // x-coordinate from Coordinates
      yPos: 463.2236022949219, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 483.675, // Width from the provided data
    },
    /*PAGE 6*/
    // Block 2
    {
      type: "wrappedText",
      page: sixthPage, // Replace with the actual page object for Page 6
      text: `13.`,
      xPos: 66.024, // x-coordinate from Coordinates
      yPos: 718.664, // y-coordinate from Coordinates
      font: helveticaFont, // Use your desired font
      maxWidth: 483.642, // Width from the provided data
    },
    // Block 2
    {
      type: "wrappedText",
      page: sixthPage, // Replace with the actual page object for Page 6
      text: `ALL amounts from time to time payable by the Mortgagor to the Mortgagee shall be paid punctually, together with interest at the Mortgagee’s prime rate ${
        details.prime_rate || "10"
      }% (${details.prime_rate_in_words || "Ten"} Per centum) plus ${
        details.interest_rate || "10"
      }% (${details.interest_rate_in_words || "Ten"} Per Centum) effectively ${
        details.interest_rate || "10"
      }% (${
        details.interest_rate_in_words || "ten"
      } Per Centum) per annum (calculated on a daily basis and compounded monthly) or such other rate as the Bank may from time to time determine and without demand or deductions to the Mortgagee at the office of ${
        details.bank_name || "Absa Bank Botswana Limited"
      }, ${
        details.bank_address || "Securities Centre, P O Box 478, Gaborone"
      }, or at such other place, if any, as the Mortgagee may designate for the time being by written notice to the Mortgagor.`,
      xPos: 101.08999633789062, // x-coordinate from Coordinates
      yPos: 718.664, // y-coordinate from Coordinates
      font: helveticaFont, // Use your desired font
      maxWidth: 442, // Width from the provided data
    },
    /*PAGE 7*/
    //Block 1
    {
      type: "wrappedText",
      page: seventhPage, // Replace with the actual page object
      text: `16.`,
      xPos: 66.024, // x-coordinate from Coordinates
      yPos: 378.244, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 483.71, // Width from the provided data
    },
    //Block 2
    {
      type: "wrappedText",
      page: seventhPage, // Replace with the actual page object
      text: `ANY indebtedness for the time being owing by the Mortgagor to the Mortgagee in respect of, legal and other costs of and incidental to this Bond and/or any proceedings instituted by the Mortgagee against the Mortgagor hereunder or otherwise, rates, taxes, stamp duties and/or any other costs and/or expenses incurred in the protection of security of the Mortgagee hereunder, shall be covered and secured under this Bond over and above the Principal Sum to the maximum extent of ${
        details.additional_sum_amount || "P100,000"
      } (${
        details.additional_sum_amount_in_words || "Hundred Thousand Pula"
      }); provided that the aforegoing shall not be construed as excluding any such indebtedness from the general cover and security afforded hereunder up to the Principal Sum.`,
      xPos: 101.08999633789062, // x-coordinate from Coordinates
      yPos: 378.244, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 442, // Width from the provided data
    },
    /*PAGE 8*/
    // Block 6
    {
      type: "wrappedText",
      page: eighthPage, // Replace with the actual page variable
      text: `IN WITNESS WHEREOF, I, the said REGISTRAR, together with the Appearer, q.q., have
subscribed to these presents and have caused the Seal of Office to be affixed thereto.

THUS DONE AND EXECUTED at the Office of the REGISTRAR OF DEEDS for BOTSWANA
at ${
        details.signedAt || "GABORONE"
      } on this the ${dateOfAuthorisationDay} day of the month of ${
        dateOfAuthorisation.month
      } in the Year of Our Lord, ${dateOfAuthorisation.year}.`,
      xPos: 66.024, // x-coordinate from Coordinates
      yPos: 511.974, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 483.71, // Width from the provided data
    },
    /*PAGE 10*/
    // Block 8
    {
      type: "centerText",
      page: tenthPage,
      text: details.director_name || "Director Name", // Placeholder for block text
      xPos: 249.22, // x-coordinate
      yPos: 621.43, // y-coordinate
      size: 10,
      font: helveticaFont,
      maxWidth: 117.25, // Width from data
    },
    // Block 9
    {
      type: "centerText",
      page: tenthPage,
      text: `in my capacity as ${details.capacity || "DIRECTOR"} of`,
      xPos: 226.66, // x-coordinate from Coordinates
      yPos: 609.19, // y-coordinate from Coordinates
      size: 10,
      font: helveticaFont,
      maxWidth: 162.22, // Width from provided data
    },
    // Block 10
    {
      type: "centerText",
      page: tenthPage,
      text: details.company_name || "Company Name", // Placeholder for block text
      xPos: 195.67, // x-coordinate
      yPos: 596.95, // y-coordinate
      size: 10,
      font: helveticaFont,
      maxWidth: 224.08, // Width from data
    },
    // Block 14
    {
      type: "centerText",
      page: tenthPage,
      text: `passed at ${details.signedAt || "Gaborone"} on the ${
        dateOfAuthorisationDay || "12"
      } day of ${dateOfAuthorisation.month || "May"} ${
        dateOfAuthorisation.year || "2024"
      }`,
      yPos: 560.6935882568359, // y-coordinate from Coordinates
      size: 10,
      font: helveticaFont,
    },
    // Block 15
    {
      type: "centerText",
      page: tenthPage,
      text: details.conveyancer_name || "Conveyancer",
      yPos: 511.9736328125,
      size: 10,
      font: helveticaFont,
    },
    // Block 16
    {
      type: "wrappedText",
      page: tenthPage,
      text: `with power of substitution to be my true and lawful Attorney and Agent for and in my name, place and stead and to appear before the REGISTRAR OF DEEDS for BOTSWANA at ${
        details.signedAt || "GABORONE"
      } and then and there as my act and deed to sign the FIRST CONTINUING COVERING SURETY MORTGAGE BOND in favour of ${
        details.bank_name || "ABSA BANK BOTSWANA LIMITED"
      } for a sum of ${details.principal_sum_amount || "P1,000,000"} (${
        details.principal_sum_amount_in_words || "One Million Pula"
      }) over the following property:-\n\nCERTAIN \t\t\t\t\t:\t\t piece of land being Lot ${
        details.lot || "1234"
      }, ${details.situated || "Gaborone"};`,
      xPos: 66.02400207519531,
      yPos: 487.70361328125,
      font: helveticaFont,
      maxWidth: 473,
    },
    // Block 18
    {
      type: "wrappedText",
      page: tenthPage,
      text: `SITUATE \t\t\t\t\t :\t\t ${
        details.situated || "in Gaborone Plot 1"
      }`,
      xPos: 66.02400207519531,
      yPos: 378.24359130859375,
      font: helveticaFont,
      maxWidth: 310.61976623535156,
    },
    // Block 20
    {
      type: "wrappedText",
      page: tenthPage,
      text: `MEASURING \t\t\t :\t\t ${
        details.square_metres || "123"
      } m\u00B2 (${
        details.square_metres_in_words ||
        "One Hundred And Twenty Three Square Metres"
      })`,
      xPos: 66.02400207519531,
      yPos: 354.00360107421875,
      font: helveticaFont,
      maxWidth: 408.8097686767578,
    },
    // Block 22
    {
      type: "wrappedText",
      page: tenthPage,
      text: `HELD UNDER \t\t\t:\t\t `,
      xPos: 66.02400207519531,
      yPos: 329.50360107421875,
      font: helveticaFont,
      maxWidth: 483.6987762451172,
    },
    // Block 22.5
    {
      type: "wrappedText",
      page: tenthPage,
      text: `Deed of Transfer No. ${
        details.deed_of_transfer_no || "12345"
      } dated ${dateOfTransferDay || "12th"} day of ${
        dateOfTransfer.month || "June"
      } ${dateOfTransfer.year || "2024"} made in favour of ${
        details.full_names || "Jordan Elana"
      };`,
      xPos: 195.024,
      yPos: 329.50360107421875,
      font: helveticaFont,
      maxWidth: 346,
    },
    // Block 23
    {
      type: "wrappedText",
      page: tenthPage,
      text: `SUBJECT TO \t\t\t :\t\t the conditions and reservations mentioned in the aforesaid Deed.`,
      xPos: 66.02400207519531,
      yPos: 293.26361083984375,
      font: helveticaFont,
      maxWidth: 480.1097869873047,
    },
    // Block 25
    {
      type: "wrappedText",
      page: tenthPage,
      text: `in terms of the draft Bond annexed hereto and arising from the causes therein mentioned to make and authenticate all alterations, additions and/or deletions in and to the said Bond attached hereto as may be necessary for the purposes of registration thereof; to receive, if necessary, the sum/s to be advanced to me up to the full amount; and generally for effecting the purposes aforesaid, to do or cause to be done whatsoever shall be requisite, as fully and effectually, to all intents and purposes as I might or could do if personally present and acting therein, hereby ratifying, allowing and confirming and promising and agreeing to ratify, allow and confirm all and whatsoever my said Attorney and Agent shall lawfully do or cause to be done in the premises by virtue of these presents.\n\n\nTHUS DONE AND SIGNED AT ${
        details.signedAt || "GABORONE"
      } ON THIS ${dateOfAuthorisationDay || "14th"} DAY OF ${
        dateOfAuthorisation.month || "May"
      } ${dateOfAuthorisation.year || "2024"}.`,
      xPos: 66.02400207519531,
      yPos: 268.78363037109375,
      font: helveticaFont,
      maxWidth: 473,
    },
    /*PAGE 12*/
    // Block 4
    {
      type: "centerText",
      page: twelfthPage,
      text: details.company_name || "Electric Corp", // Replace with actual text if available
      yPos: 694.4235687255859, // y-coordinate
      xPos: 195.91000366210938, // x-coordinate
      size: 10, // Adjust font size as needed
      font: helveticaFont,
      maxWidth: 223.83529663085938, // Width from the provided data
    },

    // Block 5
    {
      type: "centerText",
      page: twelfthPage, // Page 12
      text: `PASSED AT ${details.signedAt || "GABORONE"} ON THE ${
        dateOfResolutionDay || "13th"
      } DAY OF ${dateOfResolution.month || "May"} ${
        dateOfResolution.year || "2024"
      }`,
      yPos: 682.18, // y-coordinate from Coordinates
      size: 10,
      font: helveticaFont,
    },
    // Block 6
    {
      type: "wrappedText",
      page: twelfthPage,
      text: `RESOLVED:\n\n\n1.`,
      xPos: 66.02, // x-coordinate from Coordinates
      yPos: 645.67, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 483.54, // Width from the provided data
    },
    // Block 7
    {
      type: "wrappedText",
      page: twelfthPage,
      text: `\n\n\nThat ${
        details.mortgagor || "Mortgagor"
      } should stand as surety for the Loan obtained by ${
        details.principal_debtor_name || "Principal Debtor"
      } which marriage is governed by the Laws of Botswana (hereinafter referred to as "the Principal Debtor") from ABSA BANK BOTSWANA LIMITED.`,
      xPos: 102.05, // x-coordinate from Coordinates
      yPos: 645.67, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 483.54, // Width from the provided data
    },
    // Block 8.5
    {
      type: "wrappedText",
      page: twelfthPage,
      text: `2.`,
      xPos: 66.02, // x-coordinate from Coordinates
      yPos: 536.21, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 483.54, // Width from the provided data
    },
    // Block 9
    {
      type: "wrappedText",
      page: twelfthPage,
      text: `That as security for the loan ${
        details.principal_debtor_name || "Principal Debtor"
      } pass a FIRST CONTINUING COVERING SURETY MORTGAGE BOND for the sum of P${
        details.principal_sum_amount || "1,000,000.00"
      } (${
        details.principal_sum_amount_in_words || "One Million Pula"
      }) together with the additional sum of P${
        details.additional_sum_amount || "100,000.00"
      } (${
        details.additional_sum_amount_in_words || "Hundred Thousand Pula"
      }) and the Bank’s prime interest rate at ${
        details.prime_rate || "10"
      }% (${details.prime_rate_in_words || "Ten"}) Per centum plus ${
        details.interest_rate || "10"
      }% (${details.interest_rate_in_words || "Ten"} Per Centum) effectively ${
        details.interest_rate || "10"
      } % (${
        details.interest_rate_in_words || "Ten"
      } Per Centum) per annum calculated daily and compounded monthly over the undermentioned property, namely:-`,
      xPos: 102.05,
      yPos: 536.21,
      font: helveticaFont,
      maxWidth: 449,
    },
    // Block 13
    {
      type: "wrappedText",
      page: twelfthPage,
      text: `CERTAIN \t\t\t\t\t:\t\t `,
      xPos: 102.05,
      yPos: 414.72,
      font: helveticaFont,
      maxWidth: 319.23,
    },
    // Block 13.5
    {
      type: "wrappedText",
      page: twelfthPage,
      text: `piece of land being Lot ${details.lot || "1234"}, ${details.situated || "Gaborone"};`,
      xPos: 228,
      yPos: 414.72,
      font: helveticaFont,
      maxWidth: 384,
    },
    // Block 15
    {
      type: "wrappedText",
      page: twelfthPage,
      text: `SITUATE \t\t\t\t\t :\t\t in ${
        details.situated || "Gaborone Plot 1"
      };`,
      xPos: 102.05,
      yPos: 390.48,
      font: helveticaFont,
      maxWidth: 294.06,
    },
    // Block 16
    {
      type: "wrappedText",
      page: twelfthPage,
      text: `MEASURING \t\t\t :\t\t ${
        details.square_metres || "123"
      } m\u00B2 (${
        details.square_metres_in_words ||
        "One Hundred And Twenty Three Square Metres"
      });`,
      xPos: 102.05,
      yPos: 366.0,
      font: helveticaFont,
      maxWidth: 341.34,
    },
    // Block 18
    {
      type: "wrappedText",
      page: twelfthPage,
      text: `HELD UNDER \t\t\t:\t\t `,
      xPos: 102.05,
      yPos: 341.76,
      font: helveticaFont,
      maxWidth: 467.13,
    },
    // Block 18.5
    {
      type: "wrappedText",
      page: twelfthPage,
      text: `Deed of Transfer No. ${
        details.deed_of_transfer_no || "211"
      } dated ${dateOfTransferDay || "17th"} day of ${
        dateOfTransfer.month || "May"
      } made in favour of ${details.principal_debtor_name || "Debtor Name"}`,
      xPos: 228,
      yPos: 341.76,
      font: helveticaFont,
      maxWidth: 322,
    },
    // Block 19
    {
      type: "wrappedText",
      page: twelfthPage,
      text: `3.`,
      xPos: 66.02,
      yPos: 293.26,
      font: helveticaFont,
      maxWidth: 483.42,
    },
    // Block 20
    {
      type: "wrappedText",
      page: twelfthPage,
      text: `That ${
        details.conveyancer_name || "Conveyancer Name"
      } be and are hereby authorised to make any alterations or annotations required for registration purposes only.`,
      xPos: 102.05,
      yPos: 293.26,
      font: helveticaFont,
      maxWidth: 435,
    },
    //Block 20.5
    // Block 21
    {
      type: "wrappedText",
      page: twelfthPage,
      text: `4.`,
      xPos: 66.02,
      yPos: 244.51,
      font: helveticaFont,
      maxWidth: 483.65,
    },
    // Block 21
    {
      type: "wrappedText",
      page: twelfthPage,
      text: `That ${details.director_name || "Director Name"} in ${
        directorPossessivePronoun || "her"
      } capacity as ${
        details.capacity || "DIRECTOR"
      } be and is hereby authorised to sign all documents to give effect to this Resolution.`,
      xPos: 102.05,
      yPos: 244.51,
      font: helveticaFont,
      maxWidth: 435.65,
    },
    // Block 22
    {
      type: "wrappedText",
      page: twelfthPage,
      text: `${details.capacity || "DIRECTOR"}`,
      xPos: 395.8999938964844,
      yPos: 173.79, // Position below the signature line
      size: 10,
      font: helveticaFont,
    },
    /*PAGE 13*/
    // Block 6
    {
      type: "centerText",
      page: thirteenthPage, // Replace with your page variable for page 13
      text: details.principal_debtor_name || "Principal Debtor", // Replace x's with placeholder
      yPos: 536.214, // y-coordinate from Coordinates
      size: 10, // Adjust font size as needed
      font: helveticaFont,
    },
    // Block 8
    {
      type: "wrappedText",
      page: thirteenthPage,
      text: `do hereby make oath and swear that:\n\n\n1.\t\t\tI am an adult ${
        details.director_gender || "male"
      } citizen of ${details.country || "Botswana"}.`,
      xPos: 66.024, // x-coordinate from Coordinates
      yPos: 499.704, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 483.546, // Adjust as needed
    },
    // Block 9
    {
      type: "wrappedText",
      page: thirteenthPage,
      text: `2.\t\t\t`,
      xPos: 66.024,
      yPos: 438.984,
      font: helveticaFont,
      maxWidth: 483.442, // Width from the provided data
    },
    // Block 9.5
    {
      type: "wrappedText",
      page: thirteenthPage,
      text: `I was born on the ${principalDebtorDateOfBirthDay} day of ${principalDebtorDateOfBirth.month} ${principalDebtorDateOfBirth.year}, which date I have always regarded as my true date of birth.`,
      xPos: 107.024,
      yPos: 438.984,
      font: helveticaFont,
      maxWidth: 417, // Width from the provided data
    },
    // Block 10


    {
      type: "wrappedText",
      page: thirteenthPage,
      text: `3.\t\t\tI am ${details?.marital_status === "Married" ? `married to ${details?.spouse_full_names}` : "not married"}.`,
      xPos: 66.024,
      yPos: 402.484,
      font: helveticaFont,
      maxWidth: 381.93,
    },
    // Block 15
    {
      type: "wrappedText",
      page: thirteenthPage,
      text: `THUS SIGNED AND SWORN TO BEFORE ME AT ${
        details.signedAt || "GABORONE"
      } ON THIS ${dateOfAuthorisationDay || "12th"} DAY OF ${
        dateOfAuthorisation.month || "May"
      } ${
        dateOfAuthorisation.year || "2024"
      }, THE DEPONENT HAVING ACKNOWLEDGED THAT ${
        details.principal_debtor_pronoun || "HE"
      } KNOWS AND UNDERSTANDS THE CONTENTS OF THIS AFFIDAVIT.`,
      xPos: 66.024,
      yPos: 244.514,
      font: helveticaFont,
      maxWidth: 483.546,
    },
  ];

  renderContent(contentData);

  const modifiedPdfBytes = await pdfDoc.save();
  return modifiedPdfBytes;
};

export default SuretyBondApplicationAbsa;
