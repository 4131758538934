import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import pdfDocument from "../Documents/BOND BBS BANK.pdf";
import fontkit from "@pdf-lib/fontkit";
import fontt from './verdana.ttf';

const MortgageBondApplicationBbs = async (details) => {
  const fetchPdf = async () => {
    const existingPdfBytes = await fetch(pdfDocument).then((res) =>
      res.arrayBuffer()
    );
    return existingPdfBytes;
  };

  const pdfBytes = await fetchPdf();


 

  const pdfDoc = await PDFDocument.load(pdfBytes);
  pdfDoc.registerFontkit(fontkit);
  const response = await fetch(fontt);
  const fontBytes = await response.arrayBuffer();
  const verdana = await pdfDoc.embedFont(fontBytes); // Embed the custom font
  const helveticaFont = await pdfDoc.embedFont(fontBytes); // Embed the custom font

  const font = await pdfDoc.embedFont(StandardFonts.TimesRomanBoldItalic);
  const form = pdfDoc.getForm();

  const firstPage = pdfDoc.getPage(0);
  const secondPage = pdfDoc.getPage(1);
  const thirdPage = pdfDoc.getPage(2);
  const forthPage = pdfDoc.getPage(3);
  const fifthPage = pdfDoc.getPage(4);
  const sixthPage = pdfDoc.getPage(5);
  const seventhPage = pdfDoc.getPage(6);
  const eighthPage = pdfDoc.getPage(7);
  const ninthPage = pdfDoc.getPage(8);
  const tenthPage = pdfDoc.getPage(9);
  const eleventhPage = pdfDoc.getPage(10);
  const twelfthPage = pdfDoc.getPage(11);
  const thirteenthPage = pdfDoc.getPage(12);
  const fourteenthPage = pdfDoc.getPage(13);
  const fifteenthPage = pdfDoc.getPage(14);
  const sixteenthPage = pdfDoc.getPage(15);

  const { width, height } = firstPage.getSize();
  let textWidth = "";
  let centerX = "";

  const drawWrappedText = (projects, page, yPos, xPos, font, maxWidth) => {
    const words = projects.split(" ");
    const lineHeight = 12; // Adjust this value to control the space between lines
    const fontSize = 10;
    const additionalSpace = 2; // Extra space to add between words
  
    let currentLine = [];
    let currentLineWidth = 0;
  
    // Helper function to draw a justified line
    const drawJustifiedLine = (line, lineWidth, xPos, yPos, font, page, isLastLine = false) => {
      if (line.length === 1 || isLastLine) {
        // If it's the last line or a single word, draw normally without justification
        const text = line.join(" ");
        page.drawText(text, {
          x: xPos,
          y: yPos,
          size: fontSize,
          font,
        });
      } else {
        // Calculate extra space to justify the line
        const extraSpace = (maxWidth - lineWidth) / (line.length - 1) + additionalSpace;
  
        let currentX = xPos;
        for (let i = 0; i < line.length; i++) {
          page.drawText(line[i], {
            x: currentX,
            y: yPos,
            size: fontSize,
            font,
          });
          if (i < line.length - 1) {
            // Add extra space between words
            currentX += font.widthOfTextAtSize(line[i], fontSize) + extraSpace;
          }
        }
      }
    };
  
    // Iterate through each word in the text
    for (let i = 0; i < words.length; i++) {
      const wordWidth = font.widthOfTextAtSize(words[i], fontSize);
  
      // Check if adding the word exceeds the max width of the line
      if (currentLineWidth + wordWidth + font.widthOfTextAtSize(" ", fontSize) > maxWidth) {
        // If so, draw the justified line
        drawJustifiedLine(currentLine, currentLineWidth, xPos, yPos, font, page);
        currentLine = [words[i]]; // Start a new line with the current word
        currentLineWidth = wordWidth;
        yPos -= lineHeight; // Move down for the next line
      } else {
        // Otherwise, add the word to the current line
        currentLine.push(words[i]);
        currentLineWidth += wordWidth + font.widthOfTextAtSize(" ", fontSize);
      }
    }
  
    // Draw the last line without justification
    drawJustifiedLine(currentLine, currentLineWidth, xPos, yPos, font, page, true);
  };

  // Helper function to draw a single line with formatting
  const drawFormattedLine = (line, xPos, yPos, font, boldFont, page) => {
    line.forEach(({ word, isBold }) => {
      const wordFont = isBold ? boldFont : font;
      page.drawText(word, {
        x: xPos,
        y: yPos,
        size: 10,
        font: wordFont,
        color: rgb(0, 0, 0),
      });
      xPos += wordFont.widthOfTextAtSize(word, 10); // Adjust xPos for word width
    });
  };

  // Helper function to draw a single line
  const drawLine = (line, xPos, yPos, font, page) => {
    for (let i = 0; i < line.length; i++) {
      page.drawText(line[i], {
        x: xPos,
        y: yPos,
        size: 10,
        font: font,
        color: rgb(0, 0, 0),
      });
      xPos += font.widthOfTextAtSize(line[i], 10) + 2; // Add word spacing
    }
  };

  const drawCenterTextOnPage = (pageName, text, xPos, yPos, size) => {
    const textWidth = verdana.widthOfTextAtSize(text, size);
    const center = (width - textWidth) / 2;
    pageName.drawText(text, {
      x: center,
      y: yPos,
      size: size,
      font: verdana,
      color: rgb(0, 0, 0),
    });
  };

  // Rendering function that processes an array of content objects
  const renderContent = (contentArray) => {
    contentArray.forEach((item) => {
      switch (item.type) {
        case "centerText":
          drawCenterTextOnPage(
            item.page,
            item.text,
            item.xPos || 0,
            item.yPos,
            item.size,
            item.font
          );
          break;
        case "wrappedText":
          drawWrappedText(
            item.text,
            item.page,
            item.yPos,
            item.xPos,
            item.font,
            item.maxWidth
          );
          break;
        case "line":
          drawLine(item.line, item.xPos, item.yPos, item.font, item.page);
          break;
        // Add more cases if you have other content types
        default:
          console.warn(`Unknown content type: ${item.type}`);
      }
    });
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getUTCFullYear();

    return { day, month, year };
  }

  function getDayWithSuffix(day) {
    const suffixes = ["th", "st", "nd", "rd"];
    const remainder = day % 10;
    const suffix =
      (day % 100 >= 11 && day % 100 <= 13) || remainder > 3
        ? suffixes[0]
        : suffixes[remainder];
    return `${day}${suffix}`;
  }

  // Format the dates
  const dateOfAuthorisation = formatTimestamp(
    details.date_of_authorisation || "2024-01-01T00:00:00Z"
  );
  // Format suffix
  const dateOfAuthorisationDay = getDayWithSuffix(dateOfAuthorisation.day);

  const mortgagorDateOfBirth = formatTimestamp(
    details.date_of_birth || "2024-01-01T00:00:00Z"
  );
  // Format suffix
  const mortgagorDateOfBirthDay = getDayWithSuffix(mortgagorDateOfBirth.day);

  const dateOfTransfer = formatTimestamp(
    details.date_of_transfer || "2024-01-01T00:00:00Z"
  );
  // Format suffix
  const dateOfTransferDay = getDayWithSuffix(dateOfTransfer.day);

  const dateOfRegistration = formatTimestamp(
    details.date_of_registration || "2024-01-01T00:00:00Z"
  );
  // Format suffix
  const dateOfRegistrationDay = getDayWithSuffix(dateOfRegistration.day);

  // Determine pronouns based on gender
  const conveyancer_genderInput = (
    details.conveyancer_gender || "male"
  ).toLowerCase();

  let conveyancerPronoun = "she";
  let conveyancerPossessivePronoun = "her";
  let conveyancerObjectivePronoun = "her";

  if (conveyancer_genderInput === "male") {
    conveyancerPronoun = "he";
    conveyancerObjectivePronoun = "him";
    conveyancerPossessivePronoun = "his";
  }

  const mortgager_genderInput = (
    details.gender || "male"
  ).toLowerCase();

  let mortgagorPronoun = "she";
  let mortgagorPossessivePronoun = "her";
  let mortgagorObjectivePronoun = "her";

  if (mortgager_genderInput === "male") {
    mortgagorPronoun = "he";
    mortgagorPossessivePronoun = "his";
    mortgagorObjectivePronoun = "him";
  }

  // Content data for the given blocks
  const contentData = [
    /*PAGE 1*/
    // Block 8
    {
      type: "wrappedText",
      page: firstPage,
      text: `THAT ${
        details.conveyancer_name || "Conveyancer Name"
      } (“the Appearer"), a duly admitted Conveyancer, appeared before me, the REGISTRAR OF DEEDS FOR BOTSWANA at ${
        details.location || "GABORONE"
      }, he, the said Appearer, being duly authorised thereto by a Power of Attorney granted to ${
        conveyancerObjectivePronoun || "him"
      } at ${details.powerOfAttorneyGrantedAt || "Gaborone"} on the ${
        dateOfAuthorisationDay || "day"
      } day of ${dateOfAuthorisation.month || "month"} ${
        dateOfAuthorisation.year || "year"
      } by:`,
      xPos: 90.048, // x-coordinate from Coordinates
      yPos: 297.584, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth:width - 150, // Width from the provided data
    },
    // Block 9
    {
      type: "centerText",
      page: firstPage,
      text: details?.full_names || "Mortgagor Name",
      xPos: 251.62, // x-coordinate from Coordinates
      yPos: 236.834, // y-coordinate from Coordinates
      size: 10,
      font: helveticaFont,
    },
    // Block 10
    {
      type: "centerText",
      page: firstPage,
      text: `(Born on the ${mortgagorDateOfBirthDay || "day"} day of ${
        mortgagorDateOfBirth.month || "month"
      } ${mortgagorDateOfBirth.year || "year"}, ${
        details.grantor_status || "Spinster"
      })`,
      xPos: 180.55, // x-coordinate from Coordinates
      yPos: 224.594, // y-coordinate from Coordinates
      size: 10,
      font: helveticaFont,
    },
    /*PAGE 2*/
    // Block 7
    {
      type: "wrappedText",
      page: secondPage,
      text: `1.1`,
      xPos: 126.05, // x-coordinate from Coordinates
      yPos: 662.23, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 382.53, // Width from provided data
    },
    // Block 7.5
    {
      type: "wrappedText",
      page: secondPage,
      text: `the sum of P${details.principal_sum_amount || "1 000, 000.00"} (${
        details.principal_sum_amount_in_words ||
        "One Million Two Hundred and Ten Thousand"
      } Pula) (“the maximum sum”) and the additional sum of P${
        details.additional_sum_amount || "302, 500.00"
      } (${
        details.additional_sum_amount_in_words ||
        "Three Hundred and Two Thousand Five Hundred"
      } Pula) (“the additional sum") of lawful money howsoever and from whatsoever cause arising, including but not restricted to:`,
      xPos: 162.07000732421875, // x-coordinate from Coordinates
      yPos: 662.23, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 346.25811767578125, // Width from provided data
    },

    // Block 17
    {
      type: "wrappedText",
      page: secondPage,
      text: `1.2`,
      xPos: 126.05, // x-coordinate from Coordinates
      yPos: 370.54, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 418.54, // Width from provided data
    },
    // Block 17.5
    {
      type: "wrappedText",
      page: secondPage,
      text: `interest on all amounts secured by this bond calculated at the rate of ${
        details.prime_rate || "6.76"
      }% (${
        details.prime_rate_in_words || "Six point seven six"
      } per centum) plus ${details.interest_rate || "2"}% (${
        details.interest_rate_in_words || "Two"
      } per centum) per annum, calculated on a daily basis and compounded monthly, or such other rate as the Bank may stipulate in writing to the Mortgagor from time to time. Any interest unpaid on due date will be capitalised and will thereafter bear interest at the prevailing rate until paid;`,
      xPos: 162.07000732421875, // x-coordinate from Coordinates
      yPos: 370.54, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 346.25811767578125, // Width from provided data
    },
    /*PAGE 3*/
    // Block 13
    {
      type: "wrappedText",
      page: thirdPage,
      text: `CERTAIN :`,
      xPos: 126.05, // x-coordinate
      yPos: 516.5036, // y-coordinate
      font: helveticaFont,
      maxWidth: 333.68, // Width from the provided data
    },
    // Block 13.5
    {
      type: "wrappedText",
      page: thirdPage,
      text: `piece of land being Lot ${details.property_lot_number || "1234"}, ${
        details.situated || "Gaborone"
      };`,
      xPos: 233, // x-coordinate
      yPos: 516.5036, // y-coordinate
      font: helveticaFont,
      maxWidth: 270, // Width from the provided data
    },
    // Block 14
    {
      type: "wrappedText",
      page: thirdPage,
      text: `SITUATE :`,
      xPos: 126.05, // x-coordinate
      yPos: 492.0236, // y-coordinate
      font: helveticaFont,
      maxWidth: 312.54, // Width from the provided data
    },
    // Block 14.5
    {
      type: "wrappedText",
      page: thirdPage,
      text: `in the ${details.situated || "Gaborone Administrative District"};`,
      xPos: 233, // x-coordinate
      yPos: 492.0236, // y-coordinate
      font: helveticaFont,
      maxWidth: 270, // Width from the provided data
    },
    // Block 15
    {
      type: "wrappedText",
      page: thirdPage,
      text: `MEASURING :`,
      xPos: 126.05, // x-coordinate
      yPos: 467.7836, // y-coordinate
      font: helveticaFont,
      maxWidth: 292.38, // Width from the provided data
    },
    // Block 15.5
    {
      type: "wrappedText",
      page: thirdPage,
      text: `${details.square_metres || "123"} m\u00B2 (${
        details.square_metres_in_words ||
        "One Hundred And Twenty Three Square Meters"
      });`,
      xPos: 233, // x-coordinate
      yPos: 467.7836, // y-coordinate
      font: helveticaFont,
      maxWidth: 270, // Width from the provided data
    },
    // Block 16
    {
      type: "wrappedText",
      page: thirdPage,
      text: `HELD UNDER :`,
      xPos: 126.05, // x-coordinate
      yPos: 443.5236, // y-coordinate
      font: helveticaFont,
      maxWidth: 394.91, // Width from the provided data
    },
    // Block 16.5
    {
      type: "wrappedText",
      page: thirdPage,
      text:`Deed of Transfer No. ${details?.deed_of_transfer_no || "24646/2024"} dated ${dateOfTransferDay} day of ${dateOfTransfer.month} ${dateOfTransfer.year} made in favour of ${details?.full_names || "Mike Modise Smith"};`,
      xPos: 233, // x-coordinate
      yPos: 443.5236, // y-coordinate
      font: helveticaFont,
      maxWidth: 270, // Width from the provided data
    },
    /*PAGE 13*/
    // Block 24
    {
      type: "wrappedText",
      page: thirteenthPage, // Assuming the page is already initialized
      text: `THUS DONE AND EXECUTED at the Office of the REGISTRAR OF DEEDS FOR BOTSWANA at ${
        details.location || "GABORONE"
      } on this the ${dateOfRegistrationDay || "13th"} of the month of ${
        dateOfRegistration.month || "May"
      } in the Year of Our Lord, ${dateOfRegistration.year || "2024"}`,
      xPos: 90.048, // x-coordinate from Coordinates
      yPos: 370.544, // y-coordinate from Coordinates
      font: helveticaFont,
      maxWidth: 432.116, // Width from the provided data
    },

    /*PAGE 15*/
    // Block 9
    {
      type: "centerText",
      page: fifteenthPage,
      text: details?.full_names || details?.partyName || "Mortgagor Name",
      yPos: 637.9935760498047,
      size: 10,
      font: helveticaFont,
    },
    // Block 10
    {
      type: "centerText",
      page: fifteenthPage,
      text: `(Born on the ${mortgagorDateOfBirthDay || "13th"} day of ${
        mortgagorDateOfBirth.month || "May"
      } ${mortgagorDateOfBirth.year || "1988"}, ${
        details.bornStatus || "Bachelor"
      })`,
      yPos: 625.7335662841797,
      size: 10,
      font: helveticaFont,
    },
    // Block 11
    {
      type: "wrappedText",
      page: fifteenthPage,
      text: `being fully acquainted with the contents of the bond hereinafter set out and especially with the legal force of the benefits of the legal exceptions mentioned and renounced therein which are fully understood by me, hereby nominate and appoint ${
        details.conveyancer_name || "Conveyencer Name"
      } with power of substitution, to be my Attorney and Agent to appear before the REGISTRAR OF DEEDS at ${
        details.registrarCity || "GABORONE"
      } or any other competent official in the Republic of Botswana and then and there as our act and deed to sign and execute the said bond in favour of ${
        details.bankName || "BBS BANK LIMITED"
      } and also to cede to ${
        details.bankName || "BBS BANK LIMITED"
      } all insurance policies therein referred to, and to make and authenticate all such alterations, additions, and/or deletions in and to the said bond as may be necessary for the purpose of registration thereof, in respect of the following property:`,
      xPos: 90.0479965209961,
      yPos: 601.4935760498047,
      font: helveticaFont,
      maxWidth: 418.63236236572266,
    },
    // Block 12
    {
      type: "wrappedText",
      page: fifteenthPage,
      text: `CERTAIN :`,
      xPos: 126.05000305175781,
      yPos: 455.52362060546875,
      font: helveticaFont,
      maxWidth: 326.72377014160156,
    },
    // Block 12.5
    {
      type: "wrappedText",
      page: fifteenthPage,
      text: `piece of land being Lot ${details.property_lot_number || "1234"}, ;`,
      xPos: 233,
      yPos: 455.52362060546875,
      font: helveticaFont,
      maxWidth: 270,
    },
    // Block 13
    {
      type: "wrappedText",
      page: fifteenthPage,
      text: `SITUATE :`,
      xPos: 126.05000305175781,
      yPos: 431.2835998535156,
      font: helveticaFont,
      maxWidth: 312.5437469482422,
    },
    // Block 13.5
    {
      type: "wrappedText",
      page: fifteenthPage,
      text: `in the ${details.situated || "Gaborone Administrative District"};`,
      xPos: 233,
      yPos: 431.2835998535156,
      font: helveticaFont,
      maxWidth: 270,
    },
    // Block 14
    {
      type: "wrappedText",
      page: fifteenthPage,
      text: `MEASURING :`,
      xPos: 126.05000305175781,
      yPos: 407.0436096191406,
      font: helveticaFont,
      maxWidth: 292.38377380371094,
    },
    // Block 14.5
    {
      type: "wrappedText",
      page: fifteenthPage,
      text: `${details.square_metres || "123"} m\u00B2 (${
        details.square_metres_in_words ||
        "One Hundred And Twenty Three Square Meters"
      });`,
      xPos: 233,
      yPos: 407.0436096191406,
      font: helveticaFont,
      maxWidth: 270,
    },
    // Block 15
    {
      type: "wrappedText",
      page: fifteenthPage,
      text: `HELD UNDER:`,
      xPos: 126.05000305175781,
      yPos: 382.8036193847656,
      font: helveticaFont,
      maxWidth: 394.9137420654297,
    },
    // Block 15.5
    {
      type: "wrappedText",
      page: fifteenthPage,
      text:`Deed of Transfer No. ${details?.deed_of_transfer_no || "24646/2024"} dated ${dateOfTransferDay} day of ${dateOfTransfer.month} ${dateOfTransfer.year} made in favour of ${details?.full_names || "Mike Modise Smith"};`,
      xPos: 233,
      yPos: 382.8036193847656,
      font: helveticaFont,
      maxWidth: 270,
    },
    // Block 19
    {
      type: "wrappedText",
      page: fifteenthPage,
      text: `THUS DONE AND PASSED UNDER MY HAND AT ${
        details.signedAt || "GABORONE"
      } ON THE ${dateOfRegistrationDay || "1st"} DAY OF ${
        dateOfRegistration.month || "May"
      } ${dateOfRegistration.year || "2023"}.\n\n\nAS WITNESSES:\n\n\n1.\t\t ${
        details.witness_1_name || "Witness 1"
      }\n\n\n2.\t\t ${details.witness_2_name || "Witness 2"}`,
      xPos: 90.0479965209961,
      yPos: 254,
      font: helveticaFont,
      maxWidth: 413,
    },

    // Block 22
    {
      type: "wrappedText",
      page: fifteenthPage,
      text: details.conveyancer_name || "Conveyancer name",
      xPos: 390.6499938964844,
      yPos: 90.86761474609375,
      size: 10,
      font: helveticaFont,
    },
    /*PAGE 16*/
    // Block 9
    {
      type: "centerText",
      page: sixteenthPage,
      text: details?.full_names || details?.partyName || "Mortgagor Name",
      yPos: 550.1336059570312,
      size: 10,
      font: helveticaFont,
    },
    // Block 11
    {
      type: "wrappedText",
      page: sixteenthPage,
      text: `do hereby make oath and swear that:\n\n\n1. \t\tI am an adult ${
        details.gender || "male"
      } citizen of ${details.country || "Botswana"}.\n\n`,
      xPos: 90.0479965209961,
      yPos: 513.6235961914062,
      font: helveticaFont,
      maxWidth: 241.95575714111328,
    },
    // Block 12
    {
      type: "wrappedText",
      page: sixteenthPage,
      text: `2.`,
      xPos: 90.0479965209961,
      yPos: 452.88360595703125,
      font: helveticaFont,
      maxWidth: 418.45862579345703,
    },
    // Block 12
    {
      type: "wrappedText",
      page: sixteenthPage,
      text: `I was born on the ${mortgagorDateOfBirthDay || "2nd"} day of ${
        mortgagorDateOfBirth.month || "month"
      }, ${
        mortgagorDateOfBirth.year || "year"
      }, which date I have always regarded as my true date of birth.`,
      xPos: 119,
      yPos: 452.88360595703125,
      font: helveticaFont,
      maxWidth: 384,
    },
    // Block 20
    {
      type: "wrappedText",
      page: sixteenthPage,
      text: `THUS SIGNED AND SWORN TO BEFORE ME AT ${
        details.signedAt || "GABORONE"
      } ON THIS ${dateOfRegistrationDay || "day"}`,
      xPos: 90.0479965209961,
      yPos: 246.193603515625,
      font: helveticaFont,
      maxWidth: 419.5068130493164,
    },
    // Block 21
    {
      type: "wrappedText",
      page: sixteenthPage,
      text: `DAY OF ${dateOfRegistration.month || "month"} ${
        dateOfRegistration.year || "2023"
      }, THE DEPONENT HAVING ACKNOWLEDGED THAT ${
        mortgagorPronoun.toUpperCase() || "SHE"
      } KNOWS AND UNDERSTANDS THE CONTENTS OF THIS AFFIDAVIT.`,
      xPos: 90.0479965209961,
      yPos: 233.95361328125,
      font: helveticaFont,
      maxWidth: 418.19071197509766,
    },
  ];

  renderContent(contentData);

  const modifiedPdfBytes = await pdfDoc.save();
  return modifiedPdfBytes;
};

export default MortgageBondApplicationBbs;
