import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import pdfDocument from "../Documents/SURETY BOND BBS Limited.pdf";
import fontkit from "@pdf-lib/fontkit";
import fontt from './verdana.ttf';

const SuretyBondApplicationBbs = async (details) => {
  const fetchPdf = async () => {
    const existingPdfBytes = await fetch(pdfDocument).then((res) =>
      res.arrayBuffer()
    );
    return existingPdfBytes;
  };

  const pdfBytes = await fetchPdf();
 

  const pdfDoc = await PDFDocument.load(pdfBytes);
  pdfDoc.registerFontkit(fontkit);
  const response = await fetch(fontt);
  const fontBytes = await response.arrayBuffer();
  const verdana = await pdfDoc.embedFont(fontBytes); // Embed the custom font
  const helveticaFont = await pdfDoc.embedFont(fontBytes); // Embed the custom font

  const font = await pdfDoc.embedFont(StandardFonts.TimesRomanBoldItalic);
  const form = pdfDoc.getForm();

  const firstPage = pdfDoc.getPage(0);
  const secondPage = pdfDoc.getPage(1);
  const thirdPage = pdfDoc.getPage(2);
  const fourthPage = pdfDoc.getPage(3);
  const fifthPage = pdfDoc.getPage(4);
  const sixthPage = pdfDoc.getPage(5);
  const seventhPage = pdfDoc.getPage(6);
  const eighthPage = pdfDoc.getPage(7);
  const ninthPage = pdfDoc.getPage(8);
  const tenthPage = pdfDoc.getPage(9);
  const eleventhPage = pdfDoc.getPage(10);
  const twelfthPage = pdfDoc.getPage(11);
  const thirteenthPage = pdfDoc.getPage(12);
  const fourteenthPage = pdfDoc.getPage(13);
  const fifteenthPage = pdfDoc.getPage(14);
  const sixteenthPage = pdfDoc.getPage(15);
  const seventeenthPage = pdfDoc.getPage(16);
  const eighteenthPage = pdfDoc.getPage(17);
  const nineteenthPage = pdfDoc.getPage(18);
  const twentiethPage = pdfDoc.getPage(19);

  const { width, height } = firstPage.getSize();
  let textWidth = "";
  let centerX = "";

  const drawWrappedText = (
    text,
    page,
    yPos,
    xPos,
    font,
    maxWidth,
    boldFont
  ) => {
    const paragraphs = text.split("\n"); // Split into paragraphs using \n
    const lineHeight = 12; // Adjust this value to control the space between lines

    paragraphs.forEach((paragraph) => {
      const words = paragraph.split(/(\s+)/); // Split by words and preserve spaces
      let currentLine = [];
      let currentLineWidth = 0;

      words.forEach((word) => {
        const isBold = word.startsWith("**") && word.endsWith("**");
        const cleanWord = isBold ? word.replace(/\*\*/g, "") : word;
        const wordFont = isBold ? boldFont : font;
        const wordWidth = wordFont.widthOfTextAtSize(cleanWord, 10);

        if (currentLineWidth + wordWidth > maxWidth && currentLine.length > 0) {
          // Draw the current line and start a new one
          drawFormattedLine(currentLine, xPos, yPos, font, boldFont, page);
          currentLine = [{ word: cleanWord, isBold }];
          currentLineWidth = wordWidth;
          yPos -= lineHeight; // Move down for the next line
        } else {
          currentLine.push({ word: cleanWord, isBold });
          currentLineWidth += wordWidth;
        }
      });

      // Draw the last line of the paragraph
      drawFormattedLine(currentLine, xPos, yPos, font, boldFont, page);
      yPos -= lineHeight; // Add extra space between paragraphs
    });
  };

  // Helper function to draw a single line with formatting
  const drawFormattedLine = (line, xPos, yPos, font, boldFont, page) => {
    line.forEach(({ word, isBold }) => {
      const wordFont = isBold ? boldFont : font;
      page.drawText(word, {
        x: xPos,
        y: yPos,
        size: 10,
        font: wordFont,
        color: rgb(0, 0, 0),
      });
      xPos += wordFont.widthOfTextAtSize(word, 10); // Adjust xPos for word width
    });
  };

  // Helper function to draw a single line
  const drawLine = (line, xPos, yPos, font, page) => {
    for (let i = 0; i < line.length; i++) {
      page.drawText(line[i], {
        x: xPos,
        y: yPos,
        size: 10,
        font: font,
        color: rgb(0, 0, 0),
      });
      xPos += font.widthOfTextAtSize(line[i], 10) + 2; // Add word spacing
    }
  };

  const drawCenterTextOnPage = (pageName, text, xPos, yPos, size) => {
    const textWidth = verdana.widthOfTextAtSize(text, size);
    const center = (width - textWidth) / 2;
    pageName.drawText(text, {
      x: center,
      y: yPos,
      size: size,
      font: verdana,
      color: rgb(0, 0, 0),
    });
  };

  // Rendering function that processes an array of content objects
  const renderContent = (contentArray) => {
    contentArray.forEach((item) => {
      switch (item.type) {
        case "centerText":
          drawCenterTextOnPage(
            item.page,
            item.text,
            item.xPos || 0,
            item.yPos,
            item.size,
            item.font
          );
          break;
        case "wrappedText":
          drawWrappedText(
            item.text,
            item.page,
            item.yPos,
            item.xPos,
            item.font,
            item.maxWidth
          );
          break;
        case "line":
          drawLine(item.line, item.xPos, item.yPos, item.font, item.page);
          break;
        // Add more cases if you have other content types
        default:
          console.warn(`Unknown content type: ${item.type}`);
      }
    });
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getUTCFullYear();

    return { day, month, year };
  }

  function getDayWithSuffix(day) {
    const suffixes = ["th", "st", "nd", "rd"];
    const remainder = day % 10;
    const suffix =
      (day % 100 >= 11 && day % 100 <= 13) || remainder > 3
        ? suffixes[0]
        : suffixes[remainder];
    return `${day}${suffix}`;
  }

  // Format the dates and add suffixes for days
  const dateOfAuthorisation = formatTimestamp(
    details.date_of_authorisation || "2024-01-01T00:00:00Z"
  );
  const dateOfAuthorisationDay = getDayWithSuffix(dateOfAuthorisation.day);

  const mortgagorDateOfBirth = formatTimestamp(
    details.date_of_birth || "2024-01-01T00:00:00Z"
  );
  const mortgagorDateOfBirthDay = getDayWithSuffix(mortgagorDateOfBirth.day);

  const mortgagorSpouseDateOfBirth = formatTimestamp(
    details.spouse_date_of_birth || "2024-01-01T00:00:00Z"
  );
  const mortgagorSpouseDateOfBirthDay = getDayWithSuffix(
    mortgagorSpouseDateOfBirth.day
  );

  const principalDebtorDateOfBirth = formatTimestamp(
    details.principal_debtor_date_of_birth || "2024-01-01T00:00:00Z"
  );
  const principalDebtorDateOfBirthDay = getDayWithSuffix(
    principalDebtorDateOfBirth.day
  );

  const principalDebtorSpouseDateOfBirth = formatTimestamp(
    details.spouse_date_of_birth || "2024-01-01T00:00:00Z"
  );
  const principalDebtorSpouseDateOfBirthDay = getDayWithSuffix(
    principalDebtorSpouseDateOfBirth.day
  );

  const dateOfLease = formatTimestamp(
    details.date_of_lease || "2024-01-01T00:00:00Z"
  );
  const dateOfLeaseDay = getDayWithSuffix(dateOfLease.day);

  const dateOfRegistration = formatTimestamp(
    details.date_of_registration || "2024-01-01T00:00:00Z"
  );
  const dateOfRegistrationDay = getDayWithSuffix(dateOfRegistration.day);

  // Determine pronouns based on gender
  const conveyancer_genderInput = (
    details.conveyancer_gender || "male"
  ).toLowerCase();

  let conveyancerPronoun = "she";
  let conveyancerPossessivePronoun = "her";
  let conveyancerObjectivePronoun = "her";

  if (conveyancer_genderInput === "male") {
    conveyancerPronoun = "he";
    conveyancerPossessivePronoun = "his";
    conveyancerObjectivePronoun = "him";
  }

  const mortgager_genderInput = (
    details.gender || "male"
  ).toLowerCase();

  let mortgagorPronoun = "she";
  let mortgagorPossessivePronoun = "her";
  let mortgagorObjectivePronoun = "her";

  if (mortgager_genderInput === "male") {
    mortgagorPronoun = "he";
    mortgagorPossessivePronoun = "his";
    mortgagorObjectivePronoun = "him";
  }

  const principal_debtor_genderInput = (
    details.principal_debtor_gender || "male"
  ).toLowerCase();

  let principal_debtor_Pronoun = "she";
  let principal_debtor_PossessivePronoun = "her";
  let principal_debtor_ObjectivePronoun = "her";

  if (principal_debtor_genderInput === "male") {
    principal_debtor_Pronoun = "he";
    principal_debtor_PossessivePronoun = "his";
    principal_debtor_ObjectivePronoun = "him";
  }

  // Content data for the given blocks
  const contentData = [
    /*PAGE 1*/
    // Block 15
    {
      type: "wrappedText", 
      page: firstPage,
      text: `THAT ${
        details.conveyancer_name || "Conveyancer Name"
      } (hereinafter referred to as “the Appearer”), a duly admitted Conveyancer, appeared before me, the REGISTRAR OF DEEDS FOR BOTSWANA at ${
        details.city || "GABORONE"
      }, he, the said Appearer, being duly authorised thereto by a Power of Attorney granted to him at ${
        details.city || "GABORONE"
      } on the ${dateOfAuthorisationDay || "1st"} day of ${
        dateOfAuthorisation?.month || "May"
      } ${dateOfAuthorisation?.year || "2024"} by:`,
      xPos: 90.02400207519531,
      yPos: 250.78363037109375,
      font: helveticaFont,
      maxWidth: 425,
    },
    // Block 16
    {
      type: "centerText",
      page: firstPage,
      text: details?.full_names || details?.partyName || "Mortgagor Name",
      yPos: 177.79364013671875,
      size: 10,
      font: helveticaFont,
    },
    // Block 17
    {
      type: "centerText",
      page: firstPage,
      text: `(Born on the ${mortgagorDateOfBirthDay || "1st"} day of ${
        mortgagorDateOfBirth.month || "May"
      } ${mortgagorDateOfBirth.year || "May"})`,
      yPos: 165.77362060546875,
      size: 10,
      font: helveticaFont,
    },
    // Block 19
    {
      type: "centerText",
      page: firstPage,
      text: details.spouse_full_names || "mortgagor_spouse_name",
      yPos: 141.29364013671875,
      size: 10,
      font: helveticaFont,
    },
    // Block 20
    {
      type: "centerText",
      page: firstPage,
      text: `(Born on the ${mortgagorSpouseDateOfBirthDay || "day"} day of ${
        mortgagorSpouseDateOfBirth.month || "month"
      } ${mortgagorSpouseDateOfBirth.year || "year"})`,
      yPos: 129.29364013671875,
      size: 10,
      font: helveticaFont,
    },
    /*PAGE 2*/
    // Block 6
    {
      type: "centerText",
      page: secondPage,
      text: details.principal_debtor_name || "Principal Debtor",
      yPos: 693.7035980224609,
      size: 10,
      font: helveticaFont,
    },
    // Block 7
    {
      type: "centerText",
      page: secondPage,
      text: `(Born on the ${principalDebtorDateOfBirthDay || "day"} day of ${
        principalDebtorDateOfBirth.month || "month"
      } ${principalDebtorDateOfBirth.year || "year"})`,
      yPos: 681.4636077880859,
      size: 10,
      font: helveticaFont,
    },
    // Block 9
    {
      type: "centerText",
      page: secondPage,
      text: details.principal_debtor_spouse_name || "principal_debtor_spouse",
      yPos: 657.1935882568359,
      size: 10,
      font: helveticaFont,
    },
    // Block 10
    {
      type: "centerText",
      page: secondPage,
      text: `(Born on the ${principalDebtorSpouseDateOfBirthDay || "day"} day of${
        principalDebtorSpouseDateOfBirth.month || "month"
      } ${principalDebtorSpouseDateOfBirth.year || "year"})`,
      yPos: 645.1935882568359,
      size: 10,
      font: helveticaFont,
    },
    // Block 15
    {
      type: "centerText",
      page: secondPage,
      text: details?.full_names || details.partyName || "Mortgagor",
      yPos: 474.9836120605469,
      size: 10,
      font: helveticaFont,
    },
    // Block 16
    {
      type: "centerText",
      page: secondPage,
      text: `(Born on the ${mortgagorDateOfBirthDay || "day"} day of ${
        mortgagorDateOfBirth.month || "month"
      } ${mortgagorDateOfBirth.year || "year"})`,
      yPos: 462.74359130859375,
      size: 10,
      font: helveticaFont,
    },
    // Block 18
    {
      type: "centerText",
      page: secondPage,
      text: details.spouse_full_names || "mortgagor_spouse_name",
      yPos: 438.50360107421875,
      size: 10,
      font: helveticaFont,
    },
    // Block 19
    {
      type: "centerText",
      page: secondPage,
      text: `(Born on the ${mortgagorSpouseDateOfBirthDay || "day"} day of ${
        mortgagorSpouseDateOfBirth.month || "month"
      } ${mortgagorSpouseDateOfBirth.month || "year"})`,
      yPos: 426.26361083984375,
      size: 10,
      font: helveticaFont,
    },
    //Block 20
    {
      type: "wrappedText",
      page: secondPage,
      text: `THE Mortgagor is truly and lawfully indebted and held and firmly bound unto and on behalf of the Society, its successors-in-title or assigns (all of whom are hereinafter included in the expression “the Society”) for:`,
      xPos: 117.16999816894531,
      yPos: 268.30364990234375,
      size: 10,
      font: helveticaFont,
      maxWidth: 420,
    },
    //Block 21.5
    {
      type: "wrappedText",
      page: secondPage,
      text: `1.1`,
      xPos: 117.16999816894531,
      yPos: 224,
      size: 10,
      maxWidth: 385,
      font: helveticaFont,
    },
    //Block 21.5
    {
      type: "wrappedText",
      page: secondPage,
      text: `the sum of P${details.principal_sum_amount || "1 000, 000.00"} (${
        details.principal_sum_amount_in_words ||
        "One Million Two Hundred and Ten Thousand"
      } Pula) (“the maximum sum”) and the additional sum of P${
        details.additional_sum_amount || "302, 500.00"
      } (${
        details.additional_sum_amount_in_words ||
        "Three Hundred and Two Thousand Five Hundred"
      } Pula) of lawful money howsoever  and from whatsoever cause arising, including but not restricted to;`,
      xPos: 135,
      yPos: 224,
      size: 10,
      maxWidth: 385,
      font: helveticaFont,
    },
    /*PAGE 3*/
    // Block 8
    {
      type: "wrappedText",
      page: thirdPage,
      text: `1.2`,
      xPos: 119,
      yPos: 583.4125671386719,
      font: helveticaFont,
      maxWidth: 381.4950408935547,
    },
    // Block 8.5
    {
      type: "wrappedText",
      page: thirdPage,
      text: `interest on all amounts secured by this bond calculated at the prime lending rate of the Bank currently at ${
        details.prime_rate || "6.76"
      }% (${
        details.prime_rate_in_words || "Six point seven six"
      } per centum) plus ${details.interest_rate || "2"}% (${
        details.interest_rate_in_words || "Two"
      } per centum) per annum, calculated on a daily basis and compounded monthly, or such other rate as the society may stipulate in writing to the Mortgagor from time to time. Any interest unpaid on due date will be capitalized and will thereafter bear interest at the prevailing rate until paid;`,
      xPos: 144.0500030517578,
      yPos: 583.4125671386719,
      font: helveticaFont,
      maxWidth: 381.4950408935547,
    },

    /*PAGE 4*/
    // Block 4
    {
      type: "wrappedText",
      page: fourthPage,
      text: `CERTAIN :`,
      xPos: 125,
      yPos: 717.9435882568359,
      font: helveticaFont,
      maxWidth: 342.7837677001953,
    },
    // Block 4.5
    {
      type: "wrappedText",
      page: fourthPage,
      text: `piece of land being Lot ${details.lot || "1234"};`,
      xPos: 234,
      yPos: 717.9435882568359,
      font: helveticaFont,
      maxWidth: 286,
    },
    // Block 5
    {
      type: "wrappedText",
      page: fourthPage,
      text: `SITUATE :`,
      xPos: 125,
      yPos: 693.7035980224609,
      font: helveticaFont,
      maxWidth: 341.34376525878906,
    },
    // Block 5.5
    {
      type: "wrappedText",
      page: fourthPage,
      text: `in the ${details.situated || "Gaborone Administrative District"};`,
      xPos: 234,
      yPos: 693.7035980224609,
      font: helveticaFont,
      maxWidth: 286,
    },
    // Block 6
    {
      type: "wrappedText",
      page: fourthPage,
      text: `MEASURING :`,
      xPos: 125,
      yPos: 669.4636077880859,
      font: helveticaFont,
      maxWidth: 307.74375915527344,
    },
    // Block 6.5
    {
      type: "wrappedText",
      page: fourthPage,
      text: `${details.square_metres || "123"} m\u00B2 (${
        details.square_metres_in_words ||
        "One Hundred And Twenty Three Square Meters"
      });`,
      xPos: 234,
      yPos: 669.4636077880859,
      font: helveticaFont,
      maxWidth: 286,
    },
    // Block 8
    {
      type: "wrappedText",
      page: fourthPage,
      text: `HELD UNDER :`,
      xPos: 125,
      yPos: 645.1935882568359,
      font: helveticaFont,
      maxWidth: 407.2653121948242,
    },
    // Block 8
    {
      type: "wrappedText",
      page: fourthPage,
      text: `Memorandum of Agreement of Lease No. ${
        details.lease_no || "12345"
      } dated ${dateOfLeaseDay || "day"} day of ${
        dateOfLease.month || "month"
      } ${dateOfLease.year || "year"} made in favour of ${
        details?.full_names || details.partyName  || "Mortgagor Name"
      };`,
      xPos: 234,
      yPos: 645.1935882568359,
      font: helveticaFont,
      maxWidth: 286,
    },
    /*PAGE 14*/
    // Block 9
    {
      type: "wrappedText",
      page: fourteenthPage,
      text: `THUS DONE AND EXECUTED at the Office of the REGISTRAR OF DEEDS FOR BOTSWANA at ${
        details.city || "GABORONE"
      } on this ${dateOfRegistrationDay || "day"} day of the month of ${
        dateOfRegistration.month || "month"
      } in the Year of Our Lord, ${dateOfRegistration.year || "2024"}.`,
      xPos: 90.02400207519531,
      yPos: 596.4736175537109,
      font: helveticaFont,
      maxWidth: 435.4342498779297,
    },
    /*PAGE 16*/
    // Block 14
    {
      type: "centerText",
      page: sixteenthPage,
      text: details?.full_names || details.partyName || "Mortgagor Name",
      yPos: 572.2136077880859,
      size: 10,
      font: helveticaFont,
    },
    // Block 15
    {
      type: "centerText",
      page: sixteenthPage,
      text: `(Born on the ${mortgagorDateOfBirthDay || "day"} day of ${
        mortgagorDateOfBirth.month || "month"
      } ${mortgagorDateOfBirth.year || "year"})`,
      yPos: 559.9736175537109,
      size: 10,
      font: helveticaFont,
    },
    // Block 17
    {
      type: "centerText",
      page: sixteenthPage,
      text: details.spouse_full_names || "mortgagor_spouse_name",
      yPos: 535.7335815429688,
      size: 10,
      font: helveticaFont,
    },
    // Block 18
    {
      type: "centerText",
      page: sixteenthPage,
      text: `(Born on the ${mortgagorSpouseDateOfBirthDay || "day"} day of ${
        mortgagorSpouseDateOfBirth.month || "month"
      } ${mortgagorSpouseDateOfBirth.year || "year"})`,
      yPos: 523.4935913085938,
      size: 10,
      font: helveticaFont,
    },
    // Block 21
    {
      type: "centerText",
      page: sixteenthPage,
      text: details.conveyancer_name || "Conveyancer",
      yPos: 438.50360107421875,
      size: 10,
      font: helveticaFont,
    },
    // Block 24
    {
      type: "wrappedText",
      page: sixteenthPage,
      text: `CERTAIN :`,
      xPos: 117.16999816894531,
      yPos: 304.7835998535156,
      font: helveticaFont,
      maxWidth: 359.10377502441406,
    },
    // Block 24.5
    {
      type: "wrappedText",
      page: sixteenthPage,
      text: `piece of land being Tribal Lot ${details.lot || "1187"}, ${
        details.situated || "Ramotswa"
      };`,
      xPos: 234,
      yPos: 304.7835998535156,
      font: helveticaFont,
      maxWidth: 286,
    },
    // Block 25
    {
      type: "wrappedText",
      page: sixteenthPage,
      text: `SITUATE :`,
      xPos: 117.16999816894531,
      yPos: 280.3035888671875,
      font: helveticaFont,
      maxWidth: 352.38377380371094,
    },
    // Block 25
    {
      type: "wrappedText",
      page: sixteenthPage,
      text: `in the ${details.situated || "Gaborone Administrative District"};`,
      xPos: 234,
      yPos: 280.3035888671875,
      font: helveticaFont,
      maxWidth: 286,
    },
    // Block 26
    {
      type: "wrappedText",
      page: sixteenthPage,
      text: `MEASURING :`,
      xPos: 117.16999816894531,
      yPos: 256.0635986328125,
      font: helveticaFont,
      maxWidth: 380.72377014160156,
    },
    // Block 26
    {
      type: "wrappedText",
      page: sixteenthPage,
      text: `${details.square_metres || "123"} m\u00B2 (${
        details.square_metres_in_words ||
        "One Hundred And Twenty Three Square Meters"
      });`,
      xPos: 234,
      yPos: 256.0635986328125,
      font: helveticaFont,
      maxWidth: 286,
    },
    // Block 27
    {
      type: "wrappedText",
      page: sixteenthPage,
      text: `HELD UNDER :`,
      xPos: 117.16999816894531,
      yPos: 231.79364013671875,
      font: helveticaFont,
      maxWidth: 408.46531677246094,
    },
    // Block 27.5
    {
      type: "wrappedText",
      page: sixteenthPage,
      text: `Memorandum of Agreement of Lease No. ${
        details.lease_no || "1234"
      } dated ${dateOfLeaseDay || "day"} day of ${
        dateOfLease.month || "month"
      } ${dateOfLease.year || "year"} made in favour of ${
        details?.full_names || details.partyName || "Mortgagor Name"
      };`,
      xPos: 234,
      yPos: 231.79364013671875,
      font: helveticaFont,
      maxWidth: 286,
    },
    /*PAGE 17*/
    // Block 6
    {
      type: "wrappedText",
      page: seventeenthPage,
      text: `THUS DONE AND DATED AT ${
        details.executionCity || "GABORONE"
      } ON THIS ${dateOfRegistrationDay || "day"} DAY OF ${
        dateOfRegistration.month || "month"
      } ${dateOfRegistration.year || "2023"}.`,
      xPos: 90.02400207519531,
      yPos: 681.4636077880859,
      font: helveticaFont,
      maxWidth: 431.7712860107422,
    },
    // Block 9
    {
      type: "wrappedText",
      page: seventeenthPage,
      text: details.conveyancer_name || "Conveyancer Name",
      xPos: 346,
      yPos: 608.7136077880859,
      size: 10,
      font: helveticaFont,
    },
    // Block 10
    {
      type: "wrappedText",
      page: seventeenthPage,
      text: `1. \t\t${details.witness_1_name || "Witness 1 Name"}`,
      xPos: 90.02400207519531,
      yPos: 596.4736175537109,
      font: helveticaFont,
      maxWidth: 255.5413055419922,
    },
    // Block 11
    {
      type: "wrappedText",
      page: seventeenthPage,
      text: `2. \t\t${details.witness_2_name || "Witness 2 Name"}`,
      xPos: 90.02400207519531,
      yPos: 559.9736175537109,
      font: helveticaFont,
      maxWidth: 291.5613250732422,
    },
    /*PAGE 18*/
    // Block 7
    {
      type: "centerText",
      page: eighteenthPage,
      text:details?.full_names || details.partyName || "Mortgagor Name",
      yPos: 559.9736175537109,
      size: 10,
      font: helveticaFont,
    },
    // Block 8
    {
      type: "wrappedText",
      page: eighteenthPage,
      text: `do hereby make oath and swear that:\n\n\n1.\t\tI am an adult ${
        details.mortgagor_gender || "female"
      } citizen of ${details.country || "Botswana"}.`,
      xPos: 90.02400207519531,
      yPos: 523.4935913085938,
      font: helveticaFont,
      maxWidth: 249.63975524902344,
    },
    // Block 9
    {
      type: "wrappedText",
      page: eighteenthPage,
      text: `2.`,
      xPos: 90.02400207519531,
      yPos: 462.74359130859375,
      font: helveticaFont,
      maxWidth: 435.52268981933594,
    },
    // Block 9.5
    {
      type: "wrappedText",
      page: eighteenthPage,
      text: `I was born on the ${mortgagorDateOfBirthDay || "day"} day of ${
        mortgagorDateOfBirth.month || "month"
      } ${
        mortgagorDateOfBirth.year || "year"
      }, which date I have always regarded as my true date of birth.`,
      xPos: 119,
      yPos: 462.74359130859375,
      font: helveticaFont,
      maxWidth: 384,
    },
    // Block 10
    {
      type: "wrappedText",
      page: eighteenthPage,
      text: `3.`,
      xPos: 90.02400207519531,
      yPos: 426.26361083984375,
      font: helveticaFont,
      maxWidth: 337.9113006591797,
    },
    // Block 10.5
    {
      type: "wrappedText",
      page: eighteenthPage,
      text:details?.marital_status === "Married "  ? `I am married in community of property to ${
        details.spouse_full_names || "mortgagor_spouse_name"
      }.` :  "I am not married",
      xPos: 119,
      yPos: 426.26361083984375,
      font: helveticaFont,
      maxWidth: 384,
    },
    // Block 15
    {
      type: "wrappedText",
      page: eighteenthPage,
      text: `THUS SIGNED AND SWORN TO BEFORE ME AT ${
        details.executionCity || "GABORONE"
      } ON THIS THE ${dateOfRegistrationDay || "day"} DAY OF ${
        dateOfRegistration.month || "month"
      } ${
        dateOfRegistration.year || "2023"
      }, THE DEPONENT HAVING ACKNOWLEDGED THAT SHE KNOWS AND UNDERSTANDS THE CONTENTS OF THIS AFFIDAVIT.`,
      xPos: 90.02400207519531,
      yPos: 243.79364013671875,
      font: helveticaFont,
      maxWidth: 435.28660583496094,
    },
    /*PAGE 19*/
    // Block 9
    {
      type: "centerText",
      page: nineteenthPage,
      text: details.principal_debtor_name || "principal_debtor_name",
      yPos: 559.9736175537109,
      size: 10,
      font: helveticaFont,
    },
    // Block 10
    {
      type: "wrappedText",
      page: nineteenthPage,
      text: `do hereby make oath and swear that:\n\n\n1.\t\tI am an adult ${
        details.principal_debtor_gender || "male"
      } citizen of ${details.nationality || "Botswana"}.`,
      xPos: 90.02400207519531,
      yPos: 523.4935913085938,
      font: helveticaFont,
      maxWidth: 240.27976989746094,
    },
    // Block 11
    {
      type: "wrappedText",
      page: nineteenthPage,
      text: `2.`,
      xPos: 90.02400207519531,
      yPos: 462.74359130859375,
      font: helveticaFont,
      maxWidth: 435.5348358154297,
    },
    // Block 11.5
    {
      type: "wrappedText",
      page: nineteenthPage,
      text: `I was born on the ${
        principalDebtorDateOfBirthDay || "day"
      } day of ${principalDebtorDateOfBirth.month || "month"} ${
        principalDebtorDateOfBirth.year || "year"
      }, which date I have always regarded as my true date of birth.`,
      xPos: 119,
      yPos: 462.74359130859375,
      font: helveticaFont,
      maxWidth: 384,
    },
    // Block 12
    {
      type: "wrappedText",
      page: nineteenthPage,
      text: `3.`,
      xPos: 90.02400207519531,
      yPos: 426.26361083984375,
      font: helveticaFont,
      maxWidth: 349.76976013183594,
    },
    // Block 12.5
    {
      type: "wrappedText",
      page: nineteenthPage,
      text: `I am married in community of property to ${
        details.principal_debtor_spouse_name || "principal_debtor_spouse_name"
      }.`,
      xPos: 119,
      yPos: 426.26361083984375,
      font: helveticaFont,
      maxWidth: 384,
    },
    // Block 17
    {
      type: "wrappedText",
      page: nineteenthPage,
      text: `THUS SIGNED AND SWORN TO BEFORE ME AT ${
        details.executionCity || "GABORONE"
      } ON THIS THE ${dateOfRegistrationDay || "day"} DAY OF ${
        dateOfRegistration.month || "month"
      } ${
        dateOfRegistration.year || "2023"
      }, THE DEPONENT HAVING ACKNOWLEDGED THAT HE KNOWS AND UNDERSTANDS THE CONTENTS OF THIS AFFIDAVIT.`,
      xPos: 90.02400207519531,
      yPos: 256.0635986328125,
      font: helveticaFont,
      maxWidth: 435.36949157714844,
    },
    /*PAGE 20*/
    // Block 8
    {
      type: "centerText",
      page: twentiethPage,
      text: details?.full_names || details?.partyName || "Mortgagor Name",
      yPos: 596.4736175537109,
      size: 10,
      font: helveticaFont,
    },
    // Block 9
    {
      type: "centerText",
      page: twentiethPage,
      text: `(Born on the ${mortgagorDateOfBirthDay || "day"} day of ${
        mortgagorDateOfBirth.month || "month"
      } ${mortgagorDateOfBirth.year || "year"})`,
      yPos: 584.2136077880859,
      size: 10,
      font: helveticaFont,
    },
    // Block 10
    {
      type: "wrappedText",
      page: twentiethPage,
      text: `married in community of property to ${
        details.spouse_full_names || "Spouse Name"
      } do hereby grant consent to the said ${
        details.conveyancer_name || "Conveyancer Name"
      }, to register a Second Surety Covering Mortgage Bond passed in favour of ${
        details.bankName || "BBS BANK LIMITED"
      } in respect of the undermentioned property:`,
      xPos: 90.02400207519531,
      yPos: 559.9736175537109,
      font: helveticaFont,
      maxWidth: 435.6355438232422,
    },
    // Block 11
    {
      type: "wrappedText",
      page: twentiethPage,
      text: `CERTAIN :`,
      xPos: 117.16999816894531,
      yPos: 486.9836120605469,
      font: helveticaFont,
      maxWidth: 336.0637664794922,
    },
    // Block 11
    {
      type: "wrappedText",
      page: twentiethPage,
      text: `piece of land being Lot ${details.lot || "1234"};`,
      xPos: 234,
      yPos: 486.9836120605469,
      font: helveticaFont,
      maxWidth: 286,
    },
    // Block 12
    {
      type: "wrappedText",
      page: twentiethPage,
      text: `SITUATE :`,
      xPos: 117.16999816894531,
      yPos: 462.74359130859375,
      font: helveticaFont,
      maxWidth: 334.62376403808594,
    },
    // Block 12
    {
      type: "wrappedText",
      page: twentiethPage,
      text: `in the ${details.situated || "Gaborone Administrative District"};`,
      xPos: 234,
      yPos: 462.74359130859375,
      font: helveticaFont,
      maxWidth: 286,
    },
    // Block 13
    {
      type: "wrappedText",
      page: twentiethPage,
      text: `MEASURING :`,
      xPos: 117.16999816894531,
      yPos: 438.50360107421875,
      font: helveticaFont,
      maxWidth: 334.62376403808594,
    },
    // Block 13.5
    {
      type: "wrappedText",
      page: twentiethPage,
      text: `${details.square_metres || "123"} m\u00B2 (${
        details.square_metres_in_words ||
        "One Hundred And Twenty Three Square Meters"
      });`,
      xPos: 234,
      yPos: 438.50360107421875,
      font: helveticaFont,
      maxWidth: 286,
    },
    // Block 15
    {
      type: "wrappedText",
      page: twentiethPage,
      text: `HELD UNDER :`,
      xPos: 117.37000274658203,
      yPos: 414.00360107421875,
      font: helveticaFont,
      maxWidth: 407.2653121948242,
    },
    // Block 15.5
    {
      type: "wrappedText",
      page: twentiethPage,
      text: `Memorandum of Agreement of Lease No. ${
        details.lease_no || "12345"
      } dated ${dateOfLeaseDay || "day"} day of ${
        dateOfLease.month || "month"
      } ${dateOfLease.year || "year"} made in favour of ${
        details?.full_names || details.partyName || "Mortgagor Name"
      };`,
      xPos: 234,
      yPos: 414.00360107421875,
      font: helveticaFont,
      maxWidth: 286,
    },
    // Block 21
    {
      type: "wrappedText",
      page: twentiethPage,
      text: details?.full_names || details.partyName || "Mortgagor Name",
      xPos: 361.37000274658203,
      yPos: 280.3035888671875,
      size: 10,
      font: helveticaFont,
    },
    // Block 22
    {
      type: "wrappedText",
      page: twentiethPage,
      text: `THUS SIGNED AND SWORN TO BEFORE ME AT ${
        details.executionCity || "GABORONE"
      } ON THIS THE ${dateOfRegistrationDay || "day"} DAY OF ${
        dateOfRegistration.month || "month"
      } ${
        dateOfRegistration.year || "2023"
      }, THE DEPONENT HAVING ACKNOWLEDGED THAT HE KNOWS AND UNDERSTANDS THE CONTENTS OF THIS AFFIDAVIT.`,
      xPos: 90.02400207519531,
      yPos: 231.79364013671875,
      font: helveticaFont,
      maxWidth: 435.4136199951172,
    },
  ];

  renderContent(contentData);

  const modifiedPdfBytes = await pdfDoc.save();
  return modifiedPdfBytes;
};

export default SuretyBondApplicationBbs;
