import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import pdfDocument from "../Documents/Company-IndividualTransfer.pdf";
import fontt from './verdana.ttf';
import fontkit from '@pdf-lib/fontkit'

const TransferCompanyIndividual = async (caseDetails) => {
  const fetchPdf = async () => {
    const existingPdfBytes = await fetch(pdfDocument).then((res) =>
      res.arrayBuffer()
    );
    return existingPdfBytes;
  };

  const pdfBytes = await fetchPdf();
  let details = await caseDetails?.transactionData?.formSchema.map(
    ({ name, value }) => {
      return { name, value };
    }
  );

  const flattenedObject = details?.reduce((acc, curr) => {
    acc[curr.name] = curr.value;
    return acc;
  }, {});

  details = {
    ...flattenedObject,
  };

  const pdfDoc = await PDFDocument.load(pdfBytes);
  pdfDoc.registerFontkit(fontkit);
  const response = await fetch(fontt);
  const fontBytes = await response.arrayBuffer();
  const font = await pdfDoc.embedFont(fontBytes);
  const helveticaFont = await pdfDoc.embedFont(fontBytes); // Embed the custom font
  const verdana = await pdfDoc.embedFont(fontBytes); // Embed the custom font




  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" }); // "October"
    const year = date.getUTCFullYear();

    return { day, month, year };
  }


  const DateOfResolution = formatTimestamp(details.date_of_resolution);
  const DateOfTransfer = formatTimestamp(details.date_of_transfer);
  const TransfereeDateOfBirth = formatTimestamp(
    details.transferee_date_of_birth
  );
  const SpouseDateOfBirth = formatTimestamp(details.spouse_date_of_birth);
  const DateOfDeedOfTransfer = formatTimestamp(
    details.date_of_deed_of_transfer
  );
  const form = pdfDoc.getForm();

  const firstPage = pdfDoc.getPage(0);
  const secondPage = pdfDoc.getPage(1);
  const thirdPage = pdfDoc.getPage(2);
  const fourthPage = pdfDoc.getPage(3);
  const fifthPage = pdfDoc.getPage(4);
  const sixthPage = pdfDoc.getPage(5);
  const seventhPage = pdfDoc.getPage(6);
  const eighthPage = pdfDoc.getPage(7);
  const ninthPage = pdfDoc.getPage(8);
  const tenthPage = pdfDoc.getPage(9);
  const eleventhPage = pdfDoc.getPage(10);
  const twelfthPage = pdfDoc.getPage(11);

  const { width, height } = firstPage.getSize();

  let xPos = 70.9; // Starting x-position

  const drawWrappedText = (projects, page, yPos, xPos, font, maxWidth) => {
    const words = projects.split(" ");
    const wordSpacing = 2; // Adjust this value to set the spacing between words
    const lineHeight = 12; // Adjust this value to control the space between lines

    let currentLine = [];
    let currentLineWidth = 0;

    // Iterate through each word in the text
    for (let i = 0; i < words.length; i++) {
      const wordWidth = font.widthOfTextAtSize(words[i], 10);

      // Check if adding the word exceeds the max width of the line
      if (currentLineWidth + wordWidth + (currentLine.length - 1) * wordSpacing > maxWidth) {
        // If so, move to the next line
        drawLine(currentLine, xPos, yPos, font, page);
        currentLine = [words[i]]; // Start a new line with the current word
        currentLineWidth = wordWidth;
        yPos -= lineHeight; // Move down for the next line
      } else {
        // Otherwise, add the word to the current line
        currentLine.push(words[i]);
        currentLineWidth += wordWidth;
      }
    }

    // Draw the last line
    drawLine(currentLine, xPos, yPos, font, page);
  };

  // Function to draw a single line of words
  const drawLine = (line, xPos, yPos, font, page) => {
    // Draw each word on the current line with the specified word spacing
    for (let i = 0; i < line.length; i++) {
      page.drawText(line[i], {
        x: xPos,
        y: yPos,
        size: 10,
        font: font,
        color: rgb(0, 0, 0),
      });
      xPos += font.widthOfTextAtSize(line[i], 10) + 2; // Move to the next word with spacing
    }
  };

  const drawCenterTextOnPage = (pageName, text, xPos, yPos, size) => {

    const textWidth = verdana.widthOfTextAtSize(text, size);
    const center = (width - textWidth) / 2;
    pageName.drawText(text, {
      x: center,
      y: yPos,
      size: size,
      font: verdana,
      color: rgb(0, 0, 0),
    });
  }

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" }); // "October"
    const year = date.getUTCFullYear();

    return { day, month, year };
  }

  //page 1
  if (1 == 1) {


    // power to give attorney


    drawCenterTextOnPage(firstPage, ((details?.manager_full_names || details?.director_full_names || details?.executive_director_full_names || "Company Representative")), 200, 665, 11)
    let paragraph = `in my capacity as MANAGER and`
    drawCenterTextOnPage(firstPage, paragraph, 200, 654, 10);
    drawCenterTextOnPage(firstPage, ((details?.name_of_company || "Company Name")), 200, 628, 11)
    let date = formatTimestamp(details?.declaration_date_at_deeds || "2024-10-16T09:43:15.453+00:00");
    paragraph = `passed at Gaborone on the ${date.day} day of ${date.month} ${date.year}`
    drawCenterTextOnPage(firstPage, paragraph, 200, 616, 10)



    drawCenterTextOnPage(firstPage, ((details?.appointed_attorney || "Collins Chilisa Consultancy")), 200, 570, 11)
    date = formatTimestamp(details?.declaration_date_at_deeds || "2024-10-16T09:43:15.453+00:00");
    paragraph = `with power of substitution to be my true and lawful Attorney and Agent and in my name, place and stead to appear before the REGISTRAR OF DEEDS FOR BOTSWANA at GABORONE and then and there as my act and deed to declare that the Company did on the ${date.day}th day of ${date.month} ${date.year} sell to:`
    drawWrappedText(paragraph, firstPage, 544, xPos, helveticaFont, 450);
    drawCenterTextOnPage(firstPage, ((details?.purchaser_full_names || "Samuel James")), 200, 482, 11)
    date = formatTimestamp(details?.purchaser_date_of_birth || "2024-10-16T09:43:15.453+00:00");
    drawCenterTextOnPage(firstPage, ((`(Born on the ${date?.day} day of ${date?.month} ${date?.year})`)), 200, 470, 10)
    drawCenterTextOnPage(firstPage, ((details?.purchaser_spouse_full_names || "Mortgagor")), 200, 446, 11)
    date = formatTimestamp(details?.purchaser_spouse_date_of_birth || "2024-10-16T09:43:15.453+00:00");
    drawCenterTextOnPage(firstPage, ((`(Born on the ${date?.day} day of ${date?.month} ${date?.year})`)), 200, 434, 10)
    paragraph = `for the sum of ${details?.purchaser_marriage_settlement_amount || 'P780, 000.00 (Seven Hundred and Eighty Thousand Pula)'} namely:-`
    drawWrappedText(paragraph, firstPage, 398, xPos, helveticaFont, 460);
    paragraph = `piece of land being Lot ${details?.property_lot_number || "754939"}, Gaborone (Portion of Lot ${details?.lot_number || "754939"} Gaborone;`
    drawWrappedText(paragraph, firstPage, 373, 213.5, helveticaFont, 313);
    paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
    drawWrappedText(paragraph, firstPage, 839.0 - 490, 213.5, helveticaFont, 313);
    paragraph = `${details?.property_measurements || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
    drawWrappedText(paragraph, firstPage, 839.0 - 515, 213.5, helveticaFont, 313);
    date = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");
    paragraph = `Deed of Transfer No. ${details?.transfer_number || "24646/2024"} dated ${date?.day} day of ${date?.month} ${date?.year} made in favour of ${details?.company_name || "Company Names"};`
    drawWrappedText(paragraph, firstPage, 300, 213.5, helveticaFont, 313);





  }
 
  //page 3
  if (1 == 1) {
    drawCenterTextOnPage(thirdPage, `${details?.manager_full_names || "Manager's Full Names"}`, 200, 715, 11);
    let paragraph = `in my capacity as ${details?.company_representative_position || "MANAGER"} and`
    drawCenterTextOnPage(thirdPage, paragraph, 200, 701, 10)
    drawCenterTextOnPage(thirdPage, ((details?.name_of_company || "Company Name")), 200, 679, 11)
    let date = formatTimestamp(details?.company_date_of_resolution || "2024-10-16T09:43:15.453+00:00");
    paragraph = `passed at Gaborone on the ${date.day} day of ${date.month} ${date.year}`
    drawCenterTextOnPage(thirdPage, paragraph, 200, 665, 10)

    paragraph = `do solemnly and sincerely declare that the sum of ${details?.purchase_amount || 'P780, 000.00 (Seven Hundred and Eighty Thousand Pula)'} is the full and entire purchase money for which the Company has sold to:-`
    drawWrappedText(paragraph, thirdPage, 628, xPos, helveticaFont, 460);

    drawCenterTextOnPage(thirdPage, ((details?.purchaser_full_names || "James Mike")), 200, 580, 11)
    date = formatTimestamp(details?.purchaser_date_of_birth || "2024-10-16T09:43:15.453+00:00");
    paragraph = `Born on the ${date.day} day of ${date.month} ${date.year}`
    drawCenterTextOnPage(thirdPage, paragraph, 200, 567.5, 10)

    drawCenterTextOnPage(thirdPage, ((details?.purchaser_spouse_full_names || "Purchaser Spouse Names")), 200, 543, 11)
    date = formatTimestamp(details?.purchaser_spouse_date_of_birth || "2024-10-16T09:43:15.453+00:00");
    paragraph = `Born on the ${date.day} day of ${date.month} ${date.year}`
    drawCenterTextOnPage(thirdPage, (paragraph), 200, 532, 10)

    paragraph = `piece of land being Lot ${details?.property_lot_number || "754939"},  (Portion of Lot ${details?.property_lot_number || "754939"};`
    drawWrappedText(paragraph, thirdPage, 470.5, 213.5, helveticaFont, 313);
    paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
    drawWrappedText(paragraph, thirdPage, 446, 213.5, helveticaFont, 313);
    paragraph = `${details?.property_measurements || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
    drawWrappedText(paragraph, thirdPage, 422, 213.5, helveticaFont, 313);
    date = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");
    paragraph = `Deed of Transfer No. ${details?.transfer_number || "24646/2024"} dated ${date?.day} day of ${date?.month} ${date?.year} made in favour of ${details?.name_of_company || "SELLER"};`
    drawWrappedText(paragraph, thirdPage, 398, 213.5, helveticaFont, 313);

     date = formatTimestamp(details?.date_of_purchase || "2024-10-16T09:43:15.453+00:00");

    paragraph = `And I declare that the Company sold the same to the said PURCHASER on ${date.day} day of ${date.month} ${date.year} and not before; and that there is not any agreement, condition, or understanding between the Company and the said purchaser, whereby it has paid or is to pay to the Company or to any other person whomsoever, for, or in respect of, or in connection with the purchase by it of the said property, any sum of money over and above the said sum of ${details?.purchase_amount || 'P780, 000.00 (Seven Hundred and Eighty Thousand Pula)'} except certain charges or payments which fall under or come within one or more of the heads or items of charges or payments following`
    drawWrappedText(paragraph, thirdPage, 350, xPos, helveticaFont, 460);

    paragraph = `AND I further declare that the Company has not received, and that it is not to receive, nor has any other person received, nor is any other person to receive, for its use or benefit, or at its instance or request, any valuable consideration besides the said sum of ${details?.purchase_amount || 'P780, 000.00 (Seven Hundred and Eighty Thousand Pula)'} except in so far as any of the charges above specified, and to be paid by the said PURCHASER, might be held or taken to be payable for it or on its behalf.`
    drawWrappedText(paragraph, fourthPage, 519, xPos, helveticaFont, 460);

    paragraph = `${details?.company_name || "COMPANY"} `
    drawCenterTextOnPage(fifthPage, paragraph, 200, 749.5, 11)

    paragraph = `PASSED AT GABORONE ON THE 12th DAY OF November  2024`
    drawCenterTextOnPage(fifthPage, paragraph, 200, 738, 10)

    paragraph = `${details?.purchaser_full_names || "PURCHASER"}`
    drawCenterTextOnPage(fifthPage, paragraph, 200, 652, 11)
    date = formatTimestamp(details?.purchaser_date_of_birth || "2024-10-16T09:43:15.453+00:00");
    paragraph = `Born on the ${date.day} day of ${date.month} ${date.year}`
    drawCenterTextOnPage(fifthPage, (paragraph), 200, 641, 10)
    paragraph = `${details?.purchaser_spouse_full_names || "PURCHASER SPOUSE"}`
    drawCenterTextOnPage(fifthPage, paragraph, 200, 614, 11);
    date = formatTimestamp(details?.purchaser_spouse_date_of_birth || "2024-10-16T09:43:15.453+00:00");
    paragraph = `Born on the ${date.day} day of ${date.month} ${date.year}`
    drawCenterTextOnPage(fifthPage, (paragraph), 200, 604.5, 10)
    paragraph = `${details?.purchase_amount || 'P780, 000.00 (Seven Hundred and Eighty Thousand Pula)'}`
    drawWrappedText(paragraph, fifthPage, 542, xPos, helveticaFont, 460);


    paragraph = `piece of land being Lot ${details?.property_lot_number || "754939"}, (Portion of Lot ${details?.property_lot_number || "754939"};`
    drawWrappedText(paragraph, fifthPage, 495, 213.5, helveticaFont, 313);
    paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
    drawWrappedText(paragraph, fifthPage, 470.5, 213.5, helveticaFont, 313);
    paragraph = `${details?.property_measurements || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
    drawWrappedText(paragraph, fifthPage, 446, 213.5, helveticaFont, 313);
    date = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");
    paragraph = `under Deed of Transfer No. ${details?.transfer_number || "24646/2024"} dated ${date?.day} day of ${date?.month} ${date?.year} made in favour of ${details?.purchaser_full_names || "Mike Modise Smith"};`
    drawWrappedText(paragraph, fifthPage, 422, 213.5, helveticaFont, 313);
    paragraph = `That ${details?.manager_full_names || "Samuel James"} in his capacity as ${details?.company_representative_position || 'MANAGER'} be and is hereby authorised to sign all documents which may be required for registration of the transfer to be effected as aforesaid.`
    drawWrappedText(paragraph, fifthPage, 350, xPos, helveticaFont, 460);



    drawCenterTextOnPage(sixthPage, details?.purchaser_full_names || "PURCHASER", 200, 715.5, 11)
    date = formatTimestamp(details?.purchaser_date_of_birth || "2024-10-16T09:43:15.453+00:00");
    paragraph = `Born on the ${date.day} day of ${date.month} ${date.year}`
    drawCenterTextOnPage(sixthPage, paragraph, 200, 702.5, 10)
    drawCenterTextOnPage(sixthPage, details?.purchaser_spouse_full_names || "SPOUSE ", 200, 680, 11)
    date = formatTimestamp(details?.purchaser_spouse_date_of_birth || "2024-10-16T09:43:15.453+00:00");
    paragraph = `Born on the ${date.day} day of ${date.month} ${date.year}`
    drawCenterTextOnPage(sixthPage, paragraph, 200, 667, 10)



    paragraph = `do solemnly and sincerely declare that the sum of ${details?.purchase_amount || 'P780, 000.00 (Seven Hundred and Eighty Thousand Pula)'} is the full and entire purchase money given, or to be given by me`
    drawWrappedText(paragraph, sixthPage, 631, xPos, helveticaFont, 460);


    drawCenterTextOnPage(sixthPage, details?.purchaser_full_names || "PURCHASER", 200, 583, 11)


    paragraph = `piece of land being Lot ${details?.property_lot_number || "754939"}, Gaborone (Portion of Lot ${details?.property_lot_number || "754939"} Gaborone;`
    drawWrappedText(paragraph, sixthPage, 522, 213.5, helveticaFont, 313);
    paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
    drawWrappedText(paragraph, sixthPage, 498, 213.5, helveticaFont, 313);
    paragraph = `${details?.property_measurements || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
    drawWrappedText(paragraph, sixthPage, 474, 213.5, helveticaFont, 313);
    date = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");
    paragraph = `under Deed of Transfer No. ${details?.transfer_number || "24646/2024"} dated ${date?.day} day of ${date?.month} ${date?.year} made in favour of ${details?.purchaser_full_names || "Mike Modise Smith"};`
    drawWrappedText(paragraph, sixthPage, 448.6, 213.5, helveticaFont, 313);

    date = formatTimestamp(details?.date_of_purchase || "2024-10-16T09:43:15.453+00:00");
    paragraph = `and I declare that I bought the same from the said SELLER on the ${date.day} day of ${date.month} ${date.year} and not before, and that I have not, nor has any person to my knowledge, on my account, given, nor is there by me or on my behalf, to be given, any other valuable consideration for, or in respect, or in connection with the alienation to me of the said property, save and except certain charges or payment which fall under, or come within, some one or more of the heads or items of charges or payment following:`
    drawWrappedText(paragraph, sixthPage, 400, xPos, helveticaFont, 460);



    drawCenterTextOnPage(eighthPage, details?.purchaser_full_names || "PURCHASER", 200, 579, 11)


    date = formatTimestamp(details?.purchaser_date_of_birth || "2024-10-16T09:43:15.453+00:00");
    paragraph = `I am an adult ${details?.purchaser_gender || 'male'} citizen of Botswana`
    drawWrappedText(paragraph, eighthPage, 507, 106.2, helveticaFont, 313);
    date = formatTimestamp(details?.purchaser_date_of_birth || "2024-10-16T09:43:15.453+00:00");
    paragraph = `I was born on the ${date.day} day of ${date.month} ${date.year}, which date I have always regarded as my true date of birth.`
    drawWrappedText(paragraph, eighthPage, 483, 106.2, helveticaFont, 385);
    let married = `I am married in community of property to ${details?.purchaser_spouse_full_names || "Smith Modise"}`
    paragraph = `I am not married `

    drawWrappedText(details?.purchaser_marital_status === "Married" ? married :  paragraph, eighthPage, 446, 106.2, helveticaFont, 313);




    drawCenterTextOnPage(ninthPage, details?.appointed_attorney || "APPOINTED ATTORNEY ", 200, 275, 11)


    paragraph = `Appeared before me, THE REGISTRAR OF DEEDS FOR BOTSWANA at GABORONE he, the said Appearer, being duly authorised thereto by a Power of Attorney granted to him at Gaborone on the ${'   '} day of ${ '                    '} by;`
    drawWrappedText(paragraph, ninthPage, 252, xPos, helveticaFont, 460);



    drawCenterTextOnPage(ninthPage, details?.manager_full_names || "COMPANY REPRESENTATIVE ", 200, 202, 11)

    drawCenterTextOnPage(ninthPage, details?.manager_full_names || `in ${details?.manager_gender === "male" ? "his" :"her"} capacity as ${details?.company_representative_position} and` , 200, 190, 11)


    drawCenterTextOnPage(ninthPage, details?.name_of_company || "Estate Properties", 200, 167, 11)
    drawCenterTextOnPage(ninthPage,`passed at Gaborone on the ${'12th'} day of ${'January 2024'} `, 200, 153, 11)

    paragraph = `And the Appearer declared that his principal did on the 12th day of March 2024 truly and legally sell the hereinafter property to the hereinafter transferee, and that ${details?.manager_gender === "male" ? "he" :"she"}, in ${details?.manager_gender === "male" ? "his" :"her"} capacity aforesaid, did by these presents cede and transfer in full and free property to and on behalf of `
    drawWrappedText(paragraph, ninthPage, 130, xPos, helveticaFont, 460);

    


    drawCenterTextOnPage(tenthPage, details?.purchaser_full_names || "PURCHASER", 200, 738, 11)

    drawCenterTextOnPage(tenthPage, details?.purchaser_spouse_full_names || "PURCHASER SPOUSE", 200, 714,11)
    date = formatTimestamp(details?.purchaser_spouse_date_of_birth || "2024-10-16T09:43:15.453+00:00");
    paragraph = `Born on the ${date.day} day of ${date.month} ${date.year}`
    drawCenterTextOnPage(tenthPage,paragraph, 200, 701, 11)

   

    paragraph = `piece of land being Lot ${details?.property_lot_number || "754939"}, Gaborone (Portion of Lot ${details?.property_lot_number || "754939"} Gaborone;`
    drawWrappedText(paragraph, tenthPage, 639, 213.5, helveticaFont, 313);
    paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
    drawWrappedText(paragraph, tenthPage, 617.5, 213.5, helveticaFont, 313);
    paragraph = `${details?.property_measurements || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
    drawWrappedText(paragraph, tenthPage,591, 213.5, helveticaFont, 313);
/////////////////////////////to be continued
    date = formatTimestamp(details?.date_for_land_survey || "2024-10-16T09:43:15.453+00:00");
    paragraph = ` from General Plan ${details?.dsl_no|| '118/1991'} surveyed by Land Surveyor ${details?.land_surveyor || 'R. J. Avenell'} in ${date.month} – December ${date.year} and approved by the Director of Surveys and Lands on the 22nd day of November 1991;`
    drawWrappedText(paragraph, tenthPage,555, 213.5, helveticaFont, 313);

    date = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");
    paragraph =`was held under Certificate of Consolidated Title No. 1354/1997 dated 3rd day of December 1997 and subsequent deeds the last of which being under Deed of Transfer No. ${details?.transfer_number || "5456787"} dated 12th day of March 2024 made in favour of Estate Properties (PROPRIETARY) LIMITED; `    // drawWrappedText(paragraph, tenthPage, 466.01344, 213.5, helveticaFont, 313);
    drawWrappedText(paragraph, tenthPage,496, 213.5, helveticaFont, 313);




    drawCenterTextOnPage(eleventhPage, details?.purchaser_full_names || "PURCHASER ", 200, 544, 11)
    date = formatTimestamp(details?.purchaser_date_of_birth || "2024-10-16T09:43:15.453+00:00");
    paragraph = `Born on the ${date.day} day of ${date.month} ${date.year}`
    drawCenterTextOnPage(eleventhPage, paragraph, 200, 531, 10)


    drawCenterTextOnPage(eleventhPage, details?.purchaser_spouse_full_names || "PURCHASER SPOUSE", 200, 506, 11)

     date = formatTimestamp(details?.purchaser_spouse_date_of_birth || "2024-10-16T09:43:15.453+00:00");
    paragraph = `Born on the ${date.day} day of ${date.month} ${date.year}`
    drawCenterTextOnPage(eleventhPage, paragraph, 200,495, 10)

paragraph = `her heirs, executors, administrators or assigns now is and henceforth shall be entitled thereto, conformably to local custom. The State, however, reserving its rights; and finally acknowledging the purchase price amounting to ${details?.purchase_amount || 'P780, 000.00 (Seven Hundred and Eighty Thousand Pula)'} to have been satisfactorily paid or secured.`
drawWrappedText(paragraph, eleventhPage,459, xPos, helveticaFont, 460);




  }
 

 
  



  const modifiedPdfBytes = await pdfDoc.save();
  return modifiedPdfBytes;
};

export default TransferCompanyIndividual;
