import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import pdfDocument from "../Documents/BondAccessBank.pdf";
import fontt from './verdana.ttf';
import fontkit from '@pdf-lib/fontkit'

const MortgageBondApplicationAccess = async (details) => {
  
  
  const fetchPdf = async () => {
    const existingPdfBytes = await fetch(pdfDocument).then((res) =>
      res.arrayBuffer()
    );
    return existingPdfBytes;
  };

  const pdfBytes = await fetchPdf();
  
  const pdfDoc = await PDFDocument.load(pdfBytes);
  pdfDoc.registerFontkit(fontkit);
  const response = await fetch(fontt);
  const fontBytes = await response.arrayBuffer();
  const font = await pdfDoc.embedFont(fontBytes);
  const helveticaFont = await pdfDoc.embedFont(fontBytes); // Embed the custom font

  const verdana = await pdfDoc.embedFont(fontBytes); // Embed the custom font
  const form = pdfDoc.getForm();


  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" }); // "October"
    const year = date.getUTCFullYear();

    return { day, month, year };
  }

  const firstPage = pdfDoc.getPage(0);
  const secondPage = pdfDoc.getPage(1);
  const seventhPage = pdfDoc.getPage(6);
  const eighthPage = pdfDoc.getPage(7);
  const ninthPage = pdfDoc.getPage(8);
  const tenthPage = pdfDoc.getPage(9);
  const eleventhPage = pdfDoc.getPage(10);
  const twelfthPage = pdfDoc.getPage(11);

  const { width, height } = firstPage.getSize();
  let xPos = 70.9; // Starting x-position

  const drawWrappedText = (projects, page, yPos, xPos, font, maxWidth) => {
    const words = projects.split(" ");
    const wordSpacing = 2; // Adjust this value to set the spacing between words
    const lineHeight = 12; // Adjust this value to control the space between lines

    let currentLine = [];
    let currentLineWidth = 0;

    // Iterate through each word in the text
    for (let i = 0; i < words.length; i++) {
      const wordWidth = font.widthOfTextAtSize(words[i], 10);

      // Check if adding the word exceeds the max width of the line
      if (currentLineWidth + wordWidth + (currentLine.length - 1) * wordSpacing > maxWidth) {
        // If so, move to the next line
        drawLine(currentLine, xPos, yPos, font, page);
        currentLine = [words[i]]; // Start a new line with the current word
        currentLineWidth = wordWidth;
        yPos -= lineHeight; // Move down for the next line
      } else {
        // Otherwise, add the word to the current line
        currentLine.push(words[i]);
        currentLineWidth += wordWidth;
      }
    }

    // Draw the last line
    drawLine(currentLine, xPos, yPos, font, page);
  };

  // Function to draw a single line of words
  const drawLine = (line, xPos, yPos, font, page) => {
    // Draw each word on the current line with the specified word spacing
    for (let i = 0; i < line.length; i++) {
      page.drawText(line[i], {
        x: xPos,
        y: yPos,
        size: 10,
        font: font,
        color: rgb(0, 0, 0),
      });
      xPos += font.widthOfTextAtSize(line[i], 10) + 2; // Move to the next word with spacing
    }
  };


  const drawCenterTextOnPage = (pageName, text, xPos, yPos, size) => {

    const textWidth = verdana.widthOfTextAtSize(text, size);
    const center = (width - textWidth) / 2;
    pageName.drawText(text, {
      x: center,
      y: yPos,
      size: size,
      font: verdana,
      color: rgb(0, 0, 0),
    });
  }


  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" }); // "October"
    const year = date.getUTCFullYear();

    return { day, month, year };
  }

  //page 1

  let date = formatTimestamp(
    details?.appointment_date || "2024-10-16T09:43:15.453+00:00"
  );
  
  
  
  let paragraph = `THAT, ${details?.appointed_conveyancer || "Appointed Conveyancer"} a duly appointed Conveyancer, appeared before me, REGISTRAR OF DEEDS FOR BOTSWANA at GABORONE ${details?.appointed_conveyancer_gender === "Male" ? "he" : "she"} being duly authorised hereto by a Power of Attorney executed at Gaborone on the ${date.day} day of ${date.month} ${date.year} and granted to ${details?.appointed_conveyancer_gender === "Male" ? "him" : "her"} by:`
  drawWrappedText(paragraph, firstPage, 839.0 - 577, xPos, helveticaFont, 460);
  drawCenterTextOnPage(firstPage, ((details?.full_names || "Mortgagor")), 200, 839.0 - 637, 11)
  date = formatTimestamp(details?.date_of_birth || "2024-10-16T09:43:15.453+00:00");
  drawCenterTextOnPage(firstPage, ((`(Born on the ${date?.day} day of ${date?.month} ${date?.year})`)), 200, 839.0 - 649, 10)
  //page 2





  paragraph = `NOW THEREFORE the Appearer q.q. renouncing all benefits of the legal exceptions, "non numeratae pecuniae", "non causa debiti", "errore calculi", "revision of accounts", "no value received", and all other legal benefits and exceptions with the full force, meaning and effect whereof he declared the Mortgagor to be fully acquainted, did by these presents declare and acknowledge her Principal to be held and firmly bound unto and in favour of the Mortgagee in the sum of ${details?.principal_sum || "P94, 000.00 (NINETY FOUR THOUSAND PULA)"} (hereinafter referred to as "the Principal Sum"), as a covering security up to but not exceeding the Principal Sum for and in respect of the Mortgagor's indebtedness to the Mortgagee as aforesaid.`
  drawWrappedText(paragraph, secondPage, 839.0 - 241, xPos, helveticaFont, 460);
  paragraph = `AND the Appearer q.q. further declared the Mortgagor to be held and firmly bound in the further sum of ${details?.additional_sum || 'P18, 800.00 (EIGHTEEN THOUSAND EIGHT HUNDRED PULA) (hereinafter referred to as "the Additional Sum")'} as security for contingent payments, costs or outlays as provided for in Clause 16 of the conditions hereinafter set forth.`
  drawWrappedText(paragraph, secondPage, 839.0 - 363, xPos, helveticaFont, 460);
  paragraph = `piece of land being Lot ${details?.property_lot_number || "754939"},(Portion of Lot ${details?.property_lot_number || "754939"} ;`
  drawWrappedText(paragraph, secondPage, 839.0 - 471, 213.5, helveticaFont, 313);
  paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
  drawWrappedText(paragraph, secondPage, 839.0 - 496, 213.5, helveticaFont, 313);
  paragraph = `${details?.property_measurements || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
  drawWrappedText(paragraph, secondPage, 839.0 - 522, 213.5, helveticaFont, 313);
  date = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");
  paragraph = `under Deed of Transfer No. ${details?.transfer_number || "24646/2024"} dated ${date?.day} day of ${date?.month} ${date?.year} made in favour of ${details?.full_names || "Mike Modise Smith"};`
  drawWrappedText(paragraph, secondPage, 839.0 - 546, 213.5, helveticaFont, 313);
  paragraph = `ANY indebtedness for the time being owing by the Mortgagor to the Mortgagee in respect of, legal and other costs of and incidental to this Bond and/or any proceedings instituted by the Mortgagee against the Mortgagor hereunder or otherwise, rates, taxes, stamp duties and/or any other costs and/or expenses incurred in the protection or security of the Mortgagee hereunder, shall be covered and secured under this Bond over and above the Principal Sum to the maximum extent of ${details?.principal_sum || "P18, 800.00 (EIGHTEEN THOUSAND EIGHT HUNDRED PULA);"}; provided that the aforegoing shall not be construed as excluding any such indebtedness from the general cover and security afforded hereunder up to the Principal Sum and the interest thereon.`
  drawWrappedText(paragraph, seventhPage, 839.0 - 265, 107.8, helveticaFont, 420);
  paragraph = `THAT interest on all amounts secured by this Bond shall be calculated at the bank‘s prime rate presently being ${details?.bank_prime_rate|| "22"}% (${details?.bank_prime_rate|| "22"} percent) less ${details?.base_rate || '27'}% (${details?.base_rate || '26'} percent) per annum above the Mortgagee’s prime lending rate as current from time to time (calculated on a daily basis and compounded monthly) or such other rate as the Mortgagee may from time to time determine.`
  drawWrappedText(paragraph, seventhPage, 839.0 - 398, 107.8, helveticaFont, 420);





  drawCenterTextOnPage(tenthPage, ((details?.full_names || "Mortgagor")), 200, 839.0 - 265, 11)
  date = formatTimestamp(details?.date_of_birth || "2024-10-16T09:43:15.453+00:00");
  drawCenterTextOnPage(tenthPage, ((`(Born on the ${date?.day} day of ${date?.month} ${date?.year})`)), 200, 839.0 - 277, 10)
  drawCenterTextOnPage(tenthPage, ((details?.appointed_conveyancer || "Mortgagor")), 200, 839.0 - 338, 11)
  paragraph = `to appear before the REGISTRAR OF DEEDS FOR BOTSWANA at GABORONE and then and there as my act and deed to sign the Covering Mortgage Bond in favour of ACCESS BANK BOTSWANA LIMITED for a sum of ${details?.principal_sum || 'P94, 000.00 (NINETY FOUR THOUSAND PULA)'} in terms of the draft Bond annexed hereto in respect of all the Mortgagor’s right, title and interest in and to the following property:-`
  drawWrappedText(paragraph, tenthPage, 839.0 - 387, 107.2, helveticaFont, 420);
  paragraph = `piece of land being Lot ${details?.property_lot_number || "754939"}, (Portion of Lot ${details?.property_lot_number || "754939"} ;`
  drawWrappedText(paragraph, tenthPage, 839.0 - 472, 213.5, helveticaFont, 313);
  paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
  drawWrappedText(paragraph, tenthPage, 839.0 - 496, 213.5, helveticaFont, 313);
  paragraph = `${details?.property_measurements || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
  drawWrappedText(paragraph, tenthPage, 839.0 - 520, 213.5, helveticaFont, 313);
  date = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");
  paragraph = `under Deed of Transfer No. ${details?.transfer_number || "24646/2024"} dated ${date?.day} day of ${date?.month} ${date?.year} made in favour of ${details?.full_names || "Mike Modise Smith"};`
  drawWrappedText(paragraph, tenthPage, 839.0 - 545, 213.5, helveticaFont, 313);








    const field71 = form.createTextField("field71");
    field71.setText("day");
    field71.addToPage(eleventhPage, {
      x: 342.19,
      y: 839.0 - 111.76171999999997,
      size: 12,
      font,
      width: 40.11887999999999,
      height: 18,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
    const field72 = form.createTextField("field72");
    field72.setText("month");
    field72.addToPage(eleventhPage, {
      x: 430.03,
      y: 839.0 - 111.76171999999997,
      size: 12,
      font,
      width: 80.045079999999984,
      height: 18.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
    const field73 = form.createTextField("field73");
    field73.setText("");
    field73.addToPage(eleventhPage, {
      x: 400,
      y: 839.0 - 257.59172,
      size: 12,
      font,
      width: 125,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  
  
  
  
  drawCenterTextOnPage(twelfthPage, ((details?.full_names || "Mortgagor")), 200, 839.0 - 229, 11)
  date = formatTimestamp(details?.date_of_birth || "2024-10-16T09:43:15.453+00:00");
  paragraph = `I am an adult ${details?.gender || 'male'} citizen of Botswana`
  drawWrappedText(paragraph, twelfthPage, 839.0 - 301, 106.2, helveticaFont, 313);
  date = formatTimestamp(details?.date_of_birth || "2024-10-16T09:43:15.453+00:00");
  paragraph = `I was born on the ${date.day} day of ${date.month} ${date.year}, which date I have always regarded as my true date of birth.`
  drawWrappedText(paragraph, twelfthPage,513, 106.2, helveticaFont, 385);
  paragraph = `I am married in community of property to ${details?.spouse_full_names || "Smith Modise"}`
  let op_paragraph = `I am not married`
  drawWrappedText(details?.marital_status === "Married" ? paragraph : op_paragraph, twelfthPage, 476, 106.2, helveticaFont, 313);




  const modifiedPdfBytes = await pdfDoc.save();
  return modifiedPdfBytes;
};

export default MortgageBondApplicationAccess;



// import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
// import pdfDocument from "../Documents/BondAccessBank.pdf";
// import fontt from './verdana.ttf';
// import fontkit from '@pdf-lib/fontkit'

// const MortgageBondApplicationAccess = async (details) => {
  
  
//   const fetchPdf = async () => {
//     const existingPdfBytes = await fetch(pdfDocument).then((res) =>
//       res.arrayBuffer()
//     );
//     return existingPdfBytes;
//   };

//   const pdfBytes = await fetchPdf();
  
//   const pdfDoc = await PDFDocument.load(pdfBytes);
//   pdfDoc.registerFontkit(fontkit);
//   const response = await fetch(fontt);
//   const fontBytes = await response.arrayBuffer();
//   const font = await pdfDoc.embedFont(fontBytes);
//   const helveticaFont = await pdfDoc.embedFont(fontBytes); // Embed the custom font

//   const verdana = await pdfDoc.embedFont(fontBytes); // Embed the custom font
//   const form = pdfDoc.getForm();


//   function formatTimestamp(timestamp) {
//     const date = new Date(timestamp);

//     const day = date.getUTCDate();
//     const month = date.toLocaleString("default", { month: "long" }); // "October"
//     const year = date.getUTCFullYear();

//     return { day, month, year };
//   }

//   const firstPage = pdfDoc.getPage(0);
//   const secondPage = pdfDoc.getPage(1);
//   const seventhPage = pdfDoc.getPage(6);
//   const eighthPage = pdfDoc.getPage(7);
//   const ninthPage = pdfDoc.getPage(8);
//   const tenthPage = pdfDoc.getPage(9);
//   const eleventhPage = pdfDoc.getPage(10);
//   const twelfthPage = pdfDoc.getPage(11);

//   const { width, height } = firstPage.getSize();
//   let xPos = 70.9; // Starting x-position

//   const drawWrappedText = (projects, page, yPos, xPos, font, maxWidth) => {
//     const words = projects.split(" ");
//     const wordSpacing = 2; // Adjust this value to set the spacing between words
//     const lineHeight = 12; // Adjust this value to control the space between lines

//     let currentLine = [];
//     let currentLineWidth = 0;

//     // Iterate through each word in the text
//     for (let i = 0; i < words.length; i++) {
//       const wordWidth = font.widthOfTextAtSize(words[i], 10);

//       // Check if adding the word exceeds the max width of the line
//       if (currentLineWidth + wordWidth + (currentLine.length - 1) * wordSpacing > maxWidth) {
//         // If so, move to the next line
//         drawLine(currentLine, xPos, yPos, font, page);
//         currentLine = [words[i]]; // Start a new line with the current word
//         currentLineWidth = wordWidth;
//         yPos -= lineHeight; // Move down for the next line
//       } else {
//         // Otherwise, add the word to the current line
//         currentLine.push(words[i]);
//         currentLineWidth += wordWidth;
//       }
//     }

//     // Draw the last line
//     drawLine(currentLine, xPos, yPos, font, page);
//   };

//   // Function to draw a single line of words
//   const drawLine = (line, xPos, yPos, font, page) => {
//     // Draw each word on the current line with the specified word spacing
//     for (let i = 0; i < line.length; i++) {
//       page.drawText(line[i], {
//         x: xPos,
//         y: yPos,
//         size: 10,
//         font: font,
//         color: rgb(0, 0, 0),
//       });
//       xPos += font.widthOfTextAtSize(line[i], 10) + 2; // Move to the next word with spacing
//     }
//   };


//   const drawCenterTextOnPage = (pageName, text, xPos, yPos, size) => {

//     const textWidth = verdana.widthOfTextAtSize(text, size);
//     const center = (width - textWidth) / 2;
//     pageName.drawText(text, {
//       x: center,
//       y: yPos,
//       size: size,
//       font: verdana,
//       color: rgb(0, 0, 0),
//     });
//   }


//   function formatTimestamp(timestamp) {
//     const date = new Date(timestamp);

//     const day = date.getUTCDate();
//     const month = date.toLocaleString("default", { month: "long" }); // "October"
//     const year = date.getUTCFullYear();

//     return { day, month, year };
//   }

//   //page 1

//   let date = formatTimestamp(
//     details?.appointment_date || "2024-10-16T09:43:15.453+00:00"
//   );
  
  
  
//   let paragraph = `THAT, ${details?.appointed_conveyancer || "Appointed Conveyancer"} a duly appointed Conveyancer, appeared before me, REGISTRAR OF DEEDS FOR BOTSWANA at GABORONE ${details?.appointed_conveyancer_gender === "Male" ? "he" : "she"} being duly authorised hereto by a Power of Attorney executed at Gaborone on the ${date.day} day of ${date.month} ${date.year} and granted to ${details?.appointed_conveyancer_gender === "Male" ? "him" : "her"} by:`
//   drawWrappedText(paragraph, firstPage, 839.0 - 577, xPos, helveticaFont, 460);
//   drawCenterTextOnPage(firstPage, ((details?.mortgagor_full_names || "Mortgagor")), 200, 839.0 - 637, 11)
//   date = formatTimestamp(details?.mortgagor_date_of_birth || "2024-10-16T09:43:15.453+00:00");
//   drawCenterTextOnPage(firstPage, ((`(Born on the ${date?.day} day of ${date?.month} ${date?.year})`)), 200, 839.0 - 649, 10)
//   //page 2





//   paragraph = `NOW THEREFORE the Appearer q.q. renouncing all benefits of the legal exceptions, "non numeratae pecuniae", "non causa debiti", "errore calculi", "revision of accounts", "no value received", and all other legal benefits and exceptions with the full force, meaning and effect whereof he declared the Mortgagor to be fully acquainted, did by these presents declare and acknowledge her Principal to be held and firmly bound unto and in favour of the Mortgagee in the sum of ${details?.principal_sum || "P94, 000.00 (NINETY FOUR THOUSAND PULA)"} (hereinafter referred to as "the Principal Sum"), as a covering security up to but not exceeding the Principal Sum for and in respect of the Mortgagor's indebtedness to the Mortgagee as aforesaid.`
//   drawWrappedText(paragraph, secondPage, 839.0 - 241, xPos, helveticaFont, 460);
//   paragraph = `AND the Appearer q.q. further declared the Mortgagor to be held and firmly bound in the further sum of ${details?.additionalSum || 'P18, 800.00 (EIGHTEEN THOUSAND EIGHT HUNDRED PULA) (hereinafter referred to as "the Additional Sum")'} as security for contingent payments, costs or outlays as provided for in Clause 16 of the conditions hereinafter set forth.`
//   drawWrappedText(paragraph, secondPage, 839.0 - 363, xPos, helveticaFont, 460);
//   paragraph = `piece of land being Lot ${details?.property_lot_number || "754939"}, Gaborone (Portion of Lot ${details?.property_lot_number || "754939"} Gaborone;`
//   drawWrappedText(paragraph, secondPage, 839.0 - 471, 213.5, helveticaFont, 313);
//   paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
//   drawWrappedText(paragraph, secondPage, 839.0 - 496, 213.5, helveticaFont, 313);
//   paragraph = `${details?.property_measurements || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
//   drawWrappedText(paragraph, secondPage, 839.0 - 522, 213.5, helveticaFont, 313);
//   date = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");
//   paragraph = `under Deed of Transfer No. ${details?.transfer_number || "24646/2024"} dated ${date?.day} day of ${date?.month} ${date?.year} made in favour of ${details?.mortgagor_full_names || "Mike Modise Smith"};`
//   drawWrappedText(paragraph, secondPage, 839.0 - 546, 213.5, helveticaFont, 313);
//   paragraph = `ANY indebtedness for the time being owing by the Mortgagor to the Mortgagee in respect of, legal and other costs of and incidental to this Bond and/or any proceedings instituted by the Mortgagee against the Mortgagor hereunder or otherwise, rates, taxes, stamp duties and/or any other costs and/or expenses incurred in the protection or security of the Mortgagee hereunder, shall be covered and secured under this Bond over and above the Principal Sum to the maximum extent of ${details?.principal_sum || "P18, 800.00 (EIGHTEEN THOUSAND EIGHT HUNDRED PULA);"}; provided that the aforegoing shall not be construed as excluding any such indebtedness from the general cover and security afforded hereunder up to the Principal Sum and the interest thereon.`
//   drawWrappedText(paragraph, seventhPage, 839.0 - 265, 107.8, helveticaFont, 420);
//   paragraph = `THAT interest on all amounts secured by this Bond shall be calculated at the bank‘s prime rate presently being ${details?.bank_prime_rate|| "22"}% (${details?.bank_prime_rate|| "22"} percent) less ${details?.base_rate || '27'}% (${details?.base_rate || '26'} percent) per annum above the Mortgagee’s prime lending rate as current from time to time (calculated on a daily basis and compounded monthly) or such other rate as the Mortgagee may from time to time determine.`
//   drawWrappedText(paragraph, seventhPage, 839.0 - 398, 107.8, helveticaFont, 420);





//   drawCenterTextOnPage(tenthPage, ((details?.mortgagor_full_names || "Mortgagor")), 200, 839.0 - 265, 11)
//   date = formatTimestamp(details?.mortgagor_date_of_birth || "2024-10-16T09:43:15.453+00:00");
//   drawCenterTextOnPage(tenthPage, ((`(Born on the ${date?.day} day of ${date?.month} ${date?.year})`)), 200, 839.0 - 277, 10)
//   drawCenterTextOnPage(tenthPage, ((details?.appointed_conveyancer || "Mortgagor")), 200, 839.0 - 338, 11)
//   paragraph = `to appear before the REGISTRAR OF DEEDS FOR BOTSWANA at GABORONE and then and there as my act and deed to sign the Covering Mortgage Bond in favour of ACCESS BANK BOTSWANA LIMITED for a sum of ${details?.bond || 'P94, 000.00 (NINETY FOUR THOUSAND PULA)'} in terms of the draft Bond annexed hereto in respect of all the Mortgagor’s right, title and interest in and to the following property:-`
//   drawWrappedText(paragraph, tenthPage, 839.0 - 387, 107.2, helveticaFont, 420);
//   paragraph = `piece of land being Lot ${details?.property_lot_number || "754939"}, Gaborone (Portion of Lot ${details?.property_lot_number || "754939"} Gaborone;`
//   drawWrappedText(paragraph, tenthPage, 839.0 - 472, 213.5, helveticaFont, 313);
//   paragraph = ` ${details?.situate || "in the Gaborone Administrative District;"}`
//   drawWrappedText(paragraph, tenthPage, 839.0 - 496, 213.5, helveticaFont, 313);
//   paragraph = `${details?.property_measurements || "621 m2 (Six Hundred and Twenty One Square Metres);"}`
//   drawWrappedText(paragraph, tenthPage, 839.0 - 520, 213.5, helveticaFont, 313);
//   date = formatTimestamp(details?.transfer_number_dated || "2024-10-16T09:43:15.453+00:00");
//   paragraph = `under Deed of Transfer No. ${details?.transfer_number || "24646/2024"} dated ${date?.day} day of ${date?.month} ${date?.year} made in favour of ${details?.mortgagor_full_names || "Mike Modise Smith"};`
//   drawWrappedText(paragraph, tenthPage, 839.0 - 545, 213.5, helveticaFont, 313);


//   paragraph = `under Deed of Transfer No. ${details?.transfer_number || "24646/2024"} dated ${date?.day} day of ${date?.month} ${date?.year} made in favour of ${details?.mortgagor_full_names || "Mike Modise Smith"};`
//   drawWrappedText(paragraph, tenthPage, 839.0 - 545, 213.5, helveticaFont, 313);







//     const field71 = form.createTextField("field71");
//     field71.setText("day");
//     field71.addToPage(eleventhPage, {
//       x: 342.19,
//       y: 839.0 - 111.76171999999997,
//       size: 12,
//       font,
//       width: 40.11887999999999,
//       height: 18,
//       color: rgb(0, 0, 0),
//       borderColor: rgb(1, 1, 1),
//       backgroundColor: rgb(1, 1, 1),
//     });
//     const field72 = form.createTextField("field72");
//     field72.setText("month");
//     field72.addToPage(eleventhPage, {
//       x: 430.03,
//       y: 839.0 - 111.76171999999997,
//       size: 12,
//       font,
//       width: 80.045079999999984,
//       height: 18.5,
//       color: rgb(0, 0, 0),
//       borderColor: rgb(1, 1, 1),
//       backgroundColor: rgb(1, 1, 1),
//     });
//     const field73 = form.createTextField("field73");
//     field73.setText("");
//     field73.addToPage(eleventhPage, {
//       x: 400,
//       y: 839.0 - 257.59172,
//       size: 12,
//       font,
//       width: 125,
//       height: 14.5,
//       color: rgb(0, 0, 0),
//       borderColor: rgb(1, 1, 1),
//       backgroundColor: rgb(1, 1, 1),
//     });
  
  
  
  
//   drawCenterTextOnPage(twelfthPage, ((details?.mortgagor_full_names || "Mortgagor")), 200, 839.0 - 229, 11)
//   date = formatTimestamp(details?.mortgagor_date_of_birth || "2024-10-16T09:43:15.453+00:00");
//   paragraph = `I am an adult ${details?.mortgagor_gender || 'male'} citizen of Botswana`
//   drawWrappedText(paragraph, twelfthPage, 839.0 - 301, 106.2, helveticaFont, 313);
//   date = formatTimestamp(details?.mortgagor_date_of_birth || "2024-10-16T09:43:15.453+00:00");
//   paragraph = `I was born on the ${date.day} day of ${date.month} ${date.year}, which date I have always regarded as my true date of birth.`
//   drawWrappedText(paragraph, twelfthPage,513, 106.2, helveticaFont, 385);
//   paragraph = `I am married in community of property to ${details?.mortgagor_spouse_full_names || "Smith Modise"}`
//   drawWrappedText(paragraph, twelfthPage, 476, 106.2, helveticaFont, 313);
//   // const field77 = form.createTextField("field77");
//   //   field77.setText("day");
//   //   field77.addToPage(twelfthPage, {
//   //     x: 462.34,
//   //     y: 839.0 - 548.5,
//   //     size: 12,
//   //     font,
//   //     width:90.54339999999996,
//   //     height: 14.5,
//   //     color: rgb(0, 0, 0),
//   //     borderColor: rgb(1, 1, 1),
//   //     backgroundColor: rgb(1, 1, 1),
//   //   });
//   //   const field78 = form.createTextField("field78");
//   //   field78.setText("month");
//   //   field78.addToPage(twelfthPage, {
//   //     x: 90.984,
//   //     y: 839.0 - 560.5,
//   //     size: 12,
//   //     font,
//   //     width:80.925560000000004,
//   //     height: 15.5,
//   //     color: rgb(0, 0, 0),
//   //     borderColor: rgb(1, 1, 1),
//   //     backgroundColor: rgb(1, 1, 1),
//   //   });



//   const modifiedPdfBytes = await pdfDoc.save();
//   return modifiedPdfBytes;
// };

// export default MortgageBondApplicationAccess;
